<template>
  <IconBaseImg src="/public/icone-assinador-desktop-serpro.svg" />
</template>

<script>
import IconBaseImg from "./icon-base-img";

export default {
  components: { IconBaseImg },
  name: "icon-assinador-desktop-serpro",
};
</script>
