var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ButtonBase",
    _vm._g(
      _vm._b(
        {
          staticClass: "action-icon py-1 px-2",
          style: _vm.cssVars,
          attrs: { size: "sm" },
        },
        "ButtonBase",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }