var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BNavItem",
    _vm._g(
      _vm._b(
        {
          attrs: {
            "link-attrs": {
              id: _vm.id,
            },
            "link-classes": [
              "d-flex",
              "justify-content-between",
              "pr-4",
              _vm.level === 1 ? "pl-3" : "pl-5",
              "text-white",
              "left-block-primary-500-on-hover",
            ],
          },
        },
        "BNavItem",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }