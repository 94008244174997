<template>
  <BNavbarNav class="ml-auto align-items-center">
    <BNavText class="d-none d-md-flex flex-column text-secondary text-right">
      <span class="font-weight-bolder mb-n2">{{ title }}</span>
      <span
        class=""
      ><small>{{ subTitle }}</small></span>
    </BNavText>

    <BNavItemDropdown
      v-if="!hideMenu"
      :id="camelCase(field, 'dropdown')"
      right
      no-caret
    >
      <template #button-content>
        <BAvatar
          :id="camelCase(field, 'avatar')"
          variant="light"
          badge-variant="secondary"
          badge-offset="-4px"
          class="border text-secondary"
        >
          <template #badge>
            <AppIcon
              name="caret-down"
              class="rounded-circle"
            />
          </template>
        </BAvatar>
        <div
          v-if="badge"
          class="badge-wrapper"
        >
          <BadgeBase :text="badge" />
        </div>
      </template>
      <slot name="menu" />
    </BNavItemDropdown>
  </BNavbarNav>
</template>

<script>
import { camelCase } from "@cert/web";

import { BAvatar, BNavItemDropdown, BNavText, BNavbarNav } from "../bv";
import { AppIcon } from "../icons";
import { BadgeBase } from "../text";

export default {
  components: {
    AppIcon,
    BAvatar,
    BNavItemDropdown,
    BNavText,
    BNavbarNav,
    BadgeBase,
  },
  methods: {
    camelCase,
  },
  name: "nav-user",
  props: {
    badge: {
      default: null,
      type: String,
    },
    field: {
      default: null,
      type: String,
    },
    hideMenu: {
      default: false,
      type: Boolean,
    },
    subTitle: {
      default: "Desconhecido",
      type: String,
    },
    title: {
      default: "Desconhecido",
      type: String,
    },
  },
};
</script>

<style scoped>
.badge-wrapper {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
