var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "RowForm",
    [
      _c(
        "ColBase",
        { attrs: { cols: "4" } },
        [
          _c(
            "BInputGroup",
            { attrs: { prepend: "+" } },
            [
              _c("BFormInput", {
                attrs: { readonly: "" },
                model: {
                  value: _vm.userCountryNumber,
                  callback: function ($$v) {
                    _vm.userCountryNumber = $$v
                  },
                  expression: "userCountryNumber",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ColBase",
        { attrs: { cols: "8" } },
        [
          _c(
            "BFormInput",
            _vm._b(
              {
                attrs: {
                  id: _vm.camelCase(_vm.$attrs.id, "national"),
                  readonly: _vm.disabled,
                },
                model: {
                  value: _vm.userNationalNumber,
                  callback: function ($$v) {
                    _vm.userNationalNumber = $$v
                  },
                  expression: "userNationalNumber",
                },
              },
              "BFormInput",
              _vm.$attrs,
              false
            )
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }