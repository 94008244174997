var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ControlWrapper",
    _vm._g(
      _vm._b(
        {
          attrs: {
            "control-component": _vm.InputCelular,
            rules: _vm.composedRules,
            label: _vm.label,
          },
        },
        "ControlWrapper",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }