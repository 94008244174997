var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BNavbarNav",
    { staticClass: "ml-auto align-items-center" },
    [
      _c(
        "BNavText",
        {
          staticClass: "d-none d-md-flex flex-column text-secondary text-right",
        },
        [
          _c("span", { staticClass: "font-weight-bolder mb-n2" }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
          _vm._v(" "),
          _c("span", {}, [_c("small", [_vm._v(_vm._s(_vm.subTitle))])]),
        ]
      ),
      _vm._v(" "),
      !_vm.hideMenu
        ? _c(
            "BNavItemDropdown",
            {
              attrs: {
                id: _vm.camelCase(_vm.field, "dropdown"),
                right: "",
                "no-caret": "",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "button-content",
                    fn: function () {
                      return [
                        _c("BAvatar", {
                          staticClass: "border text-secondary",
                          attrs: {
                            id: _vm.camelCase(_vm.field, "avatar"),
                            variant: "light",
                            "badge-variant": "secondary",
                            "badge-offset": "-4px",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "badge",
                                fn: function () {
                                  return [
                                    _c("AppIcon", {
                                      staticClass: "rounded-circle",
                                      attrs: { name: "caret-down" },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            1405998670
                          ),
                        }),
                        _vm._v(" "),
                        _vm.badge
                          ? _c(
                              "div",
                              { staticClass: "badge-wrapper" },
                              [_c("BadgeBase", { attrs: { text: _vm.badge } })],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                2126731073
              ),
            },
            [_vm._v(" "), _vm._t("menu")],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }