import { formatDate } from "@/lib";
import { markdownToHtml, sortByField } from "@cert/web";

export function parseLogs(rawLogs) {
  const logs = Object.entries(rawLogs).map(([key, rawLog]) => {
    const { html, meta } = markdownToHtml(rawLog, { hasFrontMatter: true });
    const datePretty = formatDate(meta.date);
    return { key, ...meta, datePretty, html };
  });
  const sortedLogs = sortByField(logs, { asc: false, field: "date" });
  return sortedLogs;
}
