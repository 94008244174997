import * as Sentry from "@sentry/vue";

export const captureError = (messageOrError, meta) =>
  capture(messageOrError, { level: "error", meta });
export const captureAlert = (message, meta) =>
  capture(message, { level: "warning", meta });

function capture(messageOrError, { level, meta = {} }) {
  Sentry.withScope((scope) => {
    for (const key in meta) {
      scope.setExtra(key, meta[key]);
    }
    scope.setLevel(level);
    if (typeof messageOrError === "string") {
      Sentry.captureMessage(messageOrError, level);
    }
    else {
      Sentry.captureException(messageOrError);
    }
  });
}
