import { asDate } from "../as-date";

export function incDate(
  original,
  { days = 0, hours = 0, minutes = 0, months = 0, seconds = 0, years = 0 } = {},
) {
  const copy = asDate(original);

  const currentSeconds = copy.getSeconds();
  copy.setSeconds(currentSeconds + seconds);

  const currentMinutes = copy.getMinutes();
  copy.setMinutes(currentMinutes + minutes);

  const currentHours = copy.getHours();
  copy.setHours(currentHours + hours);

  const currentDays = copy.getDate();
  copy.setDate(currentDays + days);

  const currentMonths = copy.getMonth();
  copy.setMonth(currentMonths + months);

  const currentYears = copy.getFullYear();
  copy.setFullYear(currentYears + years);

  return copy;
}
