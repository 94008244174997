<template>
  <DescriptionText>
    Seja bem vindo ao NeoSigner. Por favor, aguarde enquanto preparamos sua
    sessão.
  </DescriptionText>
</template>

<script>
import { DescriptionText } from "@/lib";

export default {
  components: { DescriptionText },
  name: "auth-dialog-welcome",
};
</script>
