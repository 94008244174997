<template>
  <BBreadcrumb
    v-if="path"
    class="m-0 p-0 bg-transparent"
    :items="items"
  />
</template>

<script>
import { BBreadcrumb } from "../bv";

export default {
  components: { BBreadcrumb },
  computed: {
    items() {
      if (!this.path) return null;
      const items = this.path.map((link, i, links) => {
        const node = { text: link.text };
        if (link.to && i < links.length - 1) {
          node.to = link.to;
        }
        else {
          node.active = true;
        }
        return node;
      });

      return items;
    },
  },
  name: "nav-crumb",
  props: {
    path: {
      default: null,
      type: Array,
    },
  },
};
</script>
