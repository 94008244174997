import { request } from "@/api";
import { createCollectionStoreModule } from "@/lib";
import Vue from "vue";

import { ALGORITMO_DESKTOP } from "../assinador-desktop-adapters";
import { getConfiguration } from "../configuration";
import { hasPendingAcknowledgement, parseFluxoToRecord, parseRecordToFluxo } from "../fluxos-common";

export const fluxosExternoStore = createCollectionStoreModule({
  actions: {
    async assinarAvancada({ dispatch }, fluxo) {
      const payload = {
        endpoint: `/fluxos/${encodeURI(fluxo.id)}/assinar`,
        method: "patch",
      };
      await dispatch("setItemCommand", payload);
    },
    async obtainHashesByDocument({ dispatch }, fluxo) {
      const idFluxo = encodeURI(fluxo.id);
      const payload = {
        endpoint: `/fluxos/${idFluxo}/hashes-documentos/${ALGORITMO_DESKTOP}`,
        method: "get",
      };
      const hashes = await dispatch("requestAction", payload);
      return hashes;
    },
    async rejeitarResposta({ dispatch }, fluxo) {
      const payload = {
        endpoint: `/fluxos/${encodeURI(fluxo.id)}/rejeitar`,
        method: "patch",
      };
      await dispatch("setItemCommand", payload);
    },
    async saveRevisao({ dispatch }, { fluxo, resposta }) {
      const idFluxo = encodeURI(fluxo.id);
      const payload = {
        endpoint: `/fluxos/${idFluxo}/revisao`,
        method: "patch",
        record: resposta,
      };
      const newFluxo = await dispatch("setItemCommand", payload);
      return newFluxo;
    },
    async submitAssinaturaDesktop({ dispatch }, { documentos, fluxo }) {
      const idFluxo = encodeURI(fluxo.id);
      const payload = {
        endpoint: `/fluxos/${idFluxo}/assinar-desktop/${ALGORITMO_DESKTOP}`,
        method: "patch",
        record: { documentos },
      };
      const newFluxo = await dispatch("setItemCommand", payload);
      return newFluxo;
    },
    async submitAssinaturaSerproId({ dispatch }, { fluxoId, ...rest }) {
      const encodedFluxoId = encodeURI(fluxoId);
      const payload = {
        endpoint: `/fluxos/${encodedFluxoId}/assinar-serpro-id`,
        method: "patch",
        record: rest,
      };
      const newFluxo = await dispatch("setItemCommand", payload);
      return newFluxo;
    },
    async submitRevisao({ dispatch }, { fluxo, resposta }) {
      const idFluxo = encodeURI(fluxo.id);
      const payload = {
        endpoint: `/fluxos/${idFluxo}/revisar`,
        method: "patch",
        record: resposta,
      };
      const newFluxo = await dispatch("setItemCommand", payload);
      return newFluxo;
    },
  },
  apiService: "controlador",
  getters: {
    fluxoAcknowledgments:
      ({ acknowledgments }) =>
        fluxoId =>
          Object.entries(acknowledgments)
            .filter(([, a]) => a.fluxoId === fluxoId)
            .map(([documentoId, a]) => ({ documentoId, ...a })),
    hasFluxoPendingAcknowledgement:
      (state, getters, rootState, rootGetters) => (fluxo) => {
        const fluxoAcknowledgments = getters.fluxoAcknowledgments(fluxo.id);
        const codigo = rootGetters["auth/codigo"];
        return hasPendingAcknowledgement(
          fluxo,
          codigo,
          fluxoAcknowledgments,
        );
      },
    isCodigoExhibitionAcknowledged:
      ({ acknowledgments }) =>
        documentoId =>
          acknowledgments[documentoId]
          && acknowledgments[documentoId].codigoExhibition,
    isContentAcknowledged:
      ({ acknowledgments }) =>
        documentoId =>
          acknowledgments[documentoId] && acknowledgments[documentoId].content,
  },
  mutations: {
    setAcknowledgment(
      { acknowledgments },
      { codigoExhibition, content, documentoId, fluxoId },
    ) {
      Vue.set(acknowledgments, documentoId, {
        codigoExhibition,
        content,
        fluxoId,
      });
    },
  },
  pageLimit: getConfiguration("WEB_PAGINATION_LIMIT"),
  parseItemToRecord: parseFluxoToRecord,
  parseRecordToItem: parseRecordToFluxo,
  pollingSeconds: getConfiguration("WEB_POLLING_SECONDS"),
  queryEndpoint: "/respostas",
  request,
  state: {
    // como é ignorado pelo backend e portanto seria apagado durante o polling. não pode ser guardado junto a coleção de itens (documentos) da store.
    acknowledgments: {},
  },
});
