var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._b(
      { style: _vm.svgStyle, attrs: { viewBox: _vm.svgViewBox } },
      "svg",
      _vm.$attrs,
      false
    ),
    [_c("path", { attrs: { d: _vm.path } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }