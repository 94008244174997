export function truncateString(
  str,
  { abbreviate = false, filler = "", limit } = {},
) {
  if (typeof str !== "string" || str.length < 1) return str;
  if (typeof limit !== "number" || limit - filler.length <= 0) return "";
  if (str.length <= limit) return str;

  const trimmedStr = str.trim();
  if (trimmedStr.length <= limit) return trimmedStr;

  const cut = (s) => {
    const c = s.substring(0, limit - filler.length).trim();
    return `${c}${filler}`;
  };

  if (!abbreviate) return cut(trimmedStr);

  const parts = trimmedStr.split(" ");
  const first = parts.shift();
  const last = parts.pop();

  const tolerance = typeof abbreviate !== "number" ? 3 : abbreviate;
  const middle = parts.map(part =>
    part.length > tolerance ? part[0] : part,
  );

  let abbreviated = [first, ...middle, last].join(" ");

  if (abbreviated.length > limit) {
    abbreviated = [first, last].join(" ");
  }

  if (abbreviated.length > limit) {
    abbreviated = cut(abbreviated);
  }

  return abbreviated;
}
