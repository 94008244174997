import { ERROR_SEVERITIES } from "./severities";

const defaultText = "Aconteceu um erro inesperado na aplicação";

export class AppError extends Error {
  code;
  meta;
  severity;

  constructor({ code, message, meta = {}, severity = ERROR_SEVERITIES.CRITICAL } = {}) {
    super(message || code || defaultText);
    Error.captureStackTrace(this);

    this.code = code || message || defaultText;
    this.message = message || code || defaultText;
    this.severity = severity;
    this.meta = meta;
  }
}
