<template>
  <div
    v-if="enabled"
    :style="{ position: 'fixed', bottom: 0, right: 0, 'z-index': 10000 }"
  >
    <button @click="throwError">
      error
    </button>
  </div>
</template>

<script>
import { isConfiguration } from "@/data";
export default {
  data() {
    const enabled = isConfiguration("WEB_SHOW_OBSERVABILITY_PANEL");

    return {
      enabled,
    };
  },
  methods: {
    throwError() {
      throw new Error("Synthetic Error Throw from ObservabilityPanel");
    },
  },
  name: "ObservabilityPanel",
};
</script>
