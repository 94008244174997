import {
  extractAssinantes,
  extractInteressado,
  hasAssinante,
  isRevisor,
} from "./selectors-interessados";

export function extractDocumentos(fluxo) {
  if (!fluxo || !fluxo.documentos || !Array.isArray(fluxo.documentos)) return [];
  return fluxo.documentos;
}

export function extractDocumento(fluxo, documentoId) {
  const documentos = extractDocumentos(fluxo);
  return documentos.find(d => d.id === documentoId);
}

export function hasSomeUploadedDocument(fluxo) {
  const documentos = extractDocumentos(fluxo);

  return documentos.length > 0 && documentos.some(d => !!d.id);
}

export function hasPendingSeloSetup(fluxo) {
  if (!hasAssinante(fluxo)) return false;
  if (!hasSomeUploadedDocument(fluxo)) return false;

  if (!hasIdleUploadQueue(fluxo)) return true;

  const documentos = extractDocumentos(fluxo);
  const allHavePosition = documentos.every(d =>
    hasAllStampsPositioned(d, fluxo),
  );
  return !allHavePosition;
}

export function hasAllStampsPositioned(documento, fluxo) {
  const { selos } = documento;

  if (!Array.isArray(selos)) return false;

  const assinantes = extractAssinantes(fluxo);
  return selos.length === assinantes.length;
}

export function hasIdleUploadQueue(fluxo) {
  const { documentos } = fluxo;
  return documentos.every(d => !!d.id);
}

export function hasPendingAcknowledgement(
  fluxo,
  interessadoCodigo,
  acknowledgements = [],
) {
  const documentos = extractDocumentos(fluxo).length;

  const contentAcknowledgments = acknowledgements.filter(
    a => a.content,
  ).length;
  const hasPendingContentAcknowledgement = documentos > contentAcknowledgments;

  const shouldCheckCodigo = shouldAcknowledgeCodigoExhibition(
    fluxo,
    interessadoCodigo,
  );
  const codigoExhibitionAcknowledgments = acknowledgements.filter(
    a => a.codigoExhibition,
  ).length;
  const hasPendingCodigoAgreement = shouldCheckCodigo
    ? documentos > codigoExhibitionAcknowledgments
    : false;
  return hasPendingContentAcknowledgement || hasPendingCodigoAgreement;
}

export function shouldAcknowledgeCodigoExhibition(fluxo, interessadoCodigo) {
  const interessado = extractInteressado(fluxo, interessadoCodigo);
  return !isRevisor(interessado);
}
