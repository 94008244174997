<template>
  <OverlayBase :is-busy="isBusy">
    <DescriptionText>
      Por favor, escolha um dos canais de comunicação cadastrados para te enviarmos um <strong>código de confirmação</strong>.
    </DescriptionText>
    <DialogForm
      :external-errors="lastError"
      :default-payload="defaultPayload"
      @save="execute(handleSelect, $event)"
    >
      <template #body="{ payload }">
        <RowForm>
          <ColBase>
            <ControlRadio
              v-model="payload.tipo"
              field="channel"
              label="Para onde deseja enviar o código de confirmação?"
              :options="options"
              value-field="tipo"
              text-field="mask"
            />
          </ColBase>
        </RowForm>
      </template>
      <template #actions>
        <ButtonSecondary
          label="Cancelar"
          field="cancel"
          @click="resetState"
        />
        <ButtonPrimary
          ref="selectChannelButton"
          label="Prosseguir"
          field="selectChannel"
        />
      </template>
    </DialogForm>
  </OverlayBase>
</template>

<script>
import { ControlRadio } from "@/lib";
import { DialogForm } from "@/lib";
import { OverlayBase, useExecute } from "@/lib";
import { ButtonPrimary, ButtonSecondary } from "@/lib";
import { ColBase, RowForm } from "@/lib";
import { DescriptionText } from "@/lib";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: {
    ButtonPrimary,
    ButtonSecondary,
    ColBase,
    ControlRadio,
    DescriptionText,
    DialogForm,
    OverlayBase,
    RowForm,
  },
  computed: {
    ...mapGetters("auth", ["channels"]),
    defaultPayload() {
      return {
        tipo: this.options[0].tipo,
      };
    },
    options() {
      return this.channels.map(channel => ({ ...channel, label: channel.mask }));
    },
  },
  methods: {
    ...mapActions("auth", ["triggerUserOtp"]),
    ...mapMutations("auth", ["resetState"]),
    async handleSelect({ tipo }) {
      await this.triggerUserOtp({ tipo });
    },
  },
  mounted() {
    if (this.channels.length === 1) {
      this.execute(this.handleSelect, { tipo: this.defaultPayload.tipo });
    }
  },
  name: "auth-dialog-channel",
  setup() {
    const { execute, isBusy, lastError } = useExecute();
    return { execute, isBusy, lastError };
  },
};
</script>
