var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "SideBarItem",
    _vm._g(
      _vm._b(
        { attrs: { id: _vm.camelCase("nav", _vm.text), level: 2 } },
        "SideBarItem",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        "span",
        [
          _vm.icon
            ? _c("AppIcon", {
                attrs: {
                  name: _vm.icon,
                  "fill-color": _vm.isCurrent
                    ? "var(--w-color-success-50)"
                    : "var(--w-color-neutral-10)",
                },
              })
            : _vm._e(),
          _vm._v("\n    " + _vm._s(_vm.text.toUpperCase()) + "\n  "),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }