<template>
  <ValidationObserver
    ref="formValidationObserver"
    v-slot="{ handleSubmit }"
  >
    <!--
        novalidate é incluído para ativar o mecanismo personalizado
        de validação com bootstrap-vue e vee-validate
     -->
    <form
      novalidate
      @submit.prevent="handleSubmit(() => onSubmit($event))"
    >
      <BodyActions :show-divider="showActionDivider">
        <template #body>
          <alert-text
            v-if="formError"
            variant="danger"
            icon="error"
            :text="formError"
          />
          <slot
            name="body"
            :payload="payload"
          />
        </template>

        <template #actions>
          <slot
            name="actions"
            :payload="payload"
          />
        </template>
      </BodyActions>
    </form>
  </ValidationObserver>
</template>

<script>
import { AlertText } from "@/lib/components/text";
import { ERROR_SEVERITIES, camelCase, clone } from "@/lib/meta";
import { ValidationObserver } from "@/lib/validation";

import BodyActions from "./body-actions";

export default {
  components: { AlertText, BodyActions, ValidationObserver },
  data() {
    return {
      formError: null,
      payload: clone(this.defaultPayload),
    };
  },
  methods: {
    // infere se o erro refere-se a algum campo do formulário ou deve ser exibido no topo do "form"
    matchErrorWithFormField(error) {
      // alguns formulários declaram nomes de campo incompatíveis com os nomes adotados pelo backend. o serverFieldMap permite a associação explicita nesses casos.
      const errorField = this.serverFieldMap[error.meta.property] || error.meta.property;

      if (!errorField) return null;

      const formFields = Object.keys(this.$refs.formValidationObserver.fields).concat("form");

      const exactField = formFields.find(f => f === camelCase("validation", errorField));
      if (exactField) return exactField;

      const inferredField = formFields.find(f => f.toLowerCase().includes(errorField.toLowerCase()));
      if (inferredField) return inferredField;

      return null;
    },
    onSubmit(event) {
      // os metadados passados como segundo argumento do evento servem para apoiar comportamentos especiais como formulários com mais de um botão submit.
      const metadata = { event };
      if (event?.target) {
        const submitter = event.submitter || {};
        const formData = new FormData(event.target, submitter);
        metadata.formData = formData;
      }
      this.$emit("save", this.payload, metadata);
    },
  },
  name: "DialogForm",
  props: {
    defaultPayload: {
      default: () => ({}),
      type: Object,
    },
    externalErrors: {
      default: null,
      type: [Object, Error],
    },
    serverFieldMap: {
      default: () => ({}),
      type: Object,
    },
    showActionDivider: {
      default: false,
      type: Boolean,
    },
  },
  watch: {
    defaultPayload(newDefaultPayload) {
      // não pode ser computed pq os descendentes precisam manipular payload
      this.payload = clone(newDefaultPayload);
    },
    externalErrors(error) {
      this.formError = null;
      if (!error) return;

      const errorField = this.matchErrorWithFormField(error);
      if (errorField === "form") {
        this.formError = error.message;
        return;
      }

      if (errorField) {
        this.$refs.formValidationObserver.setErrors({ [errorField]: error.message });
        return;
      }

      if (error.severity === ERROR_SEVERITIES.LOGICAL) {
        this.$showAlert(error.message);
        return;
      }

      this.$showError(error.message);
    },
  },
};
</script>
