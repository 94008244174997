var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ValidationProvider", {
    ref: "provider",
    attrs: {
      rules: _vm.rules,
      name: _vm.label ? _vm.label.toUpperCase() : null,
      vid: _vm.camelCase("validation", _vm.field),
      mode: "eager",
    },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function ({ passed, errors, valid }) {
            return [
              _vm._t("default", null, {
                state: _vm.deriveState({ rules: _vm.rules, errors, passed }),
                error: errors[0],
                valid: valid,
                provider: _vm.$refs.provider,
              }),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }