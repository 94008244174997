var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ModalBase", {
    ref: "modal",
    attrs: { "show-action-divider": "", size: "lg" },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c("PdfDocument", {
              attrs: {
                "file-path": _vm.filePath,
                "file-name": _vm.fileName,
                "pdf-download": _vm.pdfDownload,
              },
              on: {
                download: function ($event) {
                  return _vm.$emit("download")
                },
              },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "actions",
        fn: function ({ cancel }) {
          return [
            _c("ButtonPrimary", {
              attrs: { label: "Fechar" },
              on: {
                click: function ($event) {
                  return cancel()
                },
              },
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }