export function syncUserData({ store, updateUser }) {
  const getUserPerfil = (state, getters) =>
    getters["auth/perfilIfSignedIn"];
  const getUserData = () => {
    const userData = store.getters["auth/isSignedIn"]
      ? {
          idCliente: store.getters["auth/idCliente"],
          nomeCliente: store.getters["auth/nomeCliente"],
          email: store.getters["auth/email"],
          id: store.getters["auth/idConta"],
          perfil: store.getters["auth/perfilIfSignedIn"],
          username: store.getters["auth/nome"],
        }
      : null;
    return userData;
  };

  store.watch(getUserPerfil, () => updateUser(getUserData()));
}
