<template>
  <div class="dialog-wrapper px-5 py-3 d-flex flex-column">
    <div>
      <div class="d-none d-lg-flex justify-content-end">
        <LogoSerpro
          height="30px"
          width="90px"
        />
      </div>
      <div class="d-flex justify-content-center justify-content-lg-start">
        <LogoApp class="mt-3" />
      </div>
      <div class="d-none d-lg-block">
        <HeaderText
          text="Assine vários documentos num só lugar"
          class="mt-4"
        />
      </div>
    </div>
    <div class="mt-6">
      <component
        :is="dialog"
        :session="session"
        :perfil="perfil"
        :link-token="linkToken"
        :link-id="linkId"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { HeaderText } from "@/lib";
import { LogoApp, LogoSerpro } from "@/lib";
import AuthDialogAuthentication from "./auth-dialog-authentication";
import AuthDialogChannel from "./auth-dialog-channel";
import AuthDialogCliente from "./auth-dialog-cliente";
import AuthDialogEmail from "./auth-dialog-email";
import AuthDialogLinkAutenticado from "./auth-dialog-link-autenticado";
import AuthDialogOtp from "./auth-dialog-otp";
import AuthDialogPerfis from "./auth-dialog-perfis";
import AuthDialogRegistration from "./auth-dialog-registration";
import AuthDialogSession from "./auth-dialog-session";
import AuthDialogWelcome from "./auth-dialog-welcome";

export default {
  components: {
    AuthDialogChannel,
    AuthDialogEmail,
    AuthDialogLinkAutenticado,
    AuthDialogOtp,
    AuthDialogPerfis,
    AuthDialogSession,
    AuthDialogWelcome,
    HeaderText,
    LogoApp,
    LogoSerpro,
  },
  computed: {
    ...mapGetters("auth", [
      "hasChannels",
      "hasLinkAutenticado",
      "hasPerfis",
      "isClienteSetPending",
      "isOtpSent",
      "isSignedIn",
      "isSignedOut",
      "isWebAuthnAuthenticatorAvailable",
      "isWebAuthnRegistrationPending",
      "webAuthnRegistrationOptions",
    ]),
    dialog() {
      // se o cliente estiver logado vá direto para a tela de boas vindas
      if (this.isSignedIn) return AuthDialogWelcome;

      // se tem um authenticator registrado ou arbitrariamente dispensou o registro vá e já possui uma lista de perfis para seleção, vá para a escolha do perfil
      if (this.hasPerfis && !this.isWebAuthnRegistrationPending) return AuthDialogPerfis;

      // se já possui um conjunto de opções de registro válida vá para a tela de registro de dispositivo
      if (this.webAuthnRegistrationOptions && this.isWebAuthnRegistrationPending) return AuthDialogRegistration;

      // se o otp já foi enviado pelo backend ao canal escolhido vá para a tela onde se informa o otp
      if (this.isOtpSent) return AuthDialogOtp;

      // se possui um dispositivo cadastrado para autenticação vá para a tela de uso dele
      if (this.isWebAuthnAuthenticatorAvailable) return AuthDialogAuthentication;

      // se tem uma lista de canais disponíveis vá para a lista de canais
      if (this.hasChannels) return AuthDialogChannel;

      // se ainda não definiu o cliente, vá para a lista de clientes
      if (this.isClienteSetPending) return AuthDialogCliente;

      // se possui um token de link autenticado, vá para a página que provoca a  autenticação direta
      if (this.hasLinkAutenticado) return AuthDialogLinkAutenticado;

      // se está registrado como fora do sistema vá para a tela de email
      if (this.isSignedOut) return AuthDialogEmail;

      // resolve o status de autenticação inicial do usuário
      return AuthDialogSession;
    },
  },
  name: "auth-page",
  props: {
    linkId: {
      default: null,
      type: String,
    },
    linkToken: {
      default: null,
      type: String,
    },
    perfil: {
      default: null,
      type: String,
    },
    session: {
      default: null,
      type: String,
    },
  },
};
</script>

<style>
.dialog-wrapper {
  max-width: var(--w-size-83);
  border-radius: var(--w-size-20);
  background-color: var(--w-color-neutral-10);
  opacity: 0.99;
}

@media (min-width: 576px) {
  .dialog-wrapper {
    box-shadow: var(--w-shadow-primary);
  }
}
</style>
