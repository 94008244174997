<template>
  <QueryState
    v-slot="{ isBusyWithQuerying }"
    :store-modules="collectionsStoreModules"
  >
    <CardBase
      :header-text="headerText"
      :show-linear="isBusyWithQuerying"
    >
      <div :id="camelCase('list', 'page', field)">
        <PanelButtons>
          <ButtonSecondary
            field="update"
            label="Atualizar"
            @click="handleUpdate"
          />
          <slot name="listActions" />
        </PanelButtons>
        <ListPower
          :field="field"
          :items="items"
          :primary-key="primaryKey"
          v-bind="$attrs"
          v-on="$listeners"
        >
          <template #actions="{ item, index }">
            <slot
              name="listItemActions"
              :index="index"
              :item="item"
            />
          </template>
        </ListPower>
      </div>
    </CardBase>
  </QueryState>
</template>

<script>
import { camelCase } from "@/lib/meta";

import { QueryState } from "../async";
import { ButtonSecondary, PanelButtons } from "../buttons";
import { CardBase } from "../dialogs";
import { ListPower } from "../lists";

export default {
  components: {
    ButtonSecondary,
    CardBase,
    ListPower,
    PanelButtons,
    QueryState,
  },
  methods: {
    camelCase,
    handleUpdate() {
      this.collectionsStoreModules.forEach((module) => {
        const path = `${module}/queryItems`;
        this.$store.dispatch(path);
      });
    },
  },
  name: "list-page",
  props: {
    collectionsStoreModules: {
      default: () => [],
      type: Array,
    },
    field: {
      required: true,
      type: String,
    },
    headerText: {
      required: true,
      type: String,
    },
    items: {
      default: () => [],
      type: Array,
    },
    primaryKey: {
      default: "id",
      type: String,
    },
  },
};
</script>
