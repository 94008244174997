var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "OverlayBase",
    { attrs: { "is-busy": _vm.isBusy } },
    [
      _c("DescriptionText", [
        _vm._v(
          "\n    Por favor, escolha um dos canais de comunicação cadastrados para te enviarmos um "
        ),
        _c("strong", [_vm._v("código de confirmação")]),
        _vm._v(".\n  "),
      ]),
      _vm._v(" "),
      _c("DialogForm", {
        attrs: {
          "external-errors": _vm.lastError,
          "default-payload": _vm.defaultPayload,
        },
        on: {
          save: function ($event) {
            return _vm.execute(_vm.handleSelect, $event)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function ({ payload }) {
              return [
                _c(
                  "RowForm",
                  [
                    _c(
                      "ColBase",
                      [
                        _c("ControlRadio", {
                          attrs: {
                            field: "channel",
                            label:
                              "Para onde deseja enviar o código de confirmação?",
                            options: _vm.options,
                            "value-field": "tipo",
                            "text-field": "mask",
                          },
                          model: {
                            value: payload.tipo,
                            callback: function ($$v) {
                              _vm.$set(payload, "tipo", $$v)
                            },
                            expression: "payload.tipo",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "actions",
            fn: function () {
              return [
                _c("ButtonSecondary", {
                  attrs: { label: "Cancelar", field: "cancel" },
                  on: { click: _vm.resetState },
                }),
                _vm._v(" "),
                _c("ButtonPrimary", {
                  ref: "selectChannelButton",
                  attrs: { label: "Prosseguir", field: "selectChannel" },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }