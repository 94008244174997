var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.text
    ? _c(
        "div",
        [
          _c(
            "p",
            {
              staticClass: "header-text m-0 mb-2",
              class: [_vm.element, _vm.truncate && "text-truncate"],
            },
            [_vm._v("\n    " + _vm._s(_vm.text) + "\n  ")]
          ),
          _vm._v(" "),
          _c("HeaderDetail"),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }