<template>
  <div class="dark-canvas min-vh-100">
    <slot />
  </div>
</template>

<script>
export default {
  name: "dark-canvas",
};
</script>

<style scoped>
.dark-canvas {
  background-color: var(--w-color-neutral-20);
  background-image: url("~@/public/pattern.svg");
}
</style>
