<template>
  <div class="mt-3 mx-auto">
    <HeaderText :text="headerText" />
    <div
      class="content-wrapper bg-white rounded mt-4 p-3"
      :class="{ shadow: !hideShadow }"
    >
      <LinearBase
        class="linear"
        :is-busy="showLinear && !showOverlay"
      />
      <OverlayBase :is-busy="showOverlay">
        <slot />
      </OverlayBase>
    </div>
  </div>
</template>

<script>
import { LinearBase, OverlayBase } from "../async";
import { HeaderText } from "../text";

export default {
  components: { HeaderText, LinearBase, OverlayBase },
  name: "card-base",
  props: {
    headerText: {
      default: null,
      type: String,
    },
    hideShadow: {
      default: false,
      type: Boolean,
    },
    showLinear: {
      default: false,
      type: Boolean,
    },
    showOverlay: {
      default: false,
      type: Boolean,
    },
    wrapperStyle: {
      default: null,
      type: Object,
    },
  },
};
</script>

<style scoped>
.content-wrapper {
  position: relative;
  overflow: hidden;
}

.linear {
  position: absolute;
  top: 0px;
  left: 0px;
}
</style>
