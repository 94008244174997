var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "search-text" },
    [
      _c(
        "InputBase",
        _vm._b(
          {
            attrs: {
              id: _vm.camelCase("search", _vm.field),
              value: _vm.value,
              debounce: "500",
              placeholder: "digite para pesquisar...",
              "input-class": "rounded-pill bg-transparent pr-5",
            },
            on: {
              input: function ($event) {
                return _vm.$emit("input", $event)
              },
            },
          },
          "InputBase",
          _vm.$attrs,
          false
        )
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "search-text-app-icon-wrapper" },
        [_c("AppIcon", { attrs: { name: "search", variant: "info" } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }