import {
  authStore,
  changelogStore,
  clientesStore,
  contasStore,
  fluxosAdministrativoStore,
  fluxosArchivedStore,
  fluxosExternoStore,
  gestoresStore,
  gruposStore,
  interessadosStore,
  isConfiguration,
  linksStore,
  requisitantesStore,
} from "@/data";
import { turnOffModules, turnOnModules } from "@/lib";
import Vue from "vue";
import Vuex from "vuex";

export async function initStore() {
  Vue.use(Vuex);

  const vuexStore = new Vuex.Store({
    modules: {
      auth: authStore,
      changelog: changelogStore,
      clientes: clientesStore,
      contas: contasStore,
      fluxosAdministrativo: fluxosAdministrativoStore,
      fluxosArchived: fluxosArchivedStore,
      fluxosExterno: fluxosExternoStore,
      gestores: gestoresStore,
      grupos: gruposStore,
      interessados: interessadosStore,
      links: linksStore,
      requisitantes: requisitantesStore,
    },
    strict: isConfiguration("WEB_MODE", { expected: "production", ignoreCase: true }),
  });

  const getUserPerfil = (state, getters) =>
    getters["auth/perfilIfSignedIn"];

  vuexStore.watch(getUserPerfil, (userPerfil) => {
    restartModules(userPerfil, vuexStore);
  });

  return vuexStore;
}

// inicialmente foi utilizado o recurso de cadastrar e descadastrar módulos dinamicamente da vuex store. entretanto, essa abordagem não funcionou. os tracks de reatividade do vue (computed, watch...) não conseguiam reagir a mudanças nos getters dos módulos dinâmicos em algumas situações. daí foi implementado o design de se cadastrar todos os módulos desde o inicio e depois adotar o conceito de "on" e "off" para lidar com limpeza de dados e controle de comportamentos como polling.

function restartModules(userPerfil, store) {
  const allModules = [
    "clientes",
    "fluxosAdministrativo",
    "fluxosArchived",
    "fluxosExterno",
    "gestores",
    "grupos",
    "interessados",
    "requisitantes",
  ];

  turnOffModules(store, allModules);

  const perfisModules = {
    ADMINISTRADOR: [
      "clientes",
      "grupos",
      "gestores",
      "requisitantes",
      "interessados",
      "contas",
    ],
    GESTOR: ["contas", "grupos", "gestores", "requisitantes", "interessados"],
    // a ordem dos módulos influencia no inicio do comportamento de querying de dados portanto manter essa ordem preservando a capacidade de consultar e criar fluxos
    INTERESSADO: ["fluxosExterno"],
    REQUISITANTE: [
      "fluxosAdministrativo",
      "grupos",
      "gestores",
      "requisitantes",
      "interessados",
      "contas",
      "fluxosArchived",
      "links",
    ],
  };

  const perfilModules = perfisModules[userPerfil] || [];
  turnOnModules(store, perfilModules);
}
