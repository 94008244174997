var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "FilesManager",
        _vm._g(
          _vm._b(
            {
              attrs: {
                extensions: ["pdf"],
                field: _vm.field,
                "file-icon": _vm.pdfIcon,
                "file-upload": _vm.pdfUpload,
                "file-download": _vm.pdfDownload,
                "file-delete": _vm.pdfDelete,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "message",
                    fn: function () {
                      return [_vm._t("message")]
                    },
                    proxy: true,
                  },
                  {
                    key: "actions",
                    fn: function ({ file: pdf, index, isUploading }) {
                      return [
                        _vm._t("actions", null, {
                          index: index,
                          pdf: pdf,
                          isUploading: isUploading,
                        }),
                        _vm._v(" "),
                        _c("PdfButtonView", {
                          attrs: {
                            field: _vm.camelCase("view", _vm.field, index),
                            "file-path": pdf.path,
                            "file-name": pdf.nome,
                            hide: _vm.hidePdfView(pdf),
                            disabled: isUploading,
                            "pdf-download": _vm.pdfDownload,
                          },
                        }),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            },
            "FilesManager",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }