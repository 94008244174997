import Vue from "vue";

import MessageAlert from "./message-alert";
import MessageError from "./message-error";

export async function showAlert(message, options = {}) {
  const MessageAlertInstance = mountModal(MessageAlert, { message, ...options });
  const hasConfirmed = await MessageAlertInstance.confirm();
  return hasConfirmed;
}

export function showError(message, options = {}) {
  const MessageErrorInstance = mountModal(MessageError, { message, ...options });
  return MessageErrorInstance.show();
}

function mountModal(type, options = {}) {
  const ModalClass = Vue.extend(type);
  const modalInstance = new ModalClass({
    propsData: {
      ...options,
    },
  });
  modalInstance.$mount();
  return modalInstance;
}
