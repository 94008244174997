var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.hasMultiplePerfis
        ? [_c("user-menu-profile"), _vm._v(" "), _c("menu-divider")]
        : _vm._e(),
      _vm._v(" "),
      _vm.isChangelogAvailable
        ? [_c("user-menu-changelog"), _vm._v(" "), _c("menu-divider")]
        : _vm._e(),
      _vm._v(" "),
      _c("user-menu-help"),
      _vm._v(" "),
      _c("menu-divider"),
      _vm._v(" "),
      _c("user-menu-sign-out"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }