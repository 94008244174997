import LayoutHero from "./layout-hero";
import LayoutNav from "./layout-nav";

export function pickLayout(key = "default") {
  const layoutsMap = {
    LayoutHero,
    LayoutNav,
    default: "div",
  };
  return layoutsMap[key];
}
