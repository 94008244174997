<template>
  <ControlWrapper
    :control-component="InputBase"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import ControlWrapper from "./control-wrapper";
import InputBase from "./input-base";

export default {
  components: { ControlWrapper },
  data() {
    return {
      InputBase,
    };
  },
  name: "control-input",
};
</script>
