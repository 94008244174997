var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("NavUser", {
    attrs: {
      title: _vm.nome,
      field: "user",
      badge: _vm.badge,
      "sub-title": `${_vm.perfil} | ${_vm.nomeCliente}`,
    },
    scopedSlots: _vm._u([
      {
        key: "menu",
        fn: function () {
          return [_c("user-menu")]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }