import { incDate } from "@/lib";

import { getConfiguration } from "../configuration";

export const stateKey = "otp";

export const initialState = {
  channels: [],
  message: null,
  requested: null,
};

export const getters = {
  channelOfType: (state, { channels }) => tipo => channels.find(c => c.tipo === tipo),
  channels: state => state[stateKey].channels,
  hasChannels: (state, { channels }) => channels.length > 0,
  isOtpSent: (state, { otpMessage }) => !!otpMessage,
  otpBlockedUntil: (state, { otpRequested }) => {
    if (!otpRequested) return null;
    const seconds = getConfiguration("WEB_OTP_BLOCKED_SECONDS");
    return incDate(otpRequested, { seconds });
  },
  otpMessage: state => state[stateKey].message,
  otpRequested: state => state[stateKey].requested,

};

export const mutations = {
  setChannels(state, channels = []) {
    channels.sort((a, b) => (a.mask < b.mask ? 1 : -1));
    state[stateKey].channels = channels;
  },

  setOtpMessage(state, otpMessage = null) {
    state[stateKey].message = otpMessage;
  },

  setOtpRequested(state, otpRequested = null) {
    state[stateKey].requested = otpRequested;
  },
};

export const actions = {
  async challengeUserOtp({ commit, dispatch, getters }, { otp }) {
    const payload = {
      endpoint: "/verificar-otp",
      method: "patch",
      record: {
        idConta: getters.idConta,
        otp,
      },
    };
    const loginResponse = await dispatch("requestAction", payload);
    const {
      conta: { codigo, nome, perfis },
      expiracao: expirationAsDateString,
      // registration options é um conjunto de dados usado pelo authenticator do navegador ou sistema operacional para registrar um novo dispositivo web-authn vinculado a essa conta que sucedeu na verificação do otp.
      registrationOptions,
      token,
    } = loginResponse;

    commit("setWebAuthnRegistrationOptions", registrationOptions);
    commit("setToken", token);
    commit("setExpiration", expirationAsDateString);
    commit("setCodigo", codigo);
    commit("setNome", nome);
    commit("setPerfis", perfis);

    if (perfis.length <= 0) {
      throw new Error("A conta deste usuário não possui perfil vinculado");
    }
  },

  async triggerUserOtp({ commit, dispatch, getters: { idConta } }, { tipo }) {
    const payload = {
      endpoint: "/enviar-otp",
      method: "patch",
      record: {
        idConta: idConta,
        tipoCanal: tipo,
      },
    };
    const { message } = await dispatch("requestAction", payload);

    commit("setOtpRequested", new Date());
    commit("setOtpMessage", message);
  },
};
