import { createBaseStoreModule } from "./create-base";
import { createRequestFeatures } from "./features-request";

/*
  concentra a criação de store modules conectadas com algum serviço de backend.
  o objetivo é centralizar solução de problemas comuns relacionadas a comunicação
  assíncrona como um status de loading capaz de lidar requests concorrentes.
*/

export function createConnectedStoreModule(config) {
  const {
    actions: actionsConfig,
    getters: gettersConfig,
    mutations: mutationsConfig,
    state: stateConfig,
  } = config;

  const {
    actions: actionsRequest,
    getters: gettersRequest,
    mutations: mutationsRequest,
    state: stateRequest,
  } = createRequestFeatures(config);

  return createBaseStoreModule({
    actions: {
      ...actionsRequest,
      ...actionsConfig,
    },
    getters: {
      ...gettersRequest,
      ...gettersConfig,
    },
    mutations: {
      ...mutationsRequest,
      ...mutationsConfig,
    },
    namespaced: true,
    state: {
      ...stateRequest,
      ...stateConfig,
    },
  });
}
