var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ToolbarSection",
    [
      _c("ButtonIcon", {
        attrs: {
          field: _vm.camelCase("first", _vm.field),
          disabled: _vm.innerDisabled || _vm.pagesCurrent === 1,
          icon: "first",
        },
        on: {
          click: function ($event) {
            return _vm.$emit("update:pages-current", 1)
          },
        },
      }),
      _vm._v(" "),
      _c("ButtonIcon", {
        attrs: {
          field: _vm.camelCase("previous", _vm.field),
          disabled: _vm.innerDisabled || _vm.pagesCurrent === 1,
          icon: "previous",
        },
        on: {
          click: function ($event) {
            return _vm.$emit("update:pages-current", _vm.pagesCurrent - 1)
          },
        },
      }),
      _vm._v(" "),
      _c("ButtonIcon", {
        attrs: {
          field: _vm.camelCase("current", _vm.field),
          disabled: "",
          label: _vm.pagesCurrent || 1,
        },
      }),
      _vm._v(" "),
      _c("ButtonIcon", {
        attrs: {
          field: _vm.camelCase("next", _vm.field),
          disabled: _vm.innerDisabled || _vm.pagesCurrent === _vm.pagesTotal,
          icon: "next",
        },
        on: {
          click: function ($event) {
            return _vm.$emit("update:pages-current", _vm.pagesCurrent + 1)
          },
        },
      }),
      _vm._v(" "),
      _c("ButtonIcon", {
        attrs: {
          field: _vm.camelCase("last", _vm.field),
          disabled: _vm.innerDisabled || _vm.pagesCurrent === _vm.pagesTotal,
          icon: "last",
        },
        on: {
          click: function ($event) {
            return _vm.$emit("update:pages-current", _vm.pagesTotal)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }