var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "LogoBase",
    _vm._b(
      { attrs: { src: "/public/logo-serpro.svg" } },
      "LogoBase",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }