<template>
  <div>
    <MenuText>Ajuda</MenuText>
    <MenuItem
      href="/manual"
      target="_blank"
    >
      Manual de utilização
    </MenuItem>
  </div>
</template>

<script>
import { MenuItem, MenuText } from "@/lib";

export default {
  components: { MenuItem, MenuText },
  name: "user-menu-help",
};
</script>
