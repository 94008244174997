<template>
  <SideBar
    id="navSidebar"
    v-bind="$attrs"
    :links="sidebarLinks"
    v-on="$listeners"
  />
</template>

<script>
import { SideBar } from "@/lib";
import { mapGetters } from "vuex";

const sidebarInteressadoTemplate = {
  icon: "person",
  text: "Interessados",
  to: "/interessados",
};
const sidebarFluxosTemplate = {
  group: "Assinatura",
  icon: "executive",
  text: "Fluxos",
};

const sidebarLinksFull = [
  {
    group: "Administração",
    icon: "building",
    perfis: ["ADMINISTRADOR"],
    text: "Clientes",
    to: "/clientes",
  },
  {
    group: "Administração",
    icon: "cog",
    perfis: ["ADMINISTRADOR", "GESTOR"],
    text: "Gestores",
    to: "/gestores",
  },
  {
    group: "Administração",
    icon: "people",
    perfis: ["ADMINISTRADOR", "GESTOR"],
    text: "Grupos",
    to: "/grupos",
  },
  {
    group: "Administração",
    icon: "person",
    perfis: ["ADMINISTRADOR", "GESTOR"],
    text: "Requisitantes",
    to: "/requisitantes",
  },
  {
    ...sidebarFluxosTemplate,
    perfis: ["REQUISITANTE"],
    to: "/fluxos-administrativo",
  },
  {
    group: "Assinatura",
    icon: "archive",
    perfis: ["REQUISITANTE"],
    text: "Fluxos Arquivados",
    to: "/fluxos-archived/list",
  },
  {
    ...sidebarFluxosTemplate,
    perfis: ["INTERESSADO"],
    to: "/fluxos-externo",
  },
  {
    ...sidebarInteressadoTemplate,
    group: "Administração",
    perfis: ["ADMINISTRADOR", "GESTOR"],
  },
  {
    ...sidebarInteressadoTemplate,
    group: "Assinatura",
    perfis: ["REQUISITANTE"],
  },
];

export default {
  components: {
    SideBar,
  },
  computed: {
    ...mapGetters("auth", ["perfilIsAnyOf"]),
    sidebarLinks() {
      return sidebarLinksFull.filter(link =>
        this.perfilIsAnyOf(link.perfis),
      );
    },
  },
  name: "layout-nav-side",
};
</script>
