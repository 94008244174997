var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.enabled
    ? _c(
        "div",
        { style: { position: "fixed", bottom: 0, right: 0, "z-index": 10000 } },
        [
          _c("button", { on: { click: _vm.throwError } }, [
            _vm._v("\n    error\n  "),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }