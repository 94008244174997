import { asArray } from "@cert/web";

export function hasDadosBasicos(fluxo) {
  if (!fluxo) return false;

  const { dadosBasicos } = fluxo;
  if (!dadosBasicos) return false;

  const { descricao, idGrupo, nome, status } = dadosBasicos;
  return nome && descricao && idGrupo && status;
}

export function isStatus(fluxo, statuses) {
  if (!hasDadosBasicos(fluxo)) return false;

  const statusesArray = asArray(statuses);
  return statusesArray.includes(fluxo.dadosBasicos.status);
}

export function isCriado(fluxo) {
  return isStatus(fluxo, "CRIADO");
}

export function isIniciado(fluxo) {
  return isStatus(fluxo, "INICIADO");
}

export function isFinalizado(fluxo) {
  return isStatus(fluxo, "FINALIZADO");
}

export function isCancelado(fluxo) {
  return isStatus(fluxo, "CANCELADO");
}

export function isArchived(fluxo) {
  return isStatus(fluxo, "ARQUIVADO");
}

export function isQualificada(fluxo) {
  if (!hasDadosBasicos(fluxo)) return false;
  return fluxo.dadosBasicos.isIcp;
}
