var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "FragmentBase",
    _vm._l(_vm.enumsState, function (enumState) {
      return _c(
        "BButton",
        {
          key: enumState.text,
          staticClass: "button-status mr-1",
          attrs: {
            id: _vm.camelCase("searchEnum", enumState.text),
            pressed: enumState.isActive,
            pill: "",
            variant: _vm.decideVariant(enumState.color),
          },
          on: {
            "update:pressed": function ($event) {
              return _vm.$set(enumState, "isActive", $event)
            },
          },
        },
        [_vm._v("\n    " + _vm._s(enumState.text.toUpperCase()) + "\n  ")]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }