<template>
  <BNavbar
    class="navs-nav-bar"
    :sticky="true"
    variant="light"
    type="light"
  >
    <ButtonToggle
      v-if="!hideHamburger"
      v-model="innerIsOpen"
      field="navHamburger"
    />

    <BNavbarBrand
      to="/"
      class="d-flex align-items-center p-0 m-0 ml-2"
    >
      <slot name="logo" />
    </BNavbarBrand>

    <slot name="links" />
  </BNavbar>
</template>

<script>
import { ButtonToggle } from "../buttons";
import { BNavbar, BNavbarBrand } from "../bv";
export default {
  components: { BNavbar, BNavbarBrand, ButtonToggle },
  computed: {
    hideHamburger() {
      return this.isOpen === null;
    },
  },
  data() {
    return {
      innerIsOpen: this.isOpen,
    };
  },
  name: "nav-bar",
  props: {
    isOpen: {
      default: null,
      type: Boolean,
    },
  },
  watch: {
    innerIsOpen(newValue) {
      this.$emit("update:isOpen", newValue);
    },
    isOpen(newValue) {
      if (newValue !== this.innerIsOpen) {
        this.innerIsOpen = newValue;
      }
    },
  },
};
</script>

<style scoped>
.navs-nav-bar {
  box-shadow: var(--w-shadow-neutral);
  height: var(--w-size-50);
}
</style>
