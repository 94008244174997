var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.hide
    ? _c(
        "BFormCheckbox",
        {
          attrs: { disabled: _vm.disabled, switch: _vm.$attrs.switch },
          model: {
            value: _vm.checked,
            callback: function ($$v) {
              _vm.checked = $$v
            },
            expression: "checked",
          },
        },
        [_vm._v("\n  " + _vm._s(_vm.placeholder) + "\n")]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }