<template>
  <div>
    <template v-if="hasMultiplePerfis">
      <user-menu-profile />
      <menu-divider />
    </template>
    <template v-if="isChangelogAvailable">
      <user-menu-changelog />
      <menu-divider />
    </template>
    <user-menu-help />
    <menu-divider />
    <user-menu-sign-out />
  </div>
</template>

<script>
import { MenuDivider } from "@/lib";
import { mapGetters } from "vuex";

import UserMenuChangelog from "./user-menu-changelog.vue";
import UserMenuHelp from "./user-menu-help.vue";
import UserMenuProfile from "./user-menu-profile.vue";
import UserMenuSignOut from "./user-menu-sign-out.vue";

export default {
  components: { MenuDivider, UserMenuChangelog, UserMenuHelp, UserMenuProfile, UserMenuSignOut },
  computed: {
    ...mapGetters("auth", ["perfis", "perfilIsAnyOf"]),
    ...mapGetters("changelog", ["howManyLogs"]),
    hasMultiplePerfis() {
      return this.perfis.length > 1;
    },
    isChangelogAvailable() {
      return this.howManyLogs > 0;
    },
  },
  name: "UserMenu",
};
</script>
