import * as pdfjsLib from "pdfjs-dist";

export function loadPdf(path) {
  if (!pdfjsLib.GlobalWorkerOptions.workerPort) {
    const newWorker = new Worker(
      new URL("pdfjs-dist/build/pdf.worker.js", import.meta.url),
    );
    pdfjsLib.GlobalWorkerOptions.workerPort = newWorker;
  }

  const loadingTask = pdfjsLib.getDocument(path);
  return loadingTask.promise;
}
