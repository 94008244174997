var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dialog-wrapper px-5 py-3 d-flex flex-column" },
    [
      _c("div", [
        _c(
          "div",
          { staticClass: "d-none d-lg-flex justify-content-end" },
          [_c("LogoSerpro", { attrs: { height: "30px", width: "90px" } })],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-center justify-content-lg-start",
          },
          [_c("LogoApp", { staticClass: "mt-3" })],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-none d-lg-block" },
          [
            _c("HeaderText", {
              staticClass: "mt-4",
              attrs: { text: "Assine vários documentos num só lugar" },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-6" },
        [
          _c(_vm.dialog, {
            tag: "component",
            attrs: {
              session: _vm.session,
              perfil: _vm.perfil,
              "link-token": _vm.linkToken,
              "link-id": _vm.linkId,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }