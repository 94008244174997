<template>
  <BButton
    :id="camelCase('button', field)"
    variant="light"
    class="text-secondary"
    v-bind="$attrs"
    @click="$emit('toggle', !isOpen)"
  >
    <AppIcon
      v-if="isOpen"
      name="close"
    />
    <AppIcon
      v-else
      :name="icon"
    />
  </BButton>
</template>

<script>
import { camelCase } from "@cert/web";

import { BButton } from "../bv";
import { AppIcon } from "../icons";

export default {
  components: { AppIcon, BButton },
  methods: {
    camelCase,
  },
  model: {
    event: "toggle",
    prop: "isOpen",
  },
  name: "button-toggle",
  props: {
    field: {
      default: null,
      type: String,
    },
    icon: {
      default: "hamburger",
      type: String,
    },
    isOpen: {
      default: false,
      type: Boolean,
    },
  },
};
</script>
