var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BButton",
    {
      directives: [
        { name: "toggle", rawName: "v-toggle:[parentId]", arg: _vm.parentId },
      ],
      staticClass: "tab-accordion-header px-3 py-1",
      attrs: {
        id: _vm.camelCase("toggle", _vm.parentId),
        disabled: _vm.disabled,
        block: "",
        variant: _vm.disabled ? "outline-secondary" : "outline-primary",
        role: "tab",
      },
    },
    [
      _c(
        "div",
        { staticClass: "d-flex align-items-center justify-content-between" },
        [
          _c(
            "div",
            { staticClass: "d-flex align-items-center" },
            [
              _vm.iconName
                ? _c("AppIcon", {
                    attrs: { name: _vm.iconName, badge: _vm.iconBadge },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("span", { staticClass: "ml-3 d-flex flex-column" }, [
                _c("span", [_vm._v(_vm._s(_vm.title))]),
                _vm._v(" "),
                _c("span", { staticClass: "mt-n2 text-warning" }, [
                  _c("small", [_vm._v(_vm._s(_vm.subtitle))]),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", [
            _c(
              "span",
              { staticClass: "when-open" },
              [_c("AppIcon", { attrs: { name: "caret-up" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "when-closed" },
              [_c("AppIcon", { attrs: { name: "caret-down" } })],
              1
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }