<template>
  <CenterBase>
    <ColorShifter desktop="var(--w-color-neutral-10)">
      <DisplayText :size="4">
        O NeoSigner está em manutenção
      </DisplayText>
      <SpacerBase
        vertical="20"
        block
      />
      <DisplayText :size="4">
        Retornaremos em breve
      </DisplayText>
    </ColorShifter>
    <SpacerBase
      vertical="20"
      block
    />
    <ButtonSecondary
      href="https://form.omni.serpro.gov.br/upperScreenForm/3450"
      target="_blank"
      class="mt-3 w-auto"
      label="Entre em contato com o suporte"
    />
  </CenterBase>
</template>

<script>
import { CenterBase, SpacerBase } from "@/lib";
import { ColorShifter, DisplayText } from "@/lib";

import { ButtonSecondary } from "@/lib";

export default {
  components: { ButtonSecondary, CenterBase, ColorShifter, DisplayText, SpacerBase },
  name: "maintenance-page",
};
</script>
