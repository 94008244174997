export function convertFromString(value, defaultValue = null) {
  const triggersForDefaultValue = [undefined, "undefined", null, "null", ""];
  if (triggersForDefaultValue.includes(value)) return defaultValue;

  if (typeof value !== "string") return value;

  if (value === "false") return false;
  if (value === "true") return true;

  if (typeof value === "string" && !isNaN(value))
    return value.includes(".") ? parseFloat(value) : parseInt(value);

  return value;
}
