import { request } from "@/api";
import { createCollectionStoreModule } from "@/lib";

import { getConfiguration } from "../configuration";
const baseEndpoint = "/contas/perfil/INTERESSADO";

export const interessadosStore = createCollectionStoreModule({
  apiService: "autenticador",
  editEndpoint: baseEndpoint,
  newEndpoint: baseEndpoint,
  pageLimit: getConfiguration("WEB_PAGINATION_LIMIT"),
  parseItemToRecord: item => ({
    ativo: true,
    perfil: "INTERESSADO",
    tipoConta: "PESSOA",
    ...item,
  }),
  pollingSeconds: getConfiguration("WEB_POLLING_SECONDS"),
  queryEndpoint: baseEndpoint,
  request,
});
