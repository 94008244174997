<template>
  <NavUser
    :title="nome"
    field="user"
    :badge="badge"
    :sub-title="`${perfil} | ${nomeCliente}`"
  >
    <template #menu>
      <user-menu />
    </template>
  </NavUser>
</template>

<script>
import { NavUser } from "@/lib";
import { UserMenu } from "@/widgets";
import { mapGetters } from "vuex";

export default {
  components: { NavUser, UserMenu },
  computed: {
    ...mapGetters("auth", ["nome", "nomeCliente", "perfil"]),
    ...mapGetters("changelog", ["prettyHowManyUnread", "howManyLogs"]),
    badge() {
      if (this.howManyLogs <= 0) return null;
      return this.prettyHowManyUnread;
    },
  },
  name: "LayoutNavUser",
};
</script>
