var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "FragmentBase",
    [
      _c(
        "LogoBase",
        _vm._b(
          {
            staticClass: "logo-full",
            style: _vm.cssVars,
            attrs: { src: _vm.src.full, height: "40px", width: "200px" },
          },
          "LogoBase",
          _vm.$attrs,
          false
        )
      ),
      _vm._v(" "),
      _c(
        "LogoBase",
        _vm._b(
          {
            staticClass: "logo-min",
            style: _vm.cssVars,
            attrs: { src: _vm.src.min, height: "40px" },
          },
          "LogoBase",
          _vm.$attrs,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }