<template>
  <BButton
    :id="camelCase('toggle', parentId)"
    v-toggle:[parentId]
    :disabled="disabled"
    block
    :variant="disabled ? 'outline-secondary' : 'outline-primary'"
    role="tab"
    class="tab-accordion-header px-3 py-1"
  >
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center">
        <AppIcon
          v-if="iconName"
          :name="iconName"
          :badge="iconBadge"
        />
        <span class="ml-3 d-flex flex-column">
          <span>{{ title }}</span>
          <span
            class="mt-n2 text-warning"
          ><small>{{ subtitle }}</small></span>
        </span>
      </div>
      <div>
        <span class="when-open">
          <AppIcon name="caret-up" />
        </span>
        <span class="when-closed">
          <AppIcon name="caret-down" />
        </span>
      </div>
    </div>
  </BButton>
</template>

<script>
import { camelCase } from "@cert/web";

import { BButton } from "../bv";
import { AppIcon } from "../icons";

export default {
  components: { AppIcon, BButton },
  methods: {
    camelCase,
  },
  name: "tab-accordion-header",
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    iconBadge: {
      default: null,
      type: [String, Number],
    },
    iconName: {
      default: null,
      type: String,
    },
    parentId: {
      required: true,
      type: String,
    },
    subtitle: {
      default: null,
      type: String,
    },
    title: {
      required: true,
      type: String,
    },
  },
};
</script>

<style scoped>
.tab-accordion-header {
  height: var(--w-size-50);
  font-size: var(--w-size-30);
  text-align: left;
  border-color: transparent;
  border-bottom-color: var(--w-color-neutral-20);
}

.collapsed .when-open,
.not-collapsed .when-closed {
  display: none;
}
</style>
