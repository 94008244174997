var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("ListPowerSearch", {
        staticClass: "mt-2",
        attrs: { config: _vm.searchConfig, "all-items": _vm.items },
        model: {
          value: _vm.searchedItems,
          callback: function ($$v) {
            _vm.searchedItems = $$v
          },
          expression: "searchedItems",
        },
      }),
      _vm._v(" "),
      _c(
        "RowBase",
        { staticClass: "mt-2" },
        [
          _c(
            "ColBase",
            [
              _c(
                "ListBase",
                _vm._g(
                  _vm._b(
                    {
                      attrs: {
                        field: _vm.field,
                        items: _vm.searchedItems,
                        fields: _vm.enhancedFields,
                        "is-loading": _vm.isLoading,
                        "primary-key": _vm.primaryKey,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "cell(enum)",
                            fn: function (data) {
                              return [
                                _vm.chooseEnumColorForItem(data.item)
                                  ? _c("AppIcon", {
                                      attrs: {
                                        name: "circle",
                                        variant: _vm.chooseEnumColorForItem(
                                          data.item
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            },
                          },
                          {
                            key: "cell(actions)",
                            fn: function (data) {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "d-flex flex-nowrap" },
                                  [
                                    _vm._t("actions", null, {
                                      index: data.index,
                                      item: data.item,
                                    }),
                                  ],
                                  2
                                ),
                              ]
                            },
                          },
                          _vm._l(_vm.$scopedSlots, function (_, slot) {
                            return {
                              key: slot,
                              fn: function (scope) {
                                return [_vm._t(slot, null, null, scope)]
                              },
                            }
                          }),
                        ],
                        null,
                        true
                      ),
                    },
                    "ListBase",
                    _vm.$attrs,
                    false
                  ),
                  _vm.$listeners
                )
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }