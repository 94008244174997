var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("AsyncExecute", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ isBusy, execute, lastError }) {
          return [
            _c(
              "OverlayBase",
              { attrs: { "is-busy": isBusy } },
              [
                _c("DescriptionText", [
                  _vm._v(
                    "\n      Escolha qual perfil de usuário você usará nesta sessão\n    "
                  ),
                ]),
                _vm._v(" "),
                _c("DialogForm", {
                  attrs: {
                    "default-payload": _vm.defaultPayload,
                    "external-errors": lastError,
                  },
                  on: {
                    save: function ($event) {
                      return execute(_vm.signIn, $event)
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "body",
                        fn: function ({ payload }) {
                          return [
                            _c("ControlSelectSingle", {
                              attrs: {
                                options: _vm.perfis,
                                rules: "required",
                                field: "role",
                                label: "Perfil",
                              },
                              model: {
                                value: payload.perfil,
                                callback: function ($$v) {
                                  _vm.$set(payload, "perfil", $$v)
                                },
                                expression: "payload.perfil",
                              },
                            }),
                          ]
                        },
                      },
                      {
                        key: "actions",
                        fn: function () {
                          return [
                            _c("ButtonSecondary", {
                              attrs: { label: "Cancelar", field: "cancel" },
                              on: { click: _vm.resetState },
                            }),
                            _vm._v(" "),
                            _c("ButtonPrimary", {
                              attrs: {
                                label: "Escolher e entrar",
                                field: "choose",
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }