var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BAlert",
    { attrs: { variant: _vm.variant, show: "" } },
    [
      _c("AppIcon", { attrs: { name: _vm.icon } }),
      _vm._v(" "),
      _c("SpacerBase", { attrs: { horizontal: "10" } }),
      _vm._v("\n  " + _vm._s(_vm.text) + "\n"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }