var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("OverlayBase", { attrs: { "is-busy": _vm.pageIsBusy } }, [
    _c(
      "div",
      { staticClass: "page-wrapper" },
      [
        _c(
          "PdfDocumentPageRender",
          _vm._g(
            _vm._b(
              {
                on: {
                  renderStarted: function ($event) {
                    _vm.pageIsBusy = true
                  },
                  renderFinished: function ($event) {
                    _vm.pageIsBusy = false
                  },
                },
              },
              "PdfDocumentPageRender",
              _vm.$attrs,
              false
            ),
            _vm.$listeners
          )
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }