import { asDate } from "../as-date";

export function formatDate(maybeDate, options) {
  const date = asDate(maybeDate);
  if (!date) return "";

  const defaultLocale = "pt-BR";
  if (!options)
    return date.toLocaleString(defaultLocale, {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

  const { locale, ...rest } = options;
  return date.toLocaleString(locale || defaultLocale, rest);
}
