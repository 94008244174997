var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _vm._t("default", null, {
        isBusyWithQuerying: _vm.isBusyWithQuerying,
        isBusyWithFirstQuerying: _vm.isBusyWithFirstQuerying,
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }