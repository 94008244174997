var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ToolbarSection",
    [
      _c("ButtonIcon", {
        attrs: {
          field: _vm.camelCase("minus", _vm.field),
          disabled: _vm.disabled || _vm.zoom <= 5,
          icon: "zoom-out",
        },
        on: {
          click: function ($event) {
            _vm.$emit("update:zoom", Math.round(_vm.zoom - 5))
          },
        },
      }),
      _vm._v(" "),
      _c("ButtonIcon", {
        staticClass: "zoom-label",
        attrs: {
          field: _vm.camelCase("current", _vm.field),
          disabled: "",
          label: _vm.zoomPretty,
        },
      }),
      _vm._v(" "),
      _c("ButtonIcon", {
        attrs: {
          field: _vm.camelCase("plus", _vm.field),
          disabled: _vm.disabled || _vm.zoom > 800,
          icon: "zoom-in",
        },
        on: {
          click: function ($event) {
            _vm.$emit("update:zoom", Math.round(_vm.zoom + 5))
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }