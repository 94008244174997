var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "OverlayBase",
        { attrs: { "is-busy": _vm.documentIsBusy } },
        [
          _c("PdfDocumentPage", {
            attrs: {
              field: _vm.camelCase("page", _vm.field),
              "pdf-document": _vm.pdfDocument,
              scale: _vm.zoom / 100,
              "page-number": _vm.currentPageNumber,
              overlays: _vm.overlays,
              "is-busy": _vm.pageIsBusy,
            },
            on: {
              "update:isBusy": function ($event) {
                _vm.pageIsBusy = $event
              },
              "update:is-busy": function ($event) {
                _vm.pageIsBusy = $event
              },
              pageClick: _vm.handlePageClick,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("PdfDocumentToolbar", {
        staticClass: "mt-2",
        attrs: {
          field: _vm.camelCase("toolbar", _vm.field),
          disabled: _vm.documentIsBusy || _vm.pageIsBusy || !_vm.filePath,
          "pages-total": _vm.totalPages,
          "pages-current": _vm.currentPageNumber,
          zoom: _vm.zoom,
          "pdf-download": () =>
            _vm.pdfDownload({ name: _vm.fileName, path: _vm.filePath }),
        },
        on: {
          "update:pagesCurrent": function ($event) {
            _vm.currentPageNumber = $event
          },
          "update:pages-current": function ($event) {
            _vm.currentPageNumber = $event
          },
          "update:zoom": function ($event) {
            _vm.zoom = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }