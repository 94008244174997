<template>
  <div class="wrapper">
    <slot />
  </div>
</template>

<script>
export default {
  name: "center-base",
};
</script>

<style scoped>
.wrapper {
  display: grid;
  place-items: center;
}
</style>
