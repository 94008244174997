var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.hide
    ? _c(
        "BFormSelect",
        _vm._g(
          _vm._b(
            {
              class: { "read-only": _vm.disabled },
              attrs: {
                disabled: _vm.disabled,
                options: _vm.options,
                "text-field": _vm.textField,
                "value-field": _vm.valueField,
              },
            },
            "BFormSelect",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        )
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }