<template>
  <DarkCanvas>
    <LayoutNavBar :is-open.sync="showSideBar" />

    <LayoutNavSide :is-open.sync="showSideBar" />

    <div class="p-2 p-sm-5">
      <NavCrumb
        id="navCrumbs"
        :path="$route.meta.crumbs"
        class="d-none d-sm-flex"
      />
      <div>
        <slot />
      </div>
    </div>
  </DarkCanvas>
</template>

<script>
import { NavCrumb } from "@/lib";

import DarkCanvas from "./dark-canvas";
import LayoutNavBar from "./layout-nav-bar";
import LayoutNavSide from "./layout-nav-side";

export default {
  components: {
    DarkCanvas,
    LayoutNavBar,
    LayoutNavSide,
    NavCrumb,
  },
  data() {
    return {
      showSideBar: false,
    };
  },
  name: "layout-nav",
};
</script>
