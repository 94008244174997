var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "FragmentBase",
    [
      _c("ButtonIcon", {
        attrs: {
          field: _vm.field,
          hide: _vm.hide,
          disabled: _vm.disabled,
          icon: "view",
          tooltip: "visualizar pdf",
        },
        on: { click: _vm.handleViewPdf },
      }),
      _vm._v(" "),
      _c("PdfViewModal", {
        ref: "modal",
        attrs: {
          "file-path": _vm.filePath,
          "file-name": _vm.fileName,
          "pdf-download": _vm.pdfDownload,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }