<template>
  <div ref="fileManager">
    <PanelButtons>
      <template #start>
        <slot name="message" />
      </template>
      <template #default>
        <ButtonSecondary
          :field="camelCase('new', field)"
          :hide="hideUpload || !fileUpload"
          label="+ Adicionar Documento"
          size="sm"
          @click="handleUploadAttempt"
        />
      </template>
    </PanelButtons>
    <ListPower
      :field="field"
      :items="value"
      :fields="[
        { key: 'fileIcon', label: '', minSize: true, sortable: false },
        {
          key: 'nome',
          label: 'Arquivo',
          sortable: true,
          tdClass: 'text-break',
        },
      ]"
      v-bind="$attrs"
    >
      <template #cell(fileIcon)="{ item: file, index }">
        <BSpinner
          v-if="file.id === null"
          small
          class="align-middle"
        />
        <AppIcon
          v-else
          :field="camelCase(field, index)"
          v-bind="fileIcon(file)"
        />
      </template>
      <template #actions="{ item: file, index }">
        <slot
          name="actions"
          :index="index"
          :file="file"
          :is-uploading="isUploading(file)"
        />
        <FileButtonDownload
          :field="camelCase('download', field, index)"
          :file-name="file.nome"
          :file-path="file.path"
          :hide="hideFileDownload(file)"
          :disabled="isUploading(file)"
          :file-download="() => fileDownload(file)"
        />
        <ButtonIcon
          :field="camelCase('del', field, index)"
          :hide="hideFileDel(file) || !fileDelete"
          :disabled="isUploading(file)"
          icon="del"
          tooltip="remover"
          @click="handleDelAttempt(file)"
        />
      </template>
    </ListPower>
  </div>
</template>

<script>
import { ERROR_SEVERITIES, camelCase } from "@/lib/meta";
import { logger } from "@cert/web";

import { ButtonIcon, ButtonSecondary, PanelButtons } from "../buttons";
import { BSpinner } from "../bv";
import { AppIcon } from "../icons";
import { ListPower } from "../lists";
import FileButtonDownload from "./file-button-download";
import { selectFilesFromDialog } from "./files-manager-select-files";

export default {
  components: {
    AppIcon,
    BSpinner,
    ButtonIcon,
    ButtonSecondary,
    FileButtonDownload,
    ListPower,
    PanelButtons,
  },
  methods: {
    camelCase,
    async handleDelAttempt({ id }) {
      if (!this.fileDelete) return null;
      if (!id) return this.$showError("Este arquivo ainda está em processo de upload. Aguarde um pouco mais para apagar.");

      const file = this.value.find(item => item.id === id);
      const { nome } = file;
      const hasConfirmed = await this.$showAlert(`Confirma a exclusão do arquivo "${nome}"?`, { enableCancel: true });
      if (!hasConfirmed) return;

      const { status, value } = await this.fileDelete(id);
      if (status === "success") {
        this.$emit("input", this.value.filter(f => f.id !== id));
        this.$emit("afterDelete", file);
        this.$notifySuccess(`O arquivo "${nome}" foi removido com sucesso.`);
      }
      else {
        const message = `Ocorreu um erro ao tentar excluir o arquivo "${nome}".`;
        logger.error(message, { deleteResult: value, file, id });
        this.$showError(message);
      }
    },
    async handleUploadAttempt() {
      if (!this.fileUpload) return;

      const files = await selectFilesFromDialog(this.$refs.fileManager);
      if (files.length === 0) return;

      const errorMessage = this.validateFilesToUpload(files);
      if (errorMessage) return this.$showAlert(errorMessage);

      const fileListWithPendingUploads = this.value.concat(files.map(f => ({ id: null, nome: f.name })));
      this.$emit("input", fileListWithPendingUploads);

      for (const file of files) {
        const { status, value } = await this.fileUpload(file);
        const cleanedFileList = this.value.filter(f => f.nome.toUpperCase() !== file.name.toUpperCase());

        if (status === "success") {
          const fullFileList = cleanedFileList.concat(value);
          this.$emit("input", fullFileList);
          this.$emit("afterUpload", value);
          this.$notifySuccess(`O upload do arquivo "${file.name}" foi concluído com sucesso.`);
        }
        else {
          const { message, severity } = value;
          logger.error(message, { file, uploadResult: value });
          this.$emit("input", cleanedFileList);
          if (severity === ERROR_SEVERITIES.LOGICAL) {
            this.$showAlert(message);
          }
          else {
            this.$showError(message);
          }
        }
      }
    },
    isUploading(file) {
      return file.id === null;
    },
    validateFilesToUpload(newFiles) {
      const projectedQuantity = newFiles.length + this.value.length;
      if (this.maxFileQuantity && projectedQuantity > this.maxFileQuantity) {
        return `A quantidade máxima de arquivos permitida é ${this.maxFileQuantity}.`;
      }

      if (this.maxFileSize && newFiles.some(f => f.size > this.maxFileSize)) {
        return `O tamanho máximo de arquivo é ${this.maxFileSizeText}.`;
      }

      const extractExt = f => f.name.split(".").pop().toLowerCase();
      if (this.extensions && newFiles.some(f => !this.extensions.includes(extractExt(f)))) {
        const toUpper = this.extensions.map(value => value.toUpperCase());
        return `Os tipos de arquivo permitidos são : "${toUpper.join("\", \"")}".`;
      }

      const usedNames = this.value.map(f => f.nome.toLowerCase());
      if (usedNames.length > 0 && newFiles.some(f => usedNames.includes(f.name.toLowerCase()))) {
        return "O arquivo selecionado já está na lista. Exclua-o antes de substitui-lo.";
      }

      return false;
    },
  },
  name: "FilesManager",
  props: {
    extensions: {
      default: () => [],
      type: Array,
    },
    field: {
      required: true,
      type: String,
    },
    fileDelete: {
      default: null,
      type: Function,
    },
    fileDownload: {
      default: null,
      type: Function,
    },
    fileIcon: {
      default: () => ({ name: "files" }),
      type: Function,
    },
    fileUpload: {
      default: null,
      type: Function,
    },
    hideFileDel: {
      default: () => false,
      type: Function,
    },
    hideFileDownload: {
      default: () => false,
      type: Function,
    },
    hideUpload: {
      default: false,
      type: Boolean,
    },
    maxFileQuantity: {
      default: null,
      type: Number,
    },
    maxFileSize: {
      default: 26214400,
      type: Number,
    },
    maxFileSizeText: {
      default: "25 MB",
      type: String,
    },
    value: {
      default: () => [],
      type: Array,
    },
  },
};
</script>
