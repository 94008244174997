<template>
  <AsyncExecute v-slot="{ isBusy, execute, lastError }">
    <OverlayBase :is-busy="isBusy">
      <DescriptionText>Informe seu {{ otpMessage }}</DescriptionText>
      <SpacerBase
        vertical="30"
        block
      />
      <DialogForm
        :external-errors="lastError"
        @save="execute(challengeUserOtp, $event)"
      >
        <template #body="{ payload }">
          <ControlOtp
            v-model="payload.otp"
            autofocus
            field="otp"
          />
          <div class="pb-3" />
        </template>
        <template #actions="{ payload }">
          <ButtonTertiary
            label="Cancelar"
            field="cancel"
            @click="resetState"
          />
          <ButtonSecondary
            :disabled="isOtpBlocked"
            label="Reenviar código"
            icon="reply"
            field="goChannels"
            @click="setOtpMessage"
          />
          <ButtonPrimary
            label="Acessar"
            field="sendOtp"
            :disabled="!payload.otp || payload.otp.length !== 6"
          />
        </template>
      </DialogForm>
      <SpacerBase
        vertical="35"
        block
      />
      <AlertText
        v-if="isOtpBlocked"
        :text="`Aguarde ${otpBlockedRemainingSeconds} segundos antes de reenviar o código.`"
      />
    </OverlayBase>
  </AsyncExecute>
</template>

<script>
import { AsyncExecute, OverlayBase } from "@/lib";
import { ButtonPrimary, ButtonSecondary, ButtonTertiary } from "@/lib";
import { ControlOtp } from "@/lib";
import { DialogForm } from "@/lib";
import { SpacerBase } from "@/lib";
import { AlertText, DescriptionText } from "@/lib";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: {
    AlertText,
    AsyncExecute,
    ButtonPrimary,
    ButtonSecondary,
    ButtonTertiary,
    ControlOtp,
    DescriptionText,
    DialogForm,
    OverlayBase,
    SpacerBase,
  },
  computed: {
    ...mapGetters("auth", ["otpMessage", "otpBlockedUntil"]),
    isOtpBlocked() {
      return this.otpBlockedRemainingSeconds > 0;
    },
  },
  created() {
    this.updateOtpBlockedRemainingSeconds();
    this.intervalId = setInterval(this.updateOtpBlockedRemainingSeconds, 1000);
  },
  data() {
    return {
      intervalId: null,
      otpBlockedRemainingSeconds: 0,
    };
  },
  destroyed() {
    clearInterval(this.intervalId);
  },
  methods: {
    ...mapActions("auth", ["challengeUserOtp"]),
    ...mapMutations("auth", ["resetState", "setOtpMessage"]),
    updateOtpBlockedRemainingSeconds() {
      const until = this.otpBlockedUntil;
      if (!until) {
        this.otpBlockedRemainingSeconds = 0;
        return;
      }

      const delta = Math.round((until.valueOf() - Date.now()) / 1000);
      this.otpBlockedRemainingSeconds = delta > 0 ? delta : 0;
    },
  },
  name: "auth-dialog-user-otp",
};
</script>
