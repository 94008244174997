var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("DescriptionText", [
    _vm._v("\n  Por favor, aguarde enquanto tentamos recuperar sua sessão.\n"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }