<template>
  <ButtonBase
    class="button-secondary px-4 py-2 text-nowrap"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import ButtonBase from "./button-base";

export default {
  components: { ButtonBase },
  name: "button-secondary",
};
</script>

<style scoped>
.button-secondary {
  font-weight: var(--w-font-weight-50);
  color: var(--w-color-primary-30);
  background-color: var(--w-color-neutral-10);
  border: var(--w-border-size-10) solid var(--w-color-neutral-20);
}

.button-secondary:focus,
.button-secondary:active {
  color: var(--w-color-primary-30) !important;
  background-color: var(--w-color-neutral-10) !important;
  border: var(--w-border-size-10) solid var(--w-color-primary-30) !important;
}

.button-secondary:hover {
  color: var(--w-color-primary-30);
  background-color: var(--w-color-neutral-10);
  border: var(--w-border-size-10) solid var(--w-color-neutral-20);
  box-shadow: var(--w-shadow-primary);
}

.button-secondary:disabled {
  color: var(--w-transparency-primary) !important;
  background-color: var(--w-color-neutral-10) !important;
  border: var(--w-border-size-10) solid var(--w-color-neutral-20) !important;
  box-shadow: none !important;
}
</style>
