var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "NavBar",
    _vm._g(
      _vm._b(
        {
          scopedSlots: _vm._u([
            {
              key: "logo",
              fn: function () {
                return [_c("LogoApp", { attrs: { responsive: "" } })]
              },
              proxy: true,
            },
            {
              key: "links",
              fn: function () {
                return [_c("LayoutNavUser")]
              },
              proxy: true,
            },
          ]),
        },
        "NavBar",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }