import * as rawLogs from "./logs";
import { parseLogs } from "./selectors-logs";

const LOCAL_STORAGE_KEY = "changelogRead";

const buildTimeLogs = parseLogs(rawLogs).filter(l => !l.draft);
const initialReadKeys = recoverRead();

export const changelogStore = {
  getters: {
    howManyLogs: ({ allLogs }) => allLogs.length,
    howManyUnread: ({ allLogs, readKeys }) => {
      const unreadLogs = allLogs.filter(l => !readKeys.includes(l.key));
      return unreadLogs.length;
    },
    prettyHowManyUnread(state, { howManyUnread }) {
      if (!howManyUnread || howManyUnread === 0) return null;
      return howManyUnread.toString();
    },
    recentLogs:
      ({ allLogs }) =>
        howMany =>
          allLogs.slice(0, howMany),
  },
  mutations: {
    markAllRead(state) {
      const allKeys = state.allLogs.map(l => l.key);
      persistRead(allKeys);
      state.readKeys = allKeys;
    },
  },
  namespaced: true,
  state: {
    allLogs: buildTimeLogs,
    readKeys: initialReadKeys,
  },
};

function recoverRead() {
  return JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_KEY)) || [];
}

function persistRead(keys) {
  window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(keys));
}
