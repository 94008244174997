export function isMyFluxo(requisitanteId, fluxo) {
  if (!requisitanteId || !fluxo) return false;

  return requisitanteId === fluxo.dadosBasicos.idRequisitante;
}

export function isNotMineButIsFromMyGrupo(requisitante, fluxo) {
  if (!requisitante || !fluxo) return false;
  if (isMyFluxo(requisitante.id, fluxo)) return false;

  return requisitante.grupos.includes(fluxo.dadosBasicos.idGrupo);
}
