import { request } from "@/api";
import { createCollectionStoreModule } from "@/lib";

import { getConfiguration } from "../configuration";

const baseEndpoint = "/contas/perfil/REQUISITANTE";

export const requisitantesStore = createCollectionStoreModule({
  apiService: "autenticador",
  editEndpoint: baseEndpoint,
  getters: {
    currentRequisitante(state, getters, rootState, rootGetters) {
      const codigo = rootGetters["auth/codigo"];
      const currentRequisitante = getters.getItemByFieldValue(
        "codigo",
        codigo,
      );
      return currentRequisitante;
    },
    currentRequisitanteGrupos(state, getters) {
      const currentRequisitanteId = getters.currentRequisitanteId;
      return getters.requisitanteGrupos(currentRequisitanteId);
    },
    currentRequisitanteId(state, getters) {
      return getters.currentRequisitante
        ? getters.currentRequisitante.id
        : null;
    },
    requisitanteGrupos:
      (state, getters, rootState, rootGetters) => (requisitanteId) => {
        if (!requisitanteId) return [];

        const requisitante = getters.getItemById(requisitanteId);
        const gruposIds = requisitante ? requisitante.grupos : [];
        const grupos = rootGetters["grupos/filterItemsByFieldWithSomeValue"](
          "id",
          gruposIds,
        );

        return grupos;
      },
  },
  newEndpoint: baseEndpoint,
  pageLimit: getConfiguration("WEB_PAGINATION_LIMIT"),
  parseItemToRecord: (item) => {
    return {
      ativo: true,
      perfil: "REQUISITANTE",
      ...item,
    };
  },
  pollingSeconds: getConfiguration("WEB_POLLING_SECONDS"),
  queryEndpoint: baseEndpoint,
  request,
});
