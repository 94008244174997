var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("MessageBase", {
    ref: "modal",
    attrs: {
      variant: "danger",
      title: "ERRO",
      message: _vm.message,
      "message-detail": _vm.messageDetail,
      icon: "error",
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ cancel }) {
          return [
            _c("ButtonPrimary", {
              attrs: { label: "OK" },
              on: {
                click: function ($event) {
                  return cancel()
                },
              },
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }