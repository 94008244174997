<template>
  <ButtonIcon
    icon="download"
    tooltip="download"
    :hide="hide || !fileDownload"
    v-bind="$attrs"
    @click="fileDownload"
  />
</template>

<script>
import { ButtonIcon } from "../buttons";

export default {
  components: { ButtonIcon },
  name: "FileButtonDownload",
  props: {
    fileDownload: {
      default: null,
      type: Function,
    },
    hide: {
      default: false,
      type: Boolean,
    },
  },
};
</script>
