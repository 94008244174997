<template>
  <FragmentBase>
    <ButtonSecondary
      field="sign-out"
      label="Sair"
      @click="$emit('signOut')"
    />
    <ButtonPrimary
      field="fluxos"
      label="Listar Fluxos"
      @click="$emit('list')"
    />
  </FragmentBase>
</template>
<script>
import { ButtonPrimary, ButtonSecondary, FragmentBase } from "@/lib";

export default {
  components: { ButtonPrimary, ButtonSecondary, FragmentBase },
  name: "ButtonsListOut",
};
</script>
