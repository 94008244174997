var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.hidden
    ? _c(
        "div",
        { staticClass: "tab-accordion" },
        [
          _c("TabAccordionHeader", {
            attrs: {
              "parent-id": _vm.tabId,
              disabled: _vm.disabled,
              title: _vm.headerTitle,
              subtitle: _vm.headerSubtitle,
              "icon-name": _vm.headerIconName,
              "icon-badge": _vm.headerIconBadge,
            },
          }),
          _vm._v(" "),
          _c(
            "BCollapse",
            {
              staticClass: "pt-3 px-3 pb-2",
              attrs: {
                id: _vm.tabId,
                visible: _vm.show,
                accordion: _vm.accordion,
                role: "tabpanel",
              },
            },
            [_c("div", [_vm._t("default")], 2)]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }