import { throwAppError } from "@/lib/meta";

import {
  BIconArchiveFill,
  BIconBuilding,
  BIconCaretDownFill,
  BIconCaretUpFill,
  BIconCheckCircleFill,
  BIconChevronDoubleLeft,
  BIconChevronDoubleRight,
  BIconChevronDown,
  BIconChevronLeft,
  BIconChevronRight,
  BIconChevronUp,
  BIconCircleFill,
  BIconClipboardCheck,
  BIconDownload,
  BIconEnvelopeFill,
  BIconExclamationTriangleFill,
  BIconEyeFill,
  BIconFileEarmarkTextFill,
  BIconFiles,
  BIconInfoCircleFill,
  BIconList,
  BIconListCheck,
  BIconPencilFill,
  BIconPencilSquare,
  BIconPeopleFill,
  BIconReplyFill,
  BIconSearch,
  BIconTrashFill,
  BIconVectorPen,
  BIconX,
  BIconZoomIn,
  BIconZoomOut,
} from "../bv";
import IconAssinadorDesktopSerpro from "./icon-assinador-desktop-serpro";
import IconBaseSvg from "./icon-base-svg";
import IconClient from "./icon-client";
import IconError from "./icon-error";
import IconExecutive from "./icon-executive";
import IconPdf from "./icon-pdf";
import IconPerson from "./icon-person";
import IconPersonCog from "./icon-person-cog";
import IconReload from "./icon-reload";
import IconSerproId from "./icon-serpro-id";
import IconSuccess from "./icon-success";

export function iconRetriever(name) {
  const iconsMap = {
    "alert": BIconExclamationTriangleFill,
    "archive": BIconArchiveFill,
    "base": IconBaseSvg,
    "building": BIconBuilding,
    "caret-down": BIconCaretDownFill,
    "caret-up": BIconCaretUpFill,
    "check": BIconCheckCircleFill,
    "chevron-down": BIconChevronDown,
    "chevron-right": BIconChevronRight,
    "chevron-up": BIconChevronUp,
    "circle": BIconCircleFill,
    "client": IconClient,
    "close": BIconX,
    "cog": IconPersonCog,
    "data": BIconFileEarmarkTextFill,
    "del": BIconTrashFill,
    "desktop": IconAssinadorDesktopSerpro,
    "download": BIconDownload,
    "edit": BIconPencilFill,
    "edit-square": BIconPencilSquare,
    "error": IconError,
    "executive": IconExecutive,
    "files": BIconFiles,
    "first": BIconChevronDoubleLeft,
    "hamburger": BIconList,
    "info": BIconInfoCircleFill,
    "inspect": BIconClipboardCheck,
    "last": BIconChevronDoubleRight,
    "list-check": BIconListCheck,
    "next": BIconChevronRight,
    "pdf": IconPdf,
    "people": BIconPeopleFill,
    "person": IconPerson,
    "previous": BIconChevronLeft,
    "reload": IconReload,
    "reply": BIconReplyFill,
    "search": BIconSearch,
    "send": BIconEnvelopeFill,
    "serpro-id": IconSerproId,
    "sign": BIconVectorPen,
    "success": IconSuccess,
    "view": BIconEyeFill,
    "zoom-in": BIconZoomIn,
    "zoom-out": BIconZoomOut,
  };

  const icon = iconsMap[name];
  if (!icon) throwAppError({ message: `invalid icon name: "${name}"` });
  return icon;
}
