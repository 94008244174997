<template>
  <div class="min-vh-100 d-flex justify-content-center align-items-center">
    <div class="hero-image" />
    <div class="position-relative">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "layout-hero",
};
</script>

<style scoped>
.hero-image {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: url("~@/public/hero-image.png");
  background-size: cover;
  background-position: center;
}

@media (min-width: 576px) {
  .hero-image {
    display: revert;
  }
}
</style>
