var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-3 mx-auto" },
    [
      _c("HeaderText", { attrs: { text: _vm.headerText } }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "content-wrapper bg-white rounded mt-4 p-3",
          class: { shadow: !_vm.hideShadow },
        },
        [
          _c("LinearBase", {
            staticClass: "linear",
            attrs: { "is-busy": _vm.showLinear && !_vm.showOverlay },
          }),
          _vm._v(" "),
          _c(
            "OverlayBase",
            { attrs: { "is-busy": _vm.showOverlay } },
            [_vm._t("default")],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }