import { getConfiguration } from "@/data";
import { logger } from "@cert/web";
import * as Sentry from "@sentry/vue";

import { captureAlert, captureError } from "./capture";
import { userEnvironment } from "./environment";
import { denyUrls, ignoreErrors } from "./filters";
import { syncUserData } from "./user";

export function initObservability(options) {
  const { Vue, release, router } = options;
  const enabled = getConfiguration("WEB_SENTRY_ENABLED");
  const environment = userEnvironment();
  const dsn = getConfiguration("WEB_SENTRY_DSN");

  if (!enabled || !dsn || !release) {
    logger.info("observability disabled given sentry is disabled or config was not found");
    return;
  }

  Sentry.init({
    Vue,
    denyUrls,
    dsn,
    environment,
    ignoreErrors,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    release,
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
  });

  const { store } = options;
  const updateUser = userData => Sentry.setUser(userData);
  syncUserData({ store, updateUser });

  logger.add({ fn: captureError, to: "error" });
  logger.add({ fn: captureAlert, to: "warn" });
}
