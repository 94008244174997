import { BVToastPlugin, VBToggle, VBTooltip } from "../bv";
import { notifyError, notifySuccess, showAlert, showError } from "../dialogs";

export function installComponentsGlobalDependencies(Vue) {
  Vue.use(BVToastPlugin);
  Vue.directive("toggle", VBToggle);
  Vue.directive("tooltip", VBTooltip);

  Vue.prototype.$notifySuccess = notifySuccess;
  Vue.prototype.$notifyError = notifyError;
  Vue.prototype.$showAlert = showAlert;
  Vue.prototype.$showError = showError;
}
