export function userEnvironment() {
  const hostName = window.location.hostname;

  const isCorporativo = hostName.includes("serpro.gov.br");
  if (
    (hostName.includes("localhost") || hostName.includes("web-app"))
    && !isCorporativo
  ) { return "local"; }

  if (hostName.includes("dev-neosigner") && isCorporativo) return "dev";
  if (hostName.includes("hom") && isCorporativo) return "hom";
  if (hostName.includes("neosigner") && isCorporativo) return "prod";

  if (typeof hostName === "string") return hostName;

  return "unknown";
}
