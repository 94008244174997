var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { class: _vm.badge && "badge-wrapper" },
    [
      _c(
        _vm.iconRetriever(_vm.name),
        _vm._g(
          _vm._b(
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip.hover.bottom",
                  modifiers: { hover: true, bottom: true },
                },
              ],
              tag: "component",
              style: { fill: _vm.fillColor },
              attrs: {
                id: _vm.field && _vm.camelCase("icon", _vm.field),
                title: _vm.tooltip,
              },
            },
            "component",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        )
      ),
      _vm._v(" "),
      _vm.badge
        ? _c("span", { staticClass: "icon-badge" }, [
            _vm._v("\n    " + _vm._s(_vm.badge) + "\n  "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }