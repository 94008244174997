<template>
  <div>
    <BTable
      :id="camelCase('list', field)"
      :items="items"
      :fields="styledFields"
      :sort-by="initialSort.field"
      :sort-direction="initialSort.direction"
      :selectable="!!select"
      :select-mode="select"
      :busy="isLoading"
      :primary-key="primaryKey"
      :per-page="perPage"
      :current-page="currentPage"
      :sort-desc="sortDesc"
      sort-icon-left
      striped
      hover
      stacked="md"
      responsive
      show-empty
      empty-text="Nenhum item encontrado"
      empty-filtered-text="Nenhum item encontrado na pesquisa"
      :tbody-tr-class="{ 'has-default-action': !!defaultAction }"
      class="list-base-table"
      v-bind="$attrs"
      @row-clicked="handleClick"
      v-on="$listeners"
    >
      <template #table-busy>
        <div class="text-center my-4">
          <BSpinner class="align-middle" />
          <strong>Carregando...</strong>
        </div>
      </template>
      <!--
        o código a seguir puxa os slots dos componentes ancestrais e os aplica na
        bootstrap-vue table, é uma forma de evitar ter de declarar os mesmo slots em
        toda hierarquia de componentes que usar ListBase. mais detalhes na resposta
        abaixo:
        https://stackoverflow.com/questions/50891858/vue-how-to-pass-down-slots-inside-wrapper-component?rq=1
      -->
      <template
        v-for="(_, slot) of $scopedSlots"
        #[slot]="scope"
      >
        <slot
          :name="slot"
          v-bind="scope"
        />
      </template>
    </BTable>

    <BPagination
      v-if="items.length > perPage"
      :id="camelCase('pagination', field)"
      v-model="currentPage"
      :total-rows="items.length"
      :per-page="perPage"
      :aria-controls="camelCase('pagination', field)"
      first-number
      last-number
      align="center"
    />
  </div>
</template>

<script>
import { camelCase } from "@/lib/meta";

import { BPagination, BSpinner, BTable } from "../bv";

export default {
  components: { BPagination, BSpinner, BTable },
  computed: {
    styledFields() {
      const fieldHasStyle = field => field.minSize || field.centerTitle;

      return this.fields.map(f => ({
        ...f,
        ...(f.label && { label: f.label.toUpperCase() }),
        ...(fieldHasStyle(f) && {
          thStyle: {
            ...(f.minSize && { width: "1px" }),
            ...(f.centerTitle && { "text-align": "center" }),
          },
        }),
      }));
    },
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
    };
  },
  methods: {
    assureCurrentPageConsistency(newRowsTotal, oldRowsTotal) {
      if (newRowsTotal < oldRowsTotal && this.currentPage > 1) {
        this.currentPage = 1;
      }
    },
    camelCase,
    handleClick(item) {
      if (!this.defaultAction) return;
      this.defaultAction(item);
    },
  },
  name: "list-base",
  props: {
    defaultAction: {
      default: null,
      type: Function,
    },
    field: {
      required: true,
      type: String,
    },
    fields: {
      required: true,
      type: Array,
    },
    initialSort: {
      default: () => ({}),
      type: Object,
    },
    isLoading: {
      default: false,
      type: Boolean,
    },
    items: {
      default: () => [],
      type: Array,
    },
    primaryKey: {
      default: "id",
      type: String,
    },
    select: {
      default: null,
      type: String,
    },
    sortDesc: {
      default: false,
      type: Boolean,
    },
  },
  watch: {
    "items.length": {
      handler: "assureCurrentPageConsistency",
    },
  },
};
</script>

<style scoped>
.list-base-table::v-deep th,
.list-base-table::v-deep .b-table-stacked-md tr [data-label]::before {
  color: var(--w-color-primary-30);
  font-size: var(--w-font-size-20);
  font-weight: var(--w-font-weight-20) !important;
}

.list-base-table::v-deep .b-table-details td {
  overflow-y: auto;
}

.list-base-table::v-deep .has-default-action:hover {
  cursor: pointer;
}

@media (max-width: 768px) {
  .list-base-table::v-deep .b-table-stacked-md td {
    display: flex !important;
    align-items: center;
  }
}
</style>
