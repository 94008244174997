var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.hide
    ? _c(
        "BFormTags",
        _vm._g(
          {
            staticClass: "select-multi-base pt-3 pb-1 pl-3 pr-6",
            attrs: {
              id: _vm.id,
              state: _vm.state,
              value: _vm.selectedOptionsValues,
              disabled: _vm.disabled,
              "remove-on-delete": "",
              "add-on-change": "",
              "no-outer-focus": "",
              "tag-variant": "primary",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function ({
                    tags,
                    inputAttrs,
                    inputHandlers,
                    disabled: selectDisabled,
                    removeTag,
                  }) {
                    return [
                      _c(
                        "BFormSelect",
                        _vm._g(
                          _vm._b(
                            {
                              attrs: {
                                options: _vm.unselectedOptions,
                                "text-field": _vm.textField,
                                "value-field": _vm.valueField,
                                disabled:
                                  selectDisabled ||
                                  _vm.unselectedOptions.length === 0,
                              },
                            },
                            "BFormSelect",
                            inputAttrs,
                            false
                          ),
                          inputHandlers
                        )
                      ),
                      _vm._v(" "),
                      tags.length === 0
                        ? _c("p", { staticClass: "mt-2" }, [
                            _vm._v(
                              "\n      " + _vm._s(_vm.placeholder) + "\n    "
                            ),
                          ])
                        : _c(
                            "ul",
                            { staticClass: "list-inline d-inline-block mt-2" },
                            _vm._l(tags, function (tag) {
                              return _c(
                                "li",
                                { key: tag, staticClass: "list-inline-item" },
                                [
                                  _c(
                                    "BFormTag",
                                    {
                                      attrs: {
                                        title: tag,
                                        variant: "secondary",
                                        pill: "",
                                        disabled:
                                          selectDisabled ||
                                          _vm.isTagDisabled(tag),
                                      },
                                      on: {
                                        remove: function ($event) {
                                          return removeTag(tag)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n          " +
                                          _vm._s(_vm.tagText(tag)) +
                                          "\n        "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                    ]
                  },
                },
              ],
              null,
              false,
              3426912063
            ),
          },
          _vm.$listeners
        )
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }