<template>
  <div class="items-counter">
    {{ count }}
  </div>
</template>
<script>
import { getContentSizeFromRichText } from "./size";

export default {
  computed: {
    count() {
      return getContentSizeFromRichText(this.delta);
    },
  },
  props: {
    delta: {
      default: null,
      type: Object,
    },
  },
};
</script>

<style scoped>
.items-counter {
  text-align: right;
  font-size: var(--w-font-size-20);
  padding-block: var(--w-size-05);
  padding-inline: var(--w-size-20);
  background-color: var(--w-color-neutral-20);
}
</style>
