export function selectFilesFromDialog(inputParent) {
  return new Promise((resolve) => {
    const inputFile = document.createElement("input");
    inputFile.type = "file";

    /*
      o input é incluído no DOM para possibilitar que testes automáticos
      encontrem o elemento por meio de 'input[type=file]'
    */
    inputFile.style.display = "none";
    inputParent.appendChild(inputFile);

    inputFile.onchange = (e) => {
      const files = Array.from(e.target.files);
      resolve(files);
    };

    /*
      apesar do event onchange do input ser disparado quando o usuário seleciona arquivos,
      não há solução explicita para detectar quando o usuário clicou em cancel
      no diálogo de seleção de arquivo. aqui é aplicado um artificio de detectar
      o retorno do foco ao body. mais em:
      https://stackoverflow.com/questions/22898854/find-if-cancel-was-clicked-on-file-input/22900815
    */
    document.body.onfocus = () =>
      setTimeout(() => {
        if (inputFile.files.length === 0) resolve([]);
        inputFile.remove();
        document.body.onfocus = null;
      }, 500);

    inputFile.click();
  });
}
