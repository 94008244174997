var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.path
    ? _c("BBreadcrumb", {
        staticClass: "m-0 p-0 bg-transparent",
        attrs: { items: _vm.items },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }