<template>
  <BOverlay :show="isBusy">
    <slot />
  </BOverlay>
</template>

<script>
import { BOverlay } from "../bv";

export default {
  components: { BOverlay },
  name: "overlay-base",
  props: {
    isBusy: {
      default: true,
      type: Boolean,
    },
  },
};
</script>
