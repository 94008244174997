var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BNavbarNav",
    {
      staticClass: "side-bar-group",
      style: {
        "--bg-side-bar-group": _vm.showLinks
          ? "var(--w-color-primary-70)"
          : "var(--w-color-primary-60)",
      },
    },
    [
      _c(
        "SideBarItem",
        { attrs: { level: 1 }, on: { click: _vm.toggleShowItems } },
        [
          _vm._v("\n    " + _vm._s(_vm.text.toUpperCase()) + "\n    "),
          _c("AppIcon", {
            attrs: {
              name: "caret-up",
              "fill-color": "var(--w-color-neutral-10)",
              rotate: _vm.showLinks ? "0" : "90",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showLinks
        ? _c(
            "div",
            _vm._l(_vm.links, function (link) {
              return _c("SideBarLink", {
                key: link.text,
                attrs: { text: link.text, icon: link.icon, to: link.to },
              })
            }),
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }