var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BButtonGroup",
    { staticClass: "mx-1", attrs: { size: "sm" } },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }