var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.hide
    ? _c(
        "BFormTextarea",
        _vm._g(
          _vm._b(
            { attrs: { rows: "3", disabled: _vm.disabled } },
            "BFormTextarea",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        )
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }