<template>
  <BButtonGroup
    size="sm"
    class="mx-1"
  >
    <slot />
  </BButtonGroup>
</template>

<script>
import { BButtonGroup } from "../bv";

export default {
  components: { BButtonGroup },
  name: "toolbar-section",
};
</script>
