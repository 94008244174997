var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DarkCanvas",
    [
      _c("LayoutNavBar", {
        attrs: { "is-open": _vm.showSideBar },
        on: {
          "update:isOpen": function ($event) {
            _vm.showSideBar = $event
          },
          "update:is-open": function ($event) {
            _vm.showSideBar = $event
          },
        },
      }),
      _vm._v(" "),
      _c("LayoutNavSide", {
        attrs: { "is-open": _vm.showSideBar },
        on: {
          "update:isOpen": function ($event) {
            _vm.showSideBar = $event
          },
          "update:is-open": function ($event) {
            _vm.showSideBar = $event
          },
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "p-2 p-sm-5" },
        [
          _c("NavCrumb", {
            staticClass: "d-none d-sm-flex",
            attrs: { id: "navCrumbs", path: _vm.$route.meta.crumbs },
          }),
          _vm._v(" "),
          _c("div", [_vm._t("default")], 2),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }