import Vue from "vue";
import VueRouter from "vue-router";

import App from "./app";
import { createGuardRoute } from "./guard-route";
import { routes } from "./routes";
import { createDefaultRoute } from "./routes-perfil-default";

export function initApp({ store }) {
  const router = initRouter(store);

  const app = new Vue({
    components: { App },
    render: h => h(App),
    router,
    store,
  });

  return { app, router };
}

function initRouter(store) {
  Vue.use(VueRouter);

  const router = new VueRouter({
    mode: "history",
    routes,
  });
  router.addRoute(createDefaultRoute(store));
  router.beforeEach(createGuardRoute(store));

  return router;
}
