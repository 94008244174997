import { ERROR_SEVERITIES, throwAppError } from "@/lib/meta";

import { client } from "./client";

export async function request(config, payload) {
  const {
    endpoint,
    method = "get",
    service,
    ...options
  } = config;

  const route = mountRoute(service, endpoint);
  let response;
  try {
    response = await client({ method, options, payload, route });
    if (response.status >= 300) throw new Error("force catch clause");
  }
  catch {
    throwBadRequest(response);
  }

  const data = parseResponseData(response, config);
  return data;
}

function mountRoute(service, endpoint) {
  const services = {
    autenticador: "/api/autenticador",
    controlador: "/api/controlador",
    gerenciador: "/api/gerenciador",
  };

  const serviceRoute = services[service] ?? "";
  return serviceRoute + endpoint;
}

function throwBadRequest(response = {}) {
  const { data = {}, status, statusText } = response;
  const code = data.code || status;

  // statusText também poderia ser considerado como alternativa de mensagem mas foi abandonado por ser normalmente um texto técnico em inglês
  const message = data.message || "Aconteceu um erro inesperado na aplicação durante a requisição.";

  const severity = status < 500 ? ERROR_SEVERITIES.LOGICAL : ERROR_SEVERITIES.CRITICAL;
  const meta = {
    ...data,
    isHttp: true,
    status,
    statusText,
  };
  throwAppError({ code, message, meta, severity });
}

function parseResponseData(response) {
  const { data } = response;

  // algumas respostas emitidas através do krakenD gateway tem formato específico. o código abaixo é uma conversão desse formato para o que é convencionado no módulo web
  if (Array.isArray(data?.collection)) return data.collection;

  return data;
}
