export function parseRecordToFluxo(record) {
  const { dadosBasicos, documentos, id, interessados, ...recordRest } = record;
  const {
    createdAt,
    interrompeSeNegativo,
    sequencial,
    tipoAssinatura,
    ...basicosRest
  } = dadosBasicos;

  const interessadosParsed = interessados.map((i) => {
    // mesmo sendo string, o valor em parecer pode não parseable, daí o try/catch
    try {
      const parecer = JSON.parse(i.resposta.parecer);
      return { ...i, resposta: { ...i.resposta, parecer } };
    }
    catch {
      return i;
    }
  });

  const item = {
    id,
    ...recordRest,
    dadosBasicos: {
      dataCriacao: new Date(createdAt),
      isIcp: tipoAssinatura === "QUALIFICADA",
      isInterrompeSeNegativo: interrompeSeNegativo,
      isSequencial: sequencial,
      ...basicosRest,
    },
    documentos: documentos.map(d => ({ idFluxo: id, ...d })),
    interessados: interessadosParsed,
  };

  return item;
}

export function parseFluxoToRecord(item) {
  const { dadosBasicos, ...itemRest } = item;
  const { isIcp, isInterrompeSeNegativo, isSequencial, ...basicosRest }
    = dadosBasicos;

  const record = {
    ...itemRest,
    dadosBasicos: {
      interrompeSeNegativo: isInterrompeSeNegativo,
      sequencial: isSequencial,
      tipoAssinatura: isIcp ? "QUALIFICADA" : "AVANCADA",
      ...basicosRest,
    },
  };
  return record;
}
