<template>
  <div v-if="text">
    {{ text }}
  </div>
</template>

<script>
export default {
  name: "badge-base",
  props: {
    text: {
      default: null,
      type: String,
    },
  },
};
</script>

<style scoped>
div {
  border-radius: 50%;
  background-color: var(--w-color-danger-50);
  color: var(--w-color-neutral-10);
  font-weight: var(--w-font-weight-50);
  text-align: center;
  --inner-size: var(--w-font-size-15);
  --outer-size: calc(var(--inner-size) * 1.75);
  font-size: var(--inner-size);
  width: var(--outer-size);
  line-height: var(--outer-size);
}
</style>
