<template>
  <BModal
    v-model="isShow"
    v-bind="$attrs"
    :centered="centered"
    content-class="rounded"
    hide-footer
    hide-header
    no-close-on-backdrop
    no-close-on-esc
    data-test="modal-base"
  >
    <BodyActions :show-divider="showActionDivider">
      <!--
        Eventos emitidos dentro de um slot não são capturados pelo componente que envolve
        o slot. Daí as props "save" e "cancel" do tipo Function são passadas abaixo.

        Isso permite que outro componente que estenda o slot possa comandar o fechamento
        deste ModalBase.

        Para isso o componente consumidor deve chamar uma das funções passando opcionalmente
        algum payload para "save".

        Save retornará true caso não haja payload e cancel sempre retorna "false". Quando
        isso acontecer o ModalBase irá se fechar e retornar o valor.
      -->
      <template #body>
        <slot
          name="body"
          :save="handleSave"
          :cancel="handleCancel"
        />
      </template>

      <template #actions>
        <slot
          name="actions"
          :save="handleSave"
          :cancel="handleCancel"
        />
      </template>
    </BodyActions>
  </BModal>
</template>

<script>
import { BModal } from "../bv";
import BodyActions from "./body-actions";

export default {
  components: { BModal, BodyActions },
  data() {
    return {
      isShow: false,
      resolve: undefined,
    };
  },
  methods: {
    handleCancel() {
      this.isShow = false;
      this.resolve(false);
    },
    handleSave(payload) {
      this.isShow = false;
      this.resolve(payload || true);
    },
    show() {
      this.isShow = true;
      return new Promise(resolve => (this.resolve = resolve));
    },
  },
  name: "ModalBase",
  props: {
    centered: {
      default: true,
      type: Boolean,
    },
    showActionDivider: {
      default: false,
      type: Boolean,
    },
  },
};
</script>
