import { getConfiguration } from "@/data/configuration";
import axios from "axios";

const axiosClient = axios.create({
  baseURL: decideApiBaseUrl(window.location.hostname),
  validateStatus: () => true,
});

export function client({ method, options, payload, route }) {
  const specialMethods = {
    "get.blob": getBlob,
    upload,
  };
  const dispatcher = specialMethods[method] ?? axiosClient[method];
  return dispatcher(route, payload, options);
}

export function injectTokenOnClient(extractToken) {
  axiosClient.interceptors.request.use((config) => {
    const { token } = extractToken();
    if (token) {
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  });
}

async function upload(route, payload) {
  const requestHeaders = {
    "Content-Type": "multipart/form-data",
  };

  const formData = new FormData();

  Object.entries(payload).forEach(([key, value]) =>
    formData.append(key, value),
  );

  const result = await axiosClient.post(route, formData, {
    headers: requestHeaders,
  });

  return result;
}

async function getBlob(route) {
  const response = await fetch(route);
  // unifica com api das demais formas de requisição desse módulo
  response.data = await response.blob();

  return response;
}

function decideApiBaseUrl(webHostname) {
  const configuredApi = getConfiguration("WEB_API_BASE_URL");
  if (configuredApi) return configuredApi;

  // o comando explicito para localhost remove a porta do servidor web de chamadas a api que devem ser encaminhar ao gateway
  if (webHostname.includes("localhost")) return "http://localhost";
  // esse hostname é o percebido pelos tests e2e rodando dentro do container
  if (webHostname.includes("web-app")) return "http://api-gateway:8080";
  return "";
}
