<template>
  <AsyncExecute v-slot="{ isBusy, execute, lastError }">
    <OverlayBase :is-busy="isBusy">
      <DescriptionText>Aproveite para registrar um dispositivo agora e na próxima vez que for entrar no NeoSigner poderá utilizar um método mais simples de autenticação.</DescriptionText>
      <DialogForm
        :external-errors="lastError"
        @save="execute(registerAuthenticator)"
      >
        <template #actions>
          <ButtonSecondary
            label="Talvez mais tarde"
            field="later"
            @click="handleLater"
          />
          <ButtonPrimary
            label="Registrar meu dispositivo"
            field="register"
          />
        </template>
      </DialogForm>
    </OverlayBase>
  </AsyncExecute>
</template>

<script>
import { DialogForm } from "@/lib";
import { AsyncExecute, OverlayBase } from "@/lib";
import { ButtonPrimary, ButtonSecondary } from "@/lib";
import { DescriptionText } from "@/lib";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: {
    AsyncExecute,
    ButtonPrimary,
    ButtonSecondary,
    DescriptionText,
    DialogForm,
    OverlayBase,
  },
  computed: {
    ...mapGetters("auth", ["isWebAuthnSupported"]),
  },
  methods: {
    ...mapMutations("auth", ["setWebAuthnRegistrationDone"]),
    ...mapActions("auth", ["registerAuthenticator"]),
    handleLater() {
      this.setWebAuthnRegistrationDone();
    },
  },
  mounted() {
    if (!this.isWebAuthnSupported) {
      this.handleLater();
    }
  },
  name: "auth-dialog-user-scope",
};
</script>
