var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "FragmentBase",
    [
      _c("ButtonSecondary", {
        attrs: { field: _vm.backField, label: _vm.backLabel },
        on: {
          click: function ($event) {
            return _vm.$emit("back")
          },
        },
      }),
      _vm._v(" "),
      _c("ButtonPrimary", {
        attrs: { field: _vm.saveField, label: _vm.saveLabel },
        on: {
          click: function ($event) {
            return _vm.$emit("save")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }