var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("canvas", {
    ref: "canvas",
    attrs: { id: _vm.canvasId },
    on: { click: _vm.handleClick },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }