import { VueMaskDirective } from "v-mask";
import Vue from "vue";

export function installInputMasksDirective() {
  Vue.directive("mask", VueMaskDirective);
}

const supportedMaskLiterals = ["#", "A", "N", "X", "?"];

export function replaceMaskTokens(mask, placeholderToken) {
  return supportedMaskLiterals.reduce((acc, token) => {
    return acc.replaceAll(token, placeholderToken);
  }, mask);
}

export function extractRawValue(maskedValue, mask) {
  if (!mask || !maskedValue) return maskedValue;

  const literals = extractMaskLiterals(mask);
  const raw = literals.reduce((acc, literal) => {
    return acc.replaceAll(literal, "");
  }, maskedValue.trim());
  return raw;
}

export function extractMaskLiterals(mask) {
  const mixedLiterals = Array.from(new Set([...mask]));
  const maskLiterals = mixedLiterals.filter(t => !supportedMaskLiterals.includes(t));
  return maskLiterals;
}
