import { formatDate } from "@/lib";

import { isIniciado } from "./selectors-basicos";

export function extractInteressados(fluxo) {
  return fluxo?.interessados || [];
}

export function extractInteressado(fluxo, codigo) {
  const interessados = extractInteressados(fluxo);
  return interessados.find(i => i.codigo === codigo);
}

export function hasInteressado(fluxo) {
  return extractInteressados(fluxo).length > 0;
}

export function extractAssinantes(fluxo) {
  return extractInteressados(fluxo).filter(isAssinante);
}

export function hasAssinante(fluxo) {
  return extractAssinantes(fluxo).length > 0;
}

export function isRevisor(interessado) {
  return isPapel(interessado, "REVISOR");
}

export function isAssinante(interessado) {
  return isPapel(interessado, "ASSINANTE");
}

export function isPapel(interessado, papel) {
  if (!interessado) return false;
  return interessado.papel === papel;
}

export function extractInteressadosWhomAprovaram(fluxo) {
  const interessados = extractInteressados(fluxo);
  return interessados.filter(isInteressadoWithStatusAprovado);
}

export function howManyInteressadosAprovaram(fluxo) {
  return extractInteressadosWhomAprovaram(fluxo).length;
}

export function isInteressadoWithStatusPendente(interessado) {
  return isInteressadoWithStatus(interessado, "PENDENTE");
}
export function isInteressadoWithStatusBloqueado(interessado) {
  return isInteressadoWithStatus(interessado, "BLOQUEADO");
}
export function isInteressadoWithStatusAprovado(interessado) {
  return isInteressadoWithStatus(interessado, "APROVADO");
}
export function isInteressadoWithStatusRejeitado(interessado) {
  return isInteressadoWithStatus(interessado, "REJEITADO");
}
export function isInteressadoWithFinalResposta(interessado) {
  return (
    isInteressadoWithStatusAprovado(interessado)
    || isInteressadoWithStatusRejeitado(interessado)
  );
}

function isInteressadoWithStatus(interessado, status) {
  const hasStatus = i => i?.resposta?.status || i?.status;
  if (!hasStatus(interessado)) return false;

  return (
    interessado?.resposta?.status === status || interessado?.status === status
  );
}

export function extractRespostas(fluxo) {
  const extractInteressadosIds = r => r.interessadosIds || [];

  return extractInteressados(fluxo).map(i => ({
    codigo: i.codigo,
    data: formatDate(i.resposta.dataResposta, {
      day: "2-digit",
      hour: "2-digit",
      locale: "pt-BR",
      minute: "2-digit",
      month: "2-digit",
      year: "numeric",
    }),
    interessadosIds: extractInteressadosIds(i.resposta),
    nome: i.nome,
    papel: i.papel,
    parecer: i.resposta.parecer,
    status: i.resposta.status,
  }));
}

export function extractResposta(fluxo, codigoInteressado) {
  const respostas = extractRespostas(fluxo);
  return respostas.find(r => r.codigo === codigoInteressado);
}

export function hasResposta(fluxo) {
  return extractRespostas(fluxo).length > 0;
}

export function isInteressadoAbleToResponder(fluxo, codigoInteressado) {
  if (!isIniciado(fluxo)) return false;

  const interessado = extractInteressado(fluxo, codigoInteressado);
  return isInteressadoWithStatusPendente(interessado);
}

export function hasParecer(resposta) {
  return !!resposta.parecer;
}

export function isSharingWithInteressados(resposta) {
  const interessados = resposta.interessadosIds;
  if (!Array.isArray(interessados)) return false;
  return interessados.length > 0;
}
