var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "label",
    _vm._b(
      { staticClass: "text-primary text-uppercase m-0" },
      "label",
      _vm.$attrs,
      false
    ),
    [_vm._v("\n  " + _vm._s(_vm.text) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }