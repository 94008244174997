var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ValidationObserver", {
    ref: "formValidationObserver",
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ handleSubmit }) {
          return [
            _c(
              "form",
              {
                attrs: { novalidate: "" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    handleSubmit(() => _vm.onSubmit($event))
                  },
                },
              },
              [
                _c("BodyActions", {
                  attrs: { "show-divider": _vm.showActionDivider },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "body",
                        fn: function () {
                          return [
                            _vm.formError
                              ? _c("alert-text", {
                                  attrs: {
                                    variant: "danger",
                                    icon: "error",
                                    text: _vm.formError,
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._t("body", null, { payload: _vm.payload }),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "actions",
                        fn: function () {
                          return [
                            _vm._t("actions", null, { payload: _vm.payload }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }