var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "fileManager" },
    [
      _c("PanelButtons", {
        scopedSlots: _vm._u(
          [
            {
              key: "start",
              fn: function () {
                return [_vm._t("message")]
              },
              proxy: true,
            },
            {
              key: "default",
              fn: function () {
                return [
                  _c("ButtonSecondary", {
                    attrs: {
                      field: _vm.camelCase("new", _vm.field),
                      hide: _vm.hideUpload || !_vm.fileUpload,
                      label: "+ Adicionar Documento",
                      size: "sm",
                    },
                    on: { click: _vm.handleUploadAttempt },
                  }),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
      _vm._v(" "),
      _c(
        "ListPower",
        _vm._b(
          {
            attrs: {
              field: _vm.field,
              items: _vm.value,
              fields: [
                { key: "fileIcon", label: "", minSize: true, sortable: false },
                {
                  key: "nome",
                  label: "Arquivo",
                  sortable: true,
                  tdClass: "text-break",
                },
              ],
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "cell(fileIcon)",
                  fn: function ({ item: file, index }) {
                    return [
                      file.id === null
                        ? _c("BSpinner", {
                            staticClass: "align-middle",
                            attrs: { small: "" },
                          })
                        : _c(
                            "AppIcon",
                            _vm._b(
                              {
                                attrs: {
                                  field: _vm.camelCase(_vm.field, index),
                                },
                              },
                              "AppIcon",
                              _vm.fileIcon(file),
                              false
                            )
                          ),
                    ]
                  },
                },
                {
                  key: "actions",
                  fn: function ({ item: file, index }) {
                    return [
                      _vm._t("actions", null, {
                        index: index,
                        file: file,
                        isUploading: _vm.isUploading(file),
                      }),
                      _vm._v(" "),
                      _c("FileButtonDownload", {
                        attrs: {
                          field: _vm.camelCase("download", _vm.field, index),
                          "file-name": file.nome,
                          "file-path": file.path,
                          hide: _vm.hideFileDownload(file),
                          disabled: _vm.isUploading(file),
                          "file-download": () => _vm.fileDownload(file),
                        },
                      }),
                      _vm._v(" "),
                      _c("ButtonIcon", {
                        attrs: {
                          field: _vm.camelCase("del", _vm.field, index),
                          hide: _vm.hideFileDel(file) || !_vm.fileDelete,
                          disabled: _vm.isUploading(file),
                          icon: "del",
                          tooltip: "remover",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelAttempt(file)
                          },
                        },
                      }),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          },
          "ListPower",
          _vm.$attrs,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }