// adaptado de https://github.com/niklasvh/base64-arraybuffer
export function encodeBase64(arrayBuffer) {
  const chars
    = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";

  let bytes = new Uint8Array(arrayBuffer),
    i,
    len = bytes.length,
    base64 = "";

  for (i = 0; i < len; i += 3) {
    base64 += chars[bytes[i] >> 2];
    base64 += chars[((bytes[i] & 3) << 4) | (bytes[i + 1] >> 4)];
    base64 += chars[((bytes[i + 1] & 15) << 2) | (bytes[i + 2] >> 6)];
    base64 += chars[bytes[i + 2] & 63];
  }

  if (len % 3 === 2) {
    base64 = base64.substring(0, base64.length - 1) + "=";
  }
  else if (len % 3 === 1) {
    base64 = base64.substring(0, base64.length - 2) + "==";
  }

  // remove caracteres usados na implementação de referência mas que causaram dificuldades na integração com sistemas no backend. não funciona remover caracteres da lista usada pelo algoritmo pois interfere no digest gerado e faz verificações com o NeoId falharem.
  return base64.replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
}
