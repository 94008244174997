var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BModal",
    _vm._b(
      {
        attrs: {
          centered: _vm.centered,
          "content-class": "rounded",
          "hide-footer": "",
          "hide-header": "",
          "no-close-on-backdrop": "",
          "no-close-on-esc": "",
          "data-test": "modal-base",
        },
        model: {
          value: _vm.isShow,
          callback: function ($$v) {
            _vm.isShow = $$v
          },
          expression: "isShow",
        },
      },
      "BModal",
      _vm.$attrs,
      false
    ),
    [
      _c("BodyActions", {
        attrs: { "show-divider": _vm.showActionDivider },
        scopedSlots: _vm._u(
          [
            {
              key: "body",
              fn: function () {
                return [
                  _vm._t("body", null, {
                    save: _vm.handleSave,
                    cancel: _vm.handleCancel,
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "actions",
              fn: function () {
                return [
                  _vm._t("actions", null, {
                    save: _vm.handleSave,
                    cancel: _vm.handleCancel,
                  }),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }