<template>
  <BNavbarNav
    class="side-bar-group"
    :style="{
      '--bg-side-bar-group': showLinks
        ? 'var(--w-color-primary-70)'
        : 'var(--w-color-primary-60)',
    }"
  >
    <SideBarItem
      :level="1"
      @click="toggleShowItems"
    >
      {{ text.toUpperCase() }}
      <AppIcon
        name="caret-up"
        fill-color="var(--w-color-neutral-10)"
        :rotate="showLinks ? '0' : '90'"
      />
    </SideBarItem>
    <div v-if="showLinks">
      <SideBarLink
        v-for="link in links"
        :key="link.text"
        :text="link.text"
        :icon="link.icon"
        :to="link.to"
      />
    </div>
  </BNavbarNav>
</template>

<script>
import { BNavbarNav } from "../bv";
import { AppIcon } from "../icons";
import SideBarItem from "./side-bar-item.vue";
import SideBarLink from "./side-bar-link";

export default {
  components: {
    AppIcon,
    BNavbarNav,
    SideBarItem,
    SideBarLink,
  },
  data() {
    return {
      showLinks: true,
    };
  },
  methods: {
    toggleShowItems(e) {
      e.preventDefault();
      this.showLinks = !this.showLinks;
    },
  },
  name: "side-bar-group",
  props: {
    links: {
      required: true,
      type: Array,
    },
    text: {
      required: true,
      type: String,
    },
  },
};
</script>

<style scoped>
.side-bar-group {
  background-color: var(--bg-side-bar-group);
}
</style>
