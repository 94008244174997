<template>
  <ButtonBase
    :type="type"
    variant="primary"
    class="button-primary px-4 py-2 text-nowrap"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import ButtonBase from "./button-base";

export default {
  components: { ButtonBase },
  name: "button-primary",
  props: {
    type: {
      default: "submit",
      type: String,
    },
  },
};
</script>

<style scoped>
.button-primary {
  font-weight: var(--w-font-weight-50);
}

.button-primary:focus,
.button-primary:hover {
  box-shadow: var(--w-shadow-primary);
}

.button-primary:disabled {
  background-color: var(--w-transparency-primary);
  border: none !important;
  box-shadow: none !important;
}
</style>
