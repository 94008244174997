var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("QueryState", {
    attrs: {
      "store-modules": _vm.storeModules,
      "after-first-query": _vm.afterFirstQuery,
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ isBusyWithQuerying, isBusyWithFirstQuerying }) {
          return [
            _c("AsyncExecute", {
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function ({
                      isBusy: isBusyWithExecute,
                      execute,
                      lastError,
                    }) {
                      return [
                        _c(
                          "CardBase",
                          {
                            attrs: {
                              "header-text": _vm.headerText,
                              "show-overlay":
                                isBusyWithExecute || isBusyWithFirstQuerying,
                              "show-linear": isBusyWithQuerying,
                            },
                          },
                          [
                            _vm._t("default", null, {
                              execute: execute,
                              lastError: lastError,
                            }),
                          ],
                          2
                        ),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }