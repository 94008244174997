export function gruposWithInactiveFlag(grupos = []) {
  const activeGrupos = grupos.filter(g => !!g.ativo);
  const inactiveGrupos = grupos
    .filter(g => !g.ativo)
    .map(g => ({ ...g, nome: `${g.nome} (inativo)` }));

  return [...activeGrupos, ...inactiveGrupos];
}

export function gruposOf(grupos, gestorId) {
  return grupos.filter(({ gestores }) => gestores.includes(gestorId));
}

export function isGruposOf(grupo, gestorId) {
  return grupo.gestores.includes(gestorId);
}
