<template>
  <div>
    <OverlayBase :is-busy="documentIsBusy">
      <PdfDocumentPage
        :field="camelCase('page', field)"
        :pdf-document="pdfDocument"
        :scale="zoom / 100"
        :page-number="currentPageNumber"
        :overlays="overlays"
        :is-busy.sync="pageIsBusy"
        @pageClick="handlePageClick"
      />
    </OverlayBase>
    <PdfDocumentToolbar
      class="mt-2"
      :field="camelCase('toolbar', field)"
      :disabled="documentIsBusy || pageIsBusy || !filePath"
      :pages-total="totalPages"
      :pages-current.sync="currentPageNumber"
      :zoom.sync="zoom"
      :pdf-download="() => pdfDownload({ name: fileName, path: filePath })"
    />
  </div>
</template>
<script>
import { camelCase, logger } from "@cert/web";

import { OverlayBase, useExecute } from "../async";
import PdfDocumentPage from "./pdf-document-page";
import PdfDocumentToolbar from "./pdf-document-toolbar";
import { loadPdf } from "./pdf-load";

export default {
  components: {
    OverlayBase,
    PdfDocumentPage,
    PdfDocumentToolbar,
  },
  data() {
    return {
      currentPageNumber: null,
      pageIsBusy: false,
      pdfDocument: null,
      totalPages: null,
      zoom: 100,
    };
  },
  methods: {
    camelCase,
    handlePageClick(payload) {
      this.$emit("documentClick", {
        page: this.currentPageNumber,
        ...payload,
      });
    },
    async loadPdf() {
      if (!this.filePath) return logger.debug("sem o caminho do arquivo pdf");
      const pdfDocument = await loadPdf(this.filePath);
      this.pdfDocument = pdfDocument;
      this.totalPages = pdfDocument.numPages;
      this.currentPageNumber = 1;
    },
  },
  name: "PdfDocument",
  props: {
    field: {
      default: "pdfDocument",
      type: String,
    },
    fileName: {
      default: null,
      type: String,
    },
    filePath: {
      default: null,
      type: String,
    },
    overlays: {
      default: null,
      type: Array,
    },
    pdfDownload: {
      required: true,
      type: Function,
    },
  },
  setup() {
    const { execute: documentExecute, isBusy: documentIsBusy } = useExecute();

    return {
      documentExecute,
      documentIsBusy,
    };
  },
  watch: {
    filePath: {
      handler() {
        this.documentExecute(this.loadPdf);
      },
      immediate: true,
    },
  },
};
</script>
