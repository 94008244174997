<template>
  <BSidebar
    v-model="innerIsOpen"
    :title="title.toUpperCase()"
    v-bind="$attrs"
    bg-variant="none"
    text-variant="light"
    sidebar-class="side-bar-content-wrapper"
    :shadow="true"
    :backdrop="true"
  >
    <SideBarGroup
      v-for="group in groups"
      :key="group.text"
      :text="group.text"
      :links="group.links"
    />
  </BSidebar>
</template>

<script>
import { BSidebar } from "../bv";
import SideBarGroup from "./side-bar-group";

export default {
  components: { BSidebar, SideBarGroup },
  computed: {
    groups() {
      return this.links.reduce((acc, link) => {
        const name = link.group;
        const group = acc.find(group => group.text === name);
        if (group) {
          group.links.push(link);
        }
        else {
          acc.push({ links: [link], text: name });
        }
        return acc;
      }, []);
    },
  },
  data() {
    return {
      innerIsOpen: this.isOpen,
    };
  },
  name: "side-bar",
  props: {
    isOpen: {
      default: false,
      type: Boolean,
    },
    links: {
      default: () => [],
      type: Array,
    },
    title: {
      default: "",
      type: String,
    },
  },
  watch: {
    innerIsOpen(newValue) {
      this.$emit("update:isOpen", newValue);
    },
    isOpen(newValue) {
      if (newValue !== this.innerIsOpen) {
        this.innerIsOpen = newValue;
      }
    },
  },
};
</script>

<style>
.side-bar-content-wrapper {
  background-color: var(--w-color-primary-60);
}
</style>
