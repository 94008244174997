<template>
  <div
    :id="accordionId"
    class="tabs-accordion"
    role="tablist"
  >
    <slot :accordion="accordionId" />
  </div>
</template>

<script>
import { camelCase } from "@cert/web";
export default {
  computed: {
    accordionId() {
      return camelCase("tabs", this.field);
    },
  },
  name: "tabs-accordion",
  props: {
    field: {
      required: true,
      type: String,
    },
  },
};
</script>

<style scoped>
.tabs-accordion > :not(:first-child) {
  margin-top: var(--w-size-30);
}
</style>
