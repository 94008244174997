var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ValidationWrapper", {
    attrs: { field: _vm.field, label: _vm.label, rules: _vm.rules },
    on: {
      validation: function ($event) {
        return _vm.$emit("validation", $event)
      },
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ state, error }) {
          return [
            _c(
              "ControlGroup",
              {
                attrs: {
                  "control-id": _vm.controlId,
                  "helper-text": _vm.helperText,
                  label: _vm.label,
                  error: error,
                  state: state,
                },
              },
              [
                _c(
                  _vm.controlComponent,
                  _vm._g(
                    _vm._b(
                      {
                        tag: "component",
                        class: _vm.controlClasses,
                        attrs: { id: _vm.controlId, state: state },
                        model: {
                          value: _vm.innerValue,
                          callback: function ($$v) {
                            _vm.innerValue = $$v
                          },
                          expression: "innerValue",
                        },
                      },
                      "component",
                      _vm.$attrs,
                      false
                    ),
                    _vm.$listeners
                  )
                ),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }