import { logger } from "@cert/web";
import "core-js/stable"; // core e regenerator possibilitam o uso das notações async e await em funções assíncronas em conjunto com babel
import "regenerator-runtime/runtime";
import Vue from "vue"; // tb importa e registra os web components da biblioteca cert-web

import { handshake, injectTokenOnClient } from "@/api";
import { installComponentsGlobalDependencies, installValidationSuite } from "@/lib";

import manifest from "../package.json";
import "./app/design"; // carrega os estilos personalizados e também das bibliotecas bootstrap e bootstrap-vue
import { initObservability } from "./app/observability";
import { initApp } from "./app/root";
import { initStore } from "./app/store";

async function main() {
  logger.info(`web release ${manifest.version}`);

  Vue.config.productionTip = false;

  const store = await initStore();
  injectTokenOnClient(() => {
    return {
      token: store.getters["auth/token"],
    };
  });

  // injetando propriedades e funções comuns em todos os componentes
  installComponentsGlobalDependencies(Vue);

  const { app, router } = initApp({ store });

  // instala componentes, regras e diretivas ligadas à validação de entradas do usuário
  installValidationSuite();

  // inicia o mecanismo de monitoramento do frontend
  initObservability({ Vue, release: manifest.version, router, store });

  // expondo globals em window para uso pela suite de testes e funções setup que dependem da store
  window.$app = app;
  window.$store = store;

  app.$mount("#app");

  // contato para ver se o servidor está acessível
  handshake();
}

main();
