var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "OverlayBase",
    { attrs: { "is-busy": _vm.isBusy } },
    [
      _c("DescriptionText", [
        _vm._v("\n    Olá, por favor nos informe o seu "),
        _c("strong", [_vm._v("email")]),
        _vm._v(".\n  "),
      ]),
      _vm._v(" "),
      _c("DialogForm", {
        attrs: { "external-errors": _vm.lastError },
        on: {
          save: function ($event) {
            return _vm.execute(_vm.listContas, $event)
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "body",
              fn: function ({ payload }) {
                return [
                  _c("ControlEmail", {
                    attrs: {
                      field: "email",
                      rules: "required",
                      label: "Email",
                      autocomplete: "webauthn",
                      autofocus: "",
                    },
                    model: {
                      value: payload.email,
                      callback: function ($$v) {
                        _vm.$set(payload, "email", $$v)
                      },
                      expression: "payload.email",
                    },
                  }),
                ]
              },
            },
            {
              key: "actions",
              fn: function () {
                return [
                  _vm._t("actions"),
                  _vm._v(" "),
                  _c("ButtonPrimary", {
                    attrs: { label: "Verificar", field: "send" },
                  }),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }