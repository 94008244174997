<template>
  <ButtonPrimary
    field="signOut"
    label="Sair"
    @click="$emit('signOut')"
  />
</template>
<script>
import { ButtonPrimary } from "@/lib";

export default {
  components: { ButtonPrimary },
  name: "ButtonsListOut",
};
</script>
