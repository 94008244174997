import { request } from "@/api";
import { createCollectionStoreModule } from "@/lib";

import { getConfiguration } from "../configuration";
const baseEndpoint = "/contas/perfil/GESTOR";

export const gestoresStore = createCollectionStoreModule({
  apiService: "autenticador",
  editEndpoint: baseEndpoint,
  newEndpoint: baseEndpoint,
  pageLimit: getConfiguration("WEB_PAGINATION_LIMIT"),
  parseItemToRecord: (item) => {
    return {
      ativo: true,
      perfil: "GESTOR",
      tipoConta: "PESSOA",
      ...item,
    };
  },
  pollingSeconds: getConfiguration("WEB_POLLING_SECONDS"),
  queryEndpoint: baseEndpoint,
  request,
});
