import { createBaseStoreModule } from "./create-base";
import { createCommandsFeatures } from "./features-commands";
import { createItemsFeatures } from "./features-items";
import { createPollingFeatures } from "./features-polling";
import { createQueryFeatures } from "./features-query";
import { createRequestFeatures } from "./features-request";

/*
  concentra a criação de store modules de items de negócios oferecendo
  defaults sem remover a flexibilidade de estender os módulos.

  o objetivo é centralizar código comum reduzindo a superfície de erros
  e manutenção nas data stores.
*/

export function createCollectionStoreModule(config) {
  const {
    actions: actionsConfig,
    getters: gettersConfig,
    mutations: mutationsConfig,
    state: stateConfig,
  } = config;

  const {
    actions: actionsRequest,
    getters: gettersRequest,
    mutations: mutationsRequest,
    state: stateRequest,
  } = createRequestFeatures(config);

  const {
    getters: gettersItems,
    mutations: mutationsItems,
    state: stateItems,
  } = createItemsFeatures(config);

  const {
    actions: actionsQuery,
    getters: gettersQuery,
    mutations: mutationsQuery,
    state: stateQuery,
  } = createQueryFeatures(config);

  const {
    actions: actionsPolling,
    getters: gettersPolling,
    mutations: mutationsPolling,
    state: statePolling,
  } = createPollingFeatures(config);

  const { actions: actionsCommand } = createCommandsFeatures(config);

  return createBaseStoreModule({
    actions: {
      ...actionsRequest,
      ...actionsQuery,
      ...actionsPolling,
      ...actionsCommand,
      ...actionsConfig,
    },
    getters: {
      ...gettersRequest,
      ...gettersItems,
      ...gettersPolling,
      ...gettersConfig,
      ...gettersQuery,
    },
    mutations: {
      ...mutationsRequest,
      ...mutationsItems,
      ...mutationsPolling,
      ...mutationsConfig,
      ...mutationsQuery,
    },
    state: {
      ...stateRequest,
      ...stateItems,
      ...statePolling,
      ...stateQuery,
      ...stateConfig,
    },
  });
}
