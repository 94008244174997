<template>
  <span>
    <slot
      :is-busy-with-querying="isBusyWithQuerying"
      :is-busy-with-first-querying="isBusyWithFirstQuerying"
    />
  </span>
</template>

<script>
import { useQueryState } from "./use-query-state";

export default {
  name: "query-state",
  props: {
    afterFirstQuery: {
      default: null,
      type: Object,
    },
    storeModules: {
      default: () => [],
      type: Array,
    },
  },
  setup(props) {
    const { isBusyWithFirstQuerying, isBusyWithQuerying } = useQueryState({
      afterFirstQuery: props.afterFirstQuery,
      modules: props.storeModules,
      store: window.$store,
    });
    return {
      isBusyWithFirstQuerying,
      isBusyWithQuerying,
    };
  },
};
</script>
