export function createDefaultRoute(store) {
  return {
    path: "/default",
    redirect: () => solveDefaultPath(store.getters["auth/perfil"]),
  };
}

function solveDefaultPath(perfil) {
  const perfisDefaults = {
    ADMINISTRADOR: "/gestores",
    GESTOR: "/requisitantes",
    INTERESSADO: "/fluxos-externo",
    NONE: "/auth",
    REQUISITANTE: "/fluxos-administrativo",
  };
  return perfisDefaults[perfil];
}
