<template>
  <div
    v-if="isBusy"
    class="linear-base"
    :style="cssVars"
  />
</template>

<script>
export default {
  computed: {
    cssVars() {
      return {
        "--duration": this.isBusy ? "4s" : "0s",
      };
    },
  },
  name: "linear-base",
  props: {
    isBusy: {
      default: false,
      type: Boolean,
    },
  },
};
</script>

<style scoped>
@keyframes loading {
  from {
    left: 0;
    right: 100%;
  }
  50% {
    left: 0;
    right: 0;
  }
  to {
    right: 0;
    left: 100%;
  }
}

.linear-base {
  height: 1px;
  background: var(--w-color-neutral-50);
  animation-name: loading;
  animation-duration: var(--duration);
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  animation-direction: alternate;
}
</style>
