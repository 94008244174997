import { request } from "@/api";
import { createCollectionStoreModule } from "@/lib";

import { getConfiguration } from "../configuration";
const baseEndpoint = "/grupos";

export const gruposStore = createCollectionStoreModule({
  apiService: "autenticador",
  editEndpoint: baseEndpoint,
  getters: {
    membrosOfGrupo: (state, getters, rootState, rootGetters) => (grupoId) => {
      const grupo = getters.getItemById(grupoId);
      if (!grupo) return [];

      const requisitantesIds = grupo.requisitantes;
      const requisitantes = rootGetters["requisitantes/filterItemsByFieldWithSomeValue"]("id", requisitantesIds);

      const gestoresIds = grupo.gestores;
      const gestores = rootGetters["gestores/filterItemsByFieldWithSomeValue"]("id", gestoresIds);

      const membros = requisitantes.concat(gestores);
      return membros;
    },
  },
  newEndpoint: baseEndpoint,
  pageLimit: getConfiguration("WEB_PAGINATION_LIMIT"),
  pollingSeconds: getConfiguration("WEB_POLLING_SECONDS"),
  queryEndpoint: baseEndpoint,
  request,
});
