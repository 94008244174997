var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.hide
    ? _c(
        "BFormRadioGroup",
        _vm._g(
          _vm._b(
            {
              staticClass: "input-radio-group",
              attrs: { disabled: _vm.disabled, stacked: "" },
              model: {
                value: _vm.innerValue,
                callback: function ($$v) {
                  _vm.innerValue = $$v
                },
                expression: "innerValue",
              },
            },
            "BFormRadioGroup",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        )
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }