<template>
  <AsyncExecute v-slot="{ isBusy, execute, lastError }">
    <OverlayBase :is-busy="isBusy">
      <DescriptionText>
        Escolha qual perfil de usuário você usará nesta sessão
      </DescriptionText>
      <DialogForm
        :default-payload="defaultPayload"
        :external-errors="lastError"
        @save="execute(signIn, $event)"
      >
        <template #body="{ payload }">
          <ControlSelectSingle
            v-model="payload.perfil"
            :options="perfis"
            rules="required"
            field="role"
            label="Perfil"
          />
        </template>
        <template #actions>
          <ButtonSecondary
            label="Cancelar"
            field="cancel"
            @click="resetState"
          />
          <ButtonPrimary
            label="Escolher e entrar"
            field="choose"
          />
        </template>
      </DialogForm>
    </OverlayBase>
  </AsyncExecute>
</template>

<script>
import { AsyncExecute, OverlayBase } from "@/lib";
import { ButtonPrimary, ButtonSecondary } from "@/lib";
import { ControlSelectSingle } from "@/lib";
import { DialogForm } from "@/lib";
import { DescriptionText } from "@/lib";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: {
    AsyncExecute,
    ButtonPrimary,
    ButtonSecondary,
    ControlSelectSingle,
    DescriptionText,
    DialogForm,
    OverlayBase,
  },
  computed: {
    ...mapGetters("auth", ["perfis"]),
    defaultPayload() {
      const perfil
      = this.hasPreselectedPerfil && this.perfis.includes(this.perfil)
        ? this.perfil
        : this.perfis[0];
      return {
        perfil,
      };
    },
    // alguns links customizam a experiência de login indicando o uso de um perfil especifico para aquela sessão.
    hasPreselectedPerfil() {
      return !!this.perfil;
    },
    hasSinglePerfilAvailable() {
      return this.perfis.length === 1;
    },
  },
  methods: {
    ...mapMutations("auth", ["resetState"]),
    ...mapActions("auth", ["signIn"]),
  },
  mounted() {
    if (this.hasPreselectedPerfil || this.hasSinglePerfilAvailable) {
      this.signIn(this.defaultPayload);
    }
  },
  name: "auth-dialog-user-scope",
  props: {
    perfil: {
      default: null,
      type: String,
    },
  },
};
</script>
