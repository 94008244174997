import { isDate } from "@/lib";

import { stateKey as signStateKey } from "./sign";

const LOCAL_STORAGE_KEY = "sessionData";

export const actions = {
  async attemptAuthenticationFromStorage({ commit }) {
    const restoredState = JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_KEY));
    if (!restoredState) return false;

    const expirationStr = restoredState[signStateKey]?.expiration;
    const expiration = new Date(expirationStr);
    const isValidSession = isDate(expiration) && expiration > new Date();

    if (isValidSession) {
      restoredState[signStateKey].expiration = expiration;
      commit("resetState", restoredState);
    }

    return isValidSession;
  },

  eraseAuthFromStorage() {
    window.localStorage.removeItem(LOCAL_STORAGE_KEY);
  },

  persistAuthInStorage({ state }) {
    window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
  },
};
