<template>
  <ControlWrapper
    :control-component="SelectSingleBase"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import ControlWrapper from "./control-wrapper";
import SelectSingleBase from "./select-single-base";

export default {
  components: { ControlWrapper },
  data() {
    return {
      SelectSingleBase,
    };
  },
  name: "control-select-single",
};
</script>
