var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "FragmentBase",
    [
      _c("ButtonSecondary", {
        attrs: { field: "sign-out", label: "Sair" },
        on: {
          click: function ($event) {
            return _vm.$emit("signOut")
          },
        },
      }),
      _vm._v(" "),
      _c("ButtonPrimary", {
        attrs: { field: "fluxos", label: "Listar Fluxos" },
        on: {
          click: function ($event) {
            return _vm.$emit("list")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }