<template>
  <BFormRadioGroup
    v-if="!hide"
    v-model="innerValue"
    :disabled="disabled"
    stacked
    class="input-radio-group"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import { BFormRadioGroup } from "../bv";

export default {
  components: { BFormRadioGroup },
  data() {
    return {
      innerValue: this.value,
    };
  },
  name: "input-radio",
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    hide: {
      default: false,
      type: Boolean,
    },
    value: {
      default: null,
      type: String,
    },
  },
  watch: {
    innerValue: {
      handler(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>

<style scoped>
.input-radio-group::v-deep .custom-radio {
  margin-top: var(--w-size-20);
}
</style>
