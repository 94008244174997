var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("AsyncExecute", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ isBusy, execute, lastError }) {
          return [
            _c(
              "OverlayBase",
              { attrs: { "is-busy": isBusy } },
              [
                _c("DescriptionText", [
                  _vm._v(
                    "\n      O seu email pode estar associado a múltiplos clientes. Por favor, escolha o "
                  ),
                  _c("strong", [_vm._v("cliente")]),
                  _vm._v(" ao qual você vinculará esta sessão.\n    "),
                ]),
                _vm._v(" "),
                _c("DialogForm", {
                  attrs: {
                    "external-errors": lastError,
                    "default-payload": _vm.defaultPayload,
                  },
                  on: {
                    save: function ($event) {
                      return execute(_vm.loadConta, $event.idCliente)
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "body",
                        fn: function ({ payload }) {
                          return [
                            _c(
                              "RowForm",
                              [
                                _c(
                                  "ColBase",
                                  [
                                    _c("ControlSelectSingle", {
                                      attrs: {
                                        field: "cliente",
                                        options: _vm.clientes,
                                        "value-field": "id",
                                        "text-field": "nome",
                                        rules: "required",
                                        label: "Cliente",
                                        autofocus: "",
                                      },
                                      model: {
                                        value: payload.idCliente,
                                        callback: function ($$v) {
                                          _vm.$set(payload, "idCliente", $$v)
                                        },
                                        expression: "payload.idCliente",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: "actions",
                        fn: function () {
                          return [
                            _c("ButtonSecondary", {
                              attrs: { label: "Cancelar", field: "cancel" },
                              on: { click: _vm.resetState },
                            }),
                            _vm._v(" "),
                            _c("ButtonPrimary", {
                              ref: "selectChannelButton",
                              attrs: {
                                label: "Selecionar",
                                field: "selectCliente",
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }