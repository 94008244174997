<template>
  <BImg
    class="image-icon"
    :src="src"
    :height="size"
    :width="size"
    alt="icon"
    v-bind="$attrs"
  />
</template>

<script>
import { BImg } from "../bv";

export default {
  components: { BImg },
  name: "icon-base-img",
  props: {
    size: {
      default: "24px",
      type: String,
    },
    src: {
      required: true,
      type: String,
    },
  },
};
</script>

<style>
/*
  hacks para aproximar o posicionamento das imagens ao que é observado com os
  demais ícones em botões. usado inicialmente com os ícones do serpro-id e
  assinador desktop serpro.
*/
.image-icon {
  margin-right: var(--w-size-10);
  margin-top: calc(-1 * var(--w-size-10));
}
</style>
