<template>
  <p :class="cssClass">
    <slot />
  </p>
</template>

<script>
export default {
  name: "description-text",
  props: {
    cssClass: {
      default: "",
      type: String,
    },
  },
};
</script>
