<template>
  <BButton
    v-if="!hide"
    :id="id"
    v-tooltip.hover.bottom
    :type="type"
    :title="parsedTooltip"
    :disabled="consolidatedDisabled"
    :pill="!squared"
    v-bind="$attrs"
    name="action"
    :value="field"
    @click="$emit('click')"
  >
    <AppIcon
      v-if="iconConfig.show"
      :name="iconConfig.name"
      :animation="iconConfig.animation"
    />
    {{ consolidatedLabel }}
  </BButton>
</template>

<script>
import { camelCase, capitalizeFirst } from "@cert/web";

import { BButton } from "../bv";
import { AppIcon } from "../icons";

export default {
  components: { AppIcon, BButton },
  computed: {
    consolidatedDisabled() {
      return this.disabled || this.isBusy;
    },
    consolidatedLabel() {
      return this.isBusy ? null : this.label;
    },
    iconConfig() {
      return {
        animation: this.isBusy ? "throb" : null,
        name: this.isBusy ? "circle" : this.icon,
        show: this.isBusy || this.icon,
      };
    },
    id() {
      return camelCase("button", this.field);
    },
    parsedTooltip() {
      return capitalizeFirst(this.tooltip);
    },
  },
  inheritAttrs: false,
  name: "ButtonBase",
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    field: {
      default: null,
      type: String,
    },
    hide: {
      default: false,
      type: Boolean,
    },
    icon: {
      default: null,
      type: String,
    },
    isBusy: {
      default: false,
      type: Boolean,
    },
    label: {
      default: null,
      type: [String, Number],
    },
    squared: {
      default: false,
      type: Boolean,
    },
    tooltip: {
      default: null,
      type: String,
    },
    type: {
      default: "button",
      type: String,
    },
  },
  watch: {
    isBusy(newValue) {
      /*
        por um motivo ainda desconhecido, ao ativar o modo busy a tooltip
        se fixa em exibição. abaixo é feito o fechamento forçado.
      */
      if (newValue) this.$root.$emit("bv::hide::tooltip", this.id);
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
