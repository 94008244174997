<template>
  <AsyncExecute v-slot="{ isBusy, execute, lastError }">
    <OverlayBase :is-busy="isBusy">
      <DescriptionText>
        O seu email pode estar associado a múltiplos clientes. Por favor, escolha o <strong>cliente</strong> ao qual você vinculará esta sessão.
      </DescriptionText>
      <DialogForm
        :external-errors="lastError"
        :default-payload="defaultPayload"
        @save="execute(loadConta, $event.idCliente)"
      >
        <template #body="{ payload }">
          <RowForm>
            <ColBase>
              <ControlSelectSingle
                v-model="payload.idCliente"
                field="cliente"
                :options="clientes"
                value-field="id"
                text-field="nome"
                rules="required"
                label="Cliente"
                autofocus
              />
            </ColBase>
          </RowForm>
        </template>
        <template #actions>
          <ButtonSecondary
            label="Cancelar"
            field="cancel"
            @click="resetState"
          />
          <ButtonPrimary
            ref="selectChannelButton"
            label="Selecionar"
            field="selectCliente"
          />
        </template>
      </DialogForm>
    </OverlayBase>
  </AsyncExecute>
</template>

<script>
import { ControlSelectSingle } from "@/lib";
import { DialogForm } from "@/lib";
import { AsyncExecute, OverlayBase } from "@/lib";
import { ButtonPrimary, ButtonSecondary } from "@/lib";
import { ColBase, RowForm } from "@/lib";
import { DescriptionText } from "@/lib";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: {
    AsyncExecute,
    ButtonPrimary,
    ButtonSecondary,
    ColBase,
    ControlSelectSingle,
    DescriptionText,
    DialogForm,
    OverlayBase,
    RowForm,
  },
  computed: {
    ...mapGetters("auth", ["clientes"]),
    defaultPayload() {
      const firstCliente = this.clientes[0];
      return {
        idCliente: firstCliente.id,
      };
    },
  },
  methods: {
    ...mapActions("auth", ["loadConta"]),
    ...mapMutations("auth", ["resetState"]),
  },
  mounted() {
    // caso o email fornecido pelo usuário esteja vinculado a apenas um cliente, a tela opera a seleção automaticamente
    if (this.clientes.length === 1) {
      this.loadConta(this.defaultPayload.idCliente);
    }
  },
  name: "auth-dialog-channel",
};
</script>
