var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ButtonBase",
    _vm._g(
      _vm._b(
        {
          staticClass: "button-primary px-4 py-2 text-nowrap",
          attrs: { type: _vm.type, variant: "primary" },
        },
        "ButtonBase",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }