import { request } from "@/api";
import { createCollectionStoreModule } from "@/lib";

import { getConfiguration } from "../configuration";
import { parseRecordToFluxo } from "../fluxos-common";

const baseEndpoint = "/fluxos/arquivados";

export const fluxosArchivedStore = createCollectionStoreModule({
  apiService: "controlador",
  pageLimit: getConfiguration("WEB_PAGINATION_LIMIT"),
  parseRecordToItem: parseRecordToFluxo,
  pollingSeconds: getConfiguration("WEB_POLLING_SECONDS"),
  queryEndpoint: baseEndpoint,
  request,
});
