var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("AppIcon", {
        staticClass: "d-inline",
        attrs: { name: "info", variant: "info" },
      }),
      _vm._v(" "),
      _c("span", { staticClass: "info-text" }, [_vm._t("default")], 2),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }