export function getContentSizeFromRichText(delta) {
  let textLength = 0;
  if (!delta) return textLength;

  delta.ops.forEach((op) => {
    if (typeof op.insert === "string") {
      textLength += op.insert.replace(/\n/g, "").length + (op.insert.match(/\n/g) || []).length;
    }
    else if (typeof op.insert === "object") {
      // imagens e videos são contados como um
      textLength += 1;
    }
  });

  // subtrai a contagem da última quebra de página porque gera confusão no feedback ao usuário
  textLength--;

  return textLength;
}
