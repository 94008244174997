import { request } from "@/api";
import { createCollectionStoreModule } from "@/lib";

import { getConfiguration } from "../configuration";
import { isArchived, parseFluxoToRecord, parseRecordToFluxo } from "../fluxos-common";

const baseEndpoint = "/fluxos";

export const fluxosAdministrativoStore = createCollectionStoreModule({
  actions: {
    async archiveFluxo({ dispatch }, fluxo) {
      const payload = {
        endpoint: `${baseEndpoint}/${encodeURI(fluxo.id)}/arquivar`,
        method: "patch",
      };
      await dispatch("setItemCommand", payload);
    },
    async cancelFluxo({ dispatch }, fluxo) {
      const payload = {
        endpoint: `${baseEndpoint}/${encodeURI(fluxo.id)}/cancelar`,
        method: "patch",
      };
      await dispatch("setItemCommand", payload);
    },
    async finishFluxo({ dispatch }, fluxo) {
      const payload = {
        endpoint: `${baseEndpoint}/${encodeURI(fluxo.id)}/finalizar`,
        method: "patch",
      };
      await dispatch("setItemCommand", payload);
    },
    async startFluxo({ dispatch }, fluxo) {
      await dispatch("editItemCommand", fluxo);
      const payload = {
        endpoint: `${baseEndpoint}/${encodeURI(fluxo.id)}/iniciar`,
        method: "patch",
      };
      await dispatch("setItemCommand", payload);
    },
  },
  apiService: "controlador",
  editEndpoint: baseEndpoint,
  filterItem: item => !isArchived(item),
  mutations: {
    addDocumento: (state, { idFluxo, ...data }) => {
      const documentos = state.items[idFluxo].documentos;
      documentos.push(data);
    },
    delDocumento: (state, { id, idFluxo }) => {
      const documentos = state.items[idFluxo].documentos;
      const i = documentos.findIndex(doc => doc.id === id);
      documentos.splice(i, 1);
    },
  },
  newEndpoint: baseEndpoint,
  pageLimit: getConfiguration("WEB_PAGINATION_LIMIT"),
  parseItemToRecord: parseFluxoToRecord,
  parseRecordToItem: parseRecordToFluxo,
  pollingSeconds: getConfiguration("WEB_POLLING_SECONDS"),
  queryEndpoint: baseEndpoint,
  request,
});
