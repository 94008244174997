import { request } from "@/api";
import { createCollectionStoreModule } from "@/lib";

import { getConfiguration } from "../configuration";

const clienteEndpoint = "/clientes";

export const clientesStore = createCollectionStoreModule({
  apiService: "autenticador",
  editEndpoint: clienteEndpoint,
  newEndpoint: clienteEndpoint,
  pageLimit: getConfiguration("WEB_PAGINATION_LIMIT"),
  parseItemToRecord: (item) => {
    const isAdding = !item.cliente;
    if (isAdding) return item;

    // adapta campos de acordo com sintaxe do payload exigido pelo backend na inclusão
    return {
      contratante: {
        estrangeiro: false,
        identificador: item.cliente.cnpj,
        nome: item.cliente.nome,
        sigla: item.cliente.sigla,
        tecnicosProducao: [
          {
            cpf: item.gestor.codigo,
            email: item.gestor.email,
            nome: item.gestor.nome,
            paisIso3: "BRA",
            telefone: item.gestor.telefone,
            telefoneSecundario: "",
            tipoCodigo: item.gestor.tipoCodigo,
          },
        ],
        tipoCliente: "PJ",
      },
    };
  },
  pollingSeconds: getConfiguration("WEB_POLLING_SECONDS"),
  queryEndpoint: clienteEndpoint,
  request,
});
