import { logger, tryThenThrow } from "@cert/web";
import { readonly, ref } from "vue";

export function useExecute({ retry = false } = {}) {
  const isBusy = ref(false);
  const lastError = ref(null);
  const lastResult = ref(null);

  const execute = async (saga, payload) => {
    const onExecute = typeof saga === "function" ? saga : saga.onExecute;
    const onExecuteWithPayload = () => {
      return onExecute(payload);
    };

    // sagas relacionadas a carregamento de dados da store não são bem sucedidas em casos onde a página com a instrução fosse a primeira acessada pelo usuário, p.e.: usuário acessa link de editar requisitante. usar  tryThenThrow foi o mecanismo encontrado para que a carga de dados em memória fosse concluída.
    const options = {
      attempts: 2,
      isValid: r => !!r,
    };
    const wrappedOnExecute = retry
      ? () => tryThenThrow(onExecuteWithPayload, options)
      : onExecuteWithPayload;

    const { onError, onSuccess } = saga;

    if (isBusy.value) {
      logger.info("não é possível executar um comando enquanto outro ainda está em execução", { saga });
      return;
    }

    isBusy.value = true;
    lastError.value = null;
    lastResult.value = null;
    try {
      lastResult.value = await wrappedOnExecute();
      onSuccess && onSuccess(lastResult.value);
    }
    catch (error) {
      lastError.value = error;
      if (onError) {
        onError(lastError.value);
      }
      else {
        const message = "possible error without treatment during async execute";
        logger.info(message, { error });
      }
    }
    finally {
      isBusy.value = false;
    }
  };

  return {
    execute,
    isBusy: readonly(isBusy),
    lastError: readonly(lastError),
    lastResult: readonly(lastResult),
  };
}
