var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "pb-3" }, [_vm._t("body")], 2),
      _vm._v(" "),
      _c("PanelButtons", {
        attrs: { "show-divider": _vm.showDivider },
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function () {
                return [_vm._t("actions")]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }