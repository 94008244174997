import { logger } from "@cert/web";

const maybeWindows = navigator.userAgent.toLowerCase().includes("win");
export const ALGORITMO_DESKTOP = maybeWindows ? "SHA-256" : "SHA-512";

export const CONNECTION_STATUSES = {
  CONNECTED: "CONNECTION_STATUSES.CONNECTED",
  DISCONNECTED: "CONNECTION_STATUSES.DISCONNECTED",
  STARTING: "CONNECTION_STATUSES.STARTING",
  UNAUTHORIZED: "CONNECTION_STATUSES.UNAUTHORIZED",
};

export async function attemptConnection() {
  const isLoaded = window.SerproSignerClient && window.SerproSignerPromise;
  if (!isLoaded) return CONNECTION_STATUSES.STARTING;

  const isRunning = await verifyDesktopClientInstallation();
  if (!isRunning) return CONNECTION_STATUSES.DISCONNECTED;

  const isConnected = window.SerproSignerClient.isConnected();
  if (isConnected) return CONNECTION_STATUSES.CONNECTED;

  configureDesktopClient();
  await connectToWebSocket();
  const isConnectedAfterAfterAttempt = window.SerproSignerClient.isConnected();

  return isConnectedAfterAfterAttempt
    ? CONNECTION_STATUSES.CONNECTED
    : CONNECTION_STATUSES.UNAUTHORIZED;
}

function verifyDesktopClientInstallation() {
  return new Promise((resolve) => {
    window.SerproSignerClient.verifyIsInstalledAndRunning()
      .success(() => resolve(true))
      .error((e) => {
        logger.info(
          "window.SerproSignerClient.verifyIsInstalledAndRunning failed",
          e,
        );
        resolve(false);
      });
  });
}

function configureDesktopClient() {
  try {
    window.SerproSignerClient.setDebug(true);
    window.SerproSignerClient.setUriServer(
      "wss",
      "127.0.0.1",
      65156,
      "/signer",
    );
  }
  catch (e) {
    logger.error(e);
  }
}

function connectToWebSocket() {
  return new Promise((resolve) => {
    const onOpenClose = connectionStatus => resolve(connectionStatus === 1);
    const onError = (e) => {
      logger.info("window.SerproSignerClient.connect failed", e);
      resolve(false);
    };
    window.SerproSignerClient.connect(onOpenClose, onOpenClose, onError);
  });
}

export function signDocumentsHashes(hashes) {
  const hashPack = Array.isArray(hashes) ? hashes.join(" ") : hashes;

  return new Promise((resolve, reject) => {
    window.SerproSignerClient.sign("hash", hashPack)
      .success((response) => {
        if (response.actionCanceled) {
          // o assinador serpro trata o cancelamento pelo usuário como uma exceção e comumente gera uma exceção que não passa nem por esse código de tratamento.
          const message
            = "O usuário cancelou a assinatura pelo Assinador Desktop Serpro";
          logger.info(message);
          return reject(new Error(message));
        }
        resolve(response.signature.split(" "));
      })
      .error((response) => {
        // cenário mais comum desse trecho é um erro levantado pelo assinador serpro quando o usuário cancela manualmente a operação de assinatura, mas como não há uma codificação ou tipificação dos erros optou-se aqui por continuar levantando a exceção.
        logger.info("potential error during sign with assinador serpro", {
          assinadorSerproResponse: response,
        });
        reject(new Error(response.error));
      });
  });
}
