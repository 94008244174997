<template>
  <NavBar
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #logo>
      <LogoApp responsive />
    </template>
    <template #links>
      <LayoutNavUser />
    </template>
  </NavBar>
</template>

<script>
import { NavBar } from "@/lib";
import { LogoApp } from "@/lib";

import LayoutNavUser from "./layout-nav-user";

export default {
  components: {
    LayoutNavUser,
    LogoApp,
    NavBar,
  },
  name: "layout-nav-bar",
};
</script>
