import { isConfiguration } from "@/data";

export function createGuardRoute(store) {
  const isOnMaintenance = isConfiguration("WEB_ON_MAINTENANCE");
  if (isOnMaintenance) {
    return (to, from, next) => {
      const maintenance = "/maintenance";
      return to.path === maintenance ? next() : next(maintenance);
    };
  }

  const isRouteAllowed = (route) => {
    if (!route) return false;
    const { access } = route.meta;
    if (access.includes("PUBLIC")) return true;

    return store.getters["auth/perfilIsAnyOf"](access);
  };

  let firstAttemptedRoute = null;
  let wasFirstAttemptedRedirected = false;
  const shouldRedirectToFirstAttempt = () => {
    if (wasFirstAttemptedRedirected) return false;
    if (!store.getters["auth/isSignedIn"]) return false;

    const { access } = firstAttemptedRoute.meta;
    const isFirstAttemptAnExternalRoute = !!access.find(a =>
      ["NONE", "PUBLIC"].includes(a),
    );
    if (isFirstAttemptAnExternalRoute) return false;

    const isFirstAttemptAnAllowedRoute = isRouteAllowed(firstAttemptedRoute);
    return isFirstAttemptAnAllowedRoute;
  };

  return (to, from, next) => {
    if (!firstAttemptedRoute) {
      firstAttemptedRoute = to;
    }

    if (shouldRedirectToFirstAttempt()) {
      wasFirstAttemptedRedirected = true;
      return next(firstAttemptedRoute.fullPath);
    }

    if (!isRouteAllowed(to)) {
      return next({
        path: "/default",
        // assim parâmetros embutidos no link original podem ser reusados na autenticação o outro redirecionamento prévio ao link desejado originalmente
        query: firstAttemptedRoute.query,
      });
    }

    next();
  };
}
