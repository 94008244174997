export function convertRecordsToItems(records, parseRecordToItem) {
  return records.map(r => convertRecordToItem(r, parseRecordToItem));
}

export function convertRecordToItem(record, parseRecordToItem) {
  if (isEmpty(record)) return {};
  return parseRecordToItem
    ? parseRecordToItem(record)
    : { ...record };
}

export function convertItemToRecord(item, parseItemToRecord) {
  if (isEmpty(item)) return {};
  return parseItemToRecord
    ? parseItemToRecord(item)
    : { ...item };
}

function isEmpty(object) {
  return !object || Object.keys(object).length === 0;
}
