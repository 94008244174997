var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("MessageBase", {
    ref: "modal",
    attrs: {
      variant: "success",
      title: "Sucesso",
      message: _vm.message,
      "message-detail": [
        "A sua resposta foi recebida com sucesso.",
        "Um email será enviado à você para acesso aos documentos. Caso haja mais de um interessado, o email seguirá após a resposta dos demais",
      ],
      icon: "success",
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ save, cancel }) {
          return [
            _vm.forceSignOut
              ? _c("ButtonsOut", { on: { signOut: cancel } })
              : _c("ButtonsListOut", { on: { signOut: cancel, list: save } }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }