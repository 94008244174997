import { throwAppError } from "../error";
import { sortSafe } from "./sort-safe";

export function sortByField(arrayMaybe, { asc = true, field } = {}) {
  let normalize;
  if (typeof field === "function") {
    normalize = field;
  }
  if (typeof field === "string") {
    normalize = (obj) => {
      if (!obj) return undefined;

      const value = obj[field];
      if (typeof value === "string") return value.toLowerCase();

      return value;
    };
  }
  if (!normalize) {
    throwAppError({ message: "sortByField field param must be a string or function" });
  }

  const compare = (a, b) => {
    const aValue = normalize(a);
    const bValue = normalize(b);

    let compared = 0;
    if (aValue < bValue) compared = -1;
    if (aValue > bValue) compared = 1;
    if (!asc) compared = compared * -1;
    return compared;
  };

  return sortSafe(arrayMaybe, compare);
}
