<template>
  <ButtonBase
    class="button-tertiary px-4 py-2 text-nowrap"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import ButtonBase from "./button-base";

export default {
  components: { ButtonBase },
  name: "button-tertiary",
};
</script>

<style scoped>
.button-tertiary {
  font-weight: var(--w-font-weight-50);
  color: var(--w-color-primary-30);
  background-color: var(--w-color-neutral-10);
  border: var(--w-border-size-10) solid transparent;
}

.button-tertiary:focus,
.button-tertiary:hover,
.button-tertiary:active {
  color: var(--w-color-primary-30) !important;
  background-color: var(--w-color-neutral-10) !important;
  border: var(--w-border-size-10) solid var(--w-color-primary-30) !important;
}

.button-tertiary:disabled {
  color: var(--w-transparency-primary) !important;
  background-color: var(--w-color-neutral-10) !important;
  border: var(--w-border-size-10) solid var(--w-color-neutral-10) !important;
}
</style>
