<template>
  <b-icon-x-circle-fill
    variant="danger"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import { BIconXCircleFill } from "../bv";

export default {
  components: { BIconXCircleFill },
  name: "icon-error",
};
</script>
