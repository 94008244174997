var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "MenuText",
        [
          _vm._v("\n    Confira as Novidades\n    "),
          _c("BadgeBase", {
            staticClass: "float-right",
            attrs: { text: _vm.prettyHowManyUnread },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.logs, function (log) {
        return _c("MenuItem", { key: log.key, attrs: { to: "/changelog" } }, [
          _vm._v("\n    " + _vm._s(log.value) + "\n  "),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }