var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BButton",
    _vm._b(
      {
        staticClass: "text-secondary",
        attrs: { id: _vm.camelCase("button", _vm.field), variant: "light" },
        on: {
          click: function ($event) {
            return _vm.$emit("toggle", !_vm.isOpen)
          },
        },
      },
      "BButton",
      _vm.$attrs,
      false
    ),
    [
      _vm.isOpen
        ? _c("AppIcon", { attrs: { name: "close" } })
        : _c("AppIcon", { attrs: { name: _vm.icon } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }