import { randomStr } from "@cert/web";

import { ButtonClose } from "../buttons";
import { iconRetriever } from "../icons";
import { ColBase, RowBase } from "../layout";

export function notifySuccess(message, id) {
  // "this" será uma referência para a instância do componente Vue que evocou esta função
  notify(this, message, "success", id);
}

export function notifyError(message, id) {
  // "this" será uma referência para a instância do componente Vue que evocou esta função
  notify(this, message, "error", id);
}

function notify(parent, message, variant, id) {
  const h = parent.$createElement;
  const bvToast = parent.$root.$bvToast;
  const toastId = id || randomStr(5);
  const body = createMessageBody(h, bvToast, toastId, message, variant);

  bvToast.toast(body, {
    autoHideDelay: 5000,
    id: toastId,
    noAutoHide: false, // habilite para depurar visualmente a notificação
    noCloseButton: true,
    solid: true,
  });
}

function createMessageBody(h, bvToast, id, message, variant) {
  const closeButton = h(ButtonClose, {
    on: { click: () => bvToast.hide(id) },
  });

  const body = h(RowBase, [
    h(ColBase, { props: { cols: "3" } }, [
      h(iconRetriever(variant), { attrs: { fontScale: "3" } }),
    ]),
    h(ColBase, { props: { cols: "9" } }, message),
    closeButton,
  ]);

  return body;
}
