var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BNavbar",
    {
      staticClass: "navs-nav-bar",
      attrs: { sticky: true, variant: "light", type: "light" },
    },
    [
      !_vm.hideHamburger
        ? _c("ButtonToggle", {
            attrs: { field: "navHamburger" },
            model: {
              value: _vm.innerIsOpen,
              callback: function ($$v) {
                _vm.innerIsOpen = $$v
              },
              expression: "innerIsOpen",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "BNavbarBrand",
        {
          staticClass: "d-flex align-items-center p-0 m-0 ml-2",
          attrs: { to: "/" },
        },
        [_vm._t("logo")],
        2
      ),
      _vm._v(" "),
      _vm._t("links"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }