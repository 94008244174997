var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "rich-text-wrapper",
      class: {
        disabled: _vm.disabled,
        valid: _vm.state === true,
        invalid: _vm.state === false,
      },
      attrs: { id: _vm.id },
    },
    [
      !_vm.hide ? _c("div", { attrs: { id: _vm.targetId } }) : _vm._e(),
      _vm._v(" "),
      _c("ItemsCounter", { attrs: { delta: _vm.value } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }