var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("DescriptionText", [
    _vm._v(
      "\n  Percebemos que você já possui um dispositivo cadastrado para se autenticar ao Neosigner. Vamos tentar utilizar ele.\n"
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }