var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ToolbarButtons",
    [
      _c(
        "PdfDocumentToolbarZoom",
        _vm._g(
          {
            attrs: {
              field: _vm.camelCase("zoom", _vm.field),
              disabled: _vm.disabled,
              zoom: _vm.zoom,
            },
          },
          _vm.$listeners
        )
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "PdfDocumentToolbarPages",
            _vm._g(
              {
                attrs: {
                  field: _vm.camelCase("pages", _vm.field),
                  disabled: _vm.disabled,
                  "pages-total": _vm.pagesTotal,
                  "pages-current": _vm.pagesCurrent,
                },
              },
              _vm.$listeners
            )
          ),
          _vm._v(" "),
          _c("FileButtonDownload", {
            attrs: {
              field: _vm.camelCase("download", _vm.field),
              disabled: _vm.disabled,
              "file-download": _vm.pdfDownload,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }