<template>
  <ModalBase
    ref="modal"
    show-action-divider
  >
    <template #body="{ cancel }">
      <RowBase>
        <ColBase class="d-none d-sm-block">
          <div class="d-flex justify-content-center">
            <AppIcon
              :name="icon"
              :variant="variant"
              font-scale="5"
            />
          </div>
        </ColBase>
        <ColBase
          cols="12"
          sm="9"
        >
          <w-text
            weight="30"
            size="45"
            :variant="variant"
          >
            <AppIcon
              :name="icon"
              :variant="variant"
              class="d-inline-block d-sm-none mr-2"
            />
            {{ title }}
          </w-text>
          <p>{{ message }}</p>
          <ul
            v-if="hasDetail"
            class="detail"
          >
            <li
              v-for="part in messageDetailNormalized"
              :key="part"
            >
              {{ part }}
            </li>
          </ul>
        </ColBase>
      </RowBase>

      <ButtonClose @click="cancel()" />
    </template>
    <template #actions="{ save, cancel }">
      <slot
        :save="save"
        :cancel="cancel"
      />
    </template>
  </ModalBase>
</template>

<script>
import { ButtonClose } from "../buttons";
import { AppIcon } from "../icons";
import { ColBase, RowBase } from "../layout";
import ModalBase from "./modal-base";

export default {
  components: {
    AppIcon,
    ButtonClose,
    ColBase,
    ModalBase,
    RowBase,
  },
  computed: {
    hasDetail() {
      return this.messageDetailNormalized.length >= 1;
    },
    messageDetailNormalized() {
      if (!this.messageDetail) return [];
      if (Array.isArray(this.messageDetail)) return this.messageDetail;
      return [this.messageDetail];
    },
  },
  methods: {
    show() {
      return this.$refs.modal.show();
    },
  },
  name: "message-base",
  props: {
    icon: {
      required: true,
      type: String,
    },
    message: {
      required: true,
      type: String,
    },
    messageDetail: {
      default: null,
      type: [Array, String],
    },
    title: {
      required: true,
      type: String,
    },
    variant: {
      required: true,
      type: String,
    },
  },
};
</script>

<style scoped>
.detail {
  font-weight: var(--w-font-weight-10);
  padding: 0;
  list-style-position: inside;
}
</style>
