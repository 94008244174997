var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("IconBaseSvg", {
    attrs: {
      path: "M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }