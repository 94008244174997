<template>
  <IconBaseImg src="/public/icone-serpro-id.svg" />
</template>

<script>
import IconBaseImg from "./icon-base-img";

export default {
  components: { IconBaseImg },
  name: "icon-serpro-id",
};
</script>
