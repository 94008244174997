<template>
  <ValidationWrapper
    v-slot="{ state, error }"
    :field="field"
    :label="label"
    :rules="rules"
    @validation="$emit('validation', $event)"
  >
    <ControlGroup
      :control-id="controlId"
      :helper-text="helperText"
      :label="label"
      :error="error"
      :state="state"
    >
      <!-- ValidationProvider depende de uma propriedade v-model para identificar qual o componente que vai fazer a validação, daí o uso logo abaixo de innerValue ao invés do tradicional modelo props.value mais emit.input. -->
      <component
        :is="controlComponent"
        :id="controlId"
        v-model="innerValue"
        :state="state"
        v-bind="$attrs"
        :class="controlClasses"
        v-on="$listeners"
      />
    </ControlGroup>
  </ValidationWrapper>
</template>

<script>
import { camelCase } from "@/lib/meta";
import { ValidationWrapper } from "@/lib/validation";

import ControlGroup from "./control-group";

export default {
  components: { ControlGroup, ValidationWrapper },
  computed: {
    controlId() {
      return camelCase("control", this.field);
    },
  },
  data() {
    return {
      innerValue: this.value,
    };
  },
  name: "ControlWrapper",
  props: {
    controlClasses: {
      default: null,
      type: String,
    },
    controlComponent: {
      required: true,
      type: Object,
    },
    field: {
      required: true,
      type: String,
    },
    helperText: {
      default: null,
      type: String,
    },
    label: {
      default: null,
      type: String,
    },
    rules: {
      default: null,
      type: [String, Object],
    },
    value: {
      default: null,
      type: [String, Number, Boolean, Array, Object, Date],
    },
  },
  watch: {
    value: {
      handler(newValue) {
        /*
          Esse watcher permite alterar programaticamente o valor dos inputs em
          outros componentes que consomem este.
          Por exemplo, quando se precisa atualizar o valor de input
          baseando-se no valor do que for informado em outro campo.
        */
        if (this.innerValue !== newValue) {
          this.innerValue = newValue;
        }
      },
    },
  },
};
</script>
