<template>
  <BAlert
    :variant="variant"
    show
  >
    <AppIcon :name="icon" />
    <SpacerBase horizontal="10" />
    {{ text }}
  </BAlert>
</template>

<script>
import { BAlert } from "../bv";
import { AppIcon } from "../icons";
import { SpacerBase } from "../layout";

export default {
  components: { AppIcon, BAlert, SpacerBase },
  name: "alert-text",
  props: {
    icon: {
      default: "info",
      type: String,
    },
    text: {
      default: null,
      type: String,
    },
    variant: {
      default: "info",
      type: String,
    },
  },
};
</script>
