var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("AsyncExecute", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ isBusy, execute, lastError }) {
          return [
            _c(
              "OverlayBase",
              { attrs: { "is-busy": isBusy } },
              [
                _c("DescriptionText", [
                  _vm._v("Informe seu " + _vm._s(_vm.otpMessage)),
                ]),
                _vm._v(" "),
                _c("SpacerBase", { attrs: { vertical: "30", block: "" } }),
                _vm._v(" "),
                _c("DialogForm", {
                  attrs: { "external-errors": lastError },
                  on: {
                    save: function ($event) {
                      return execute(_vm.challengeUserOtp, $event)
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "body",
                        fn: function ({ payload }) {
                          return [
                            _c("ControlOtp", {
                              attrs: { autofocus: "", field: "otp" },
                              model: {
                                value: payload.otp,
                                callback: function ($$v) {
                                  _vm.$set(payload, "otp", $$v)
                                },
                                expression: "payload.otp",
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "pb-3" }),
                          ]
                        },
                      },
                      {
                        key: "actions",
                        fn: function ({ payload }) {
                          return [
                            _c("ButtonTertiary", {
                              attrs: { label: "Cancelar", field: "cancel" },
                              on: { click: _vm.resetState },
                            }),
                            _vm._v(" "),
                            _c("ButtonSecondary", {
                              attrs: {
                                disabled: _vm.isOtpBlocked,
                                label: "Reenviar código",
                                icon: "reply",
                                field: "goChannels",
                              },
                              on: { click: _vm.setOtpMessage },
                            }),
                            _vm._v(" "),
                            _c("ButtonPrimary", {
                              attrs: {
                                label: "Acessar",
                                field: "sendOtp",
                                disabled:
                                  !payload.otp || payload.otp.length !== 6,
                              },
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _vm._v(" "),
                _c("SpacerBase", { attrs: { vertical: "35", block: "" } }),
                _vm._v(" "),
                _vm.isOtpBlocked
                  ? _c("AlertText", {
                      attrs: {
                        text: `Aguarde ${_vm.otpBlockedRemainingSeconds} segundos antes de reenviar o código.`,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }