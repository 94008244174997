var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("MenuText", [_vm._v("Perfil do Usuário")]),
      _vm._v(" "),
      _vm._l(_vm.perfis, function (perfil) {
        return _c(
          "MenuItem",
          {
            key: perfil,
            attrs: { disabled: _vm.perfilIsAnyOf(perfil) },
            on: {
              click: function ($event) {
                return _vm.handleTrocaPerfil(perfil)
              },
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "content",
                class: { current: _vm.perfilIsAnyOf(perfil) },
              },
              [
                _c("span", { staticClass: "dot" }),
                _vm._v(" "),
                _c("SpacerBase", { attrs: { horizontal: "10" } }),
                _vm._v(" "),
                _c("span", { staticClass: "text" }, [_vm._v(_vm._s(perfil))]),
              ],
              1
            ),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }