var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.$slots.default
    ? _c(
        "div",
        {
          staticClass:
            "d-flex flex-column flex-md-row justify-content-md-between",
          class: {
            "border-top": _vm.showDivider,
            "pt-4": _vm.showDivider,
          },
        },
        [
          _c("div", { staticClass: "actions-bar" }, [_vm._t("start")], 2),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "actions-bar actions-end" },
            [_vm._t("default")],
            2
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }