var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CenterBase",
    [
      _c(
        "ColorShifter",
        { attrs: { desktop: "var(--w-color-neutral-10)" } },
        [
          _c("DisplayText", { attrs: { size: 1 } }, [
            _vm._v("\n      404\n    "),
          ]),
          _vm._v(" "),
          _c("DisplayText", { attrs: { size: 5 } }, [
            _vm._v(
              "\n      Oops! A página que você tentou acessar não foi encontrada.\n    "
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("SpacerBase", { attrs: { vertical: "20", block: "" } }),
      _vm._v(" "),
      _c("ButtonSecondary", {
        staticClass: "mt-3 w-auto",
        attrs: { label: "Ir para o início" },
        on: {
          click: function ($event) {
            return _vm.$router.push("/default")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }