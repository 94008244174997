<template>
  <div class="input-base">
    <BFormInput
      v-if="!hide"
      v-model="display"
      v-mask="mask"
      :class="inputClass"
      :placeholder="conditionalPlaceholder"
      :readonly="disabled"
      :autocomplete="autocompleteNormalized"
      :type="lookupCompatibleType"
      v-bind="$attrs"
      @blur="handleBlur"
      @focus="handleFocus"
      @keyup.enter.prevent="handleLookupSelectByKeyboard"
      @keyup.up="decLookupChosenIndex"
      @keyup.down="incLookupChosenIndex"
    />
    <div
      v-if="isLookupOpen"
      :id="camelCase('lookup', $attrs.id)"
      class="wrapper"
    >
      <ul
        ref="menu"
        class="lookup-menu"
      >
        <li
          v-for="(option, index) in lookupList"
          :id="option.value"
          :key="option.value"
          class="lookup-item"
          :class="{ active: index === chosenIndex }"
          @mousedown="handleLookupSelectByMouse"
          @mouseover="setLookupChosenIndex(index)"
        >
          {{ option.text }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { extractRawValue, replaceMaskTokens } from "@/lib/validation";
import { camelCase, equalsIgnoreCase, sortByField } from "@cert/web";

import { BFormInput } from "../bv";

export default {
  components: { BFormInput },
  computed: {
    autocompleteNormalized() {
      if (this.autocomplete) return this.autocomplete;
      return this.isLookup && "off";
    },
    conditionalPlaceholder() {
      if (this.placeholder) return this.placeholder;
      if (this.mask) return replaceMaskTokens(this.mask, "_");

      return null;
    },
    isLookup() {
      return (
        this.lookupOptions && this.lookupTextField && this.lookupValueField
      );
    },
    isLookupOpen() {
      return (
        this.isLookup
        && this.shouldLookupMenuBeOpen
        && this.lookupOptionsFiltered.length > 0
      );
    },
    lookupCompatibleType() {
      /*
        os teste feitos com a versão 97.0.4692.71 demonstram que o atributo off desabilita
        sugestões ao campo capturadas durante o uso cotidiano do navegador. entretanto, os
        dados salvo no cadastro de endereços do chrome são exibidos mesmo com a
        opção off em autocomplete.

        a única técnica até agora capaz de desativar esse segundo tipo de autofill foi
        remover o campo name e sobrescrever o tipo com "search".

        https://adamsilver.io/blog/stopping-chrome-from-ignoring-autocomplete-off/
        https://stackoverflow.com/questions/59250835/google-chrome-disable-auto-completion-menu-for-manage-addresses
      */
      return this.isLookup ? "search" : this.type;
    },
    lookupList() {
      const normalized = this.lookupOptionsFiltered.map(option => ({
        text: option[this.lookupTextField],
        value: option[this.lookupValueField],
      }));
      const sorted = sortByField(normalized, { field: "text" });
      return sorted;
    },
    lookupOptionsFiltered() {
      if (!this.isLookup) return [];

      const shouldShow = o =>
        o[this.lookupTextField]
          .toLowerCase()
          .includes(this.value.toLowerCase());

      const filteredOptions = this.value
        ? this.lookupOptions.filter(shouldShow)
        : this.lookupOptions;

      return filteredOptions.slice(0, this.lookupOptionsMaxLength);
    },
  },
  data() {
    return {
      chosenIndex: 0,
      display: this.value,
      shouldLookupMenuBeOpen: false,
    };
  },
  inheritAttrs: false,
  methods: {
    attemptDisplayUpdate(newValue) {
      const currentDisplayValue = extractRawValue(this.display, this.mask);
      if (newValue !== currentDisplayValue) {
        // significa que a prop "value" foi atualizada sem intervenção do usuário
        // e é preciso refletir na exibição do componente.
        this.display = newValue;
      }
    },
    attemptValueUpdate(newDisplay) {
      const newDisplayValue = this.mask
        ? extractRawValue(newDisplay, this.mask)
        : newDisplay;

      if (newDisplayValue !== this.value) {
        this.$emit("input", newDisplayValue);
      }
    },
    camelCase,
    decLookupChosenIndex() {
      if (this.chosenIndex > 0) this.chosenIndex--;
    },
    handleBlur(blurEvent) {
      if (this.isLookup) this.handleLookupAttemptByText(this.value);
      this.$emit("blur", blurEvent);
    },
    handleFocus(focusEvent) {
      this.resetLookupChosenIndex();
      this.signalOpenLookupMenu();
      this.$emit("focus", focusEvent);
    },
    handleLookupAttemptByText(text) {
      const lookupOption = this.lookupOptions.find(o =>
        equalsIgnoreCase(o[this.lookupTextField], text),
      );
      this.handleLookupChosen(
        lookupOption && lookupOption[this.lookupValueField],
      );
    },
    handleLookupChosen(lookupValue) {
      this.resetLookupChosenIndex();
      this.signalCloseLookupMenu();

      const chosenOption = this.lookupOptions.find(
        o => o[this.lookupValueField] === lookupValue,
      );

      if (chosenOption) {
        this.$emit("input", chosenOption[this.lookupTextField]);
        this.$emit("lookup", chosenOption);
      }
    },
    handleLookupSelectByKeyboard() {
      const lookupMenu = this.$refs.menu;
      if (!lookupMenu || !lookupMenu.children) return;
      const lookupChosenItem = lookupMenu.children.item(this.chosenIndex);
      this.handleLookupChosen(lookupChosenItem.id);
    },
    handleLookupSelectByMouse(clickEvent) {
      const lookupChosenItem = clickEvent.target;
      this.handleLookupChosen(lookupChosenItem.id);
    },
    incLookupChosenIndex() {
      if (this.chosenIndex < this.lookupOptionsFiltered.length - 1) {
        this.chosenIndex++;
      }
    },
    resetLookupChosenIndex() {
      this.chosenIndex = 0;
    },
    setLookupChosenIndex(newIndex) {
      this.chosenIndex = newIndex;
    },
    signalCloseLookupMenu() {
      this.shouldLookupMenuBeOpen = false;
    },
    signalOpenLookupMenu() {
      this.shouldLookupMenuBeOpen = true;
    },
  },
  name: "input-base",
  props: {
    autocomplete: {
      default: null,
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    hide: {
      default: false,
      type: Boolean,
    },
    inputClass: {
      default: null,
      type: String,
    },
    lookupOptions: {
      default: null,
      type: Array,
    },
    lookupOptionsMaxLength: {
      default: 5,
      type: Number,
    },
    lookupTextField: {
      default: null,
      type: String,
    },
    lookupValueField: {
      default: null,
      type: String,
    },
    mask: {
      default: null,
      type: String,
    },
    placeholder: {
      default: null,
      type: String,
    },
    type: {
      default: null,
      type: String,
    },
    value: {
      default: "",
      type: String,
    },
  },
  watch: {
    "display": "attemptValueUpdate",
    "lookupOptionsFiltered.length": "resetLookupChosenIndex",
    "value": "attemptDisplayUpdate",
  },
};
</script>

<style scoped>
.input-base {
  /*
    facilita construir responsividade em composições com o componente.
    especialmente dentro de um inputGroup como acontece no uso de
    searchText nas listagens da aplicação.
  */
  width: 100%;
}

.wrapper {
  position: relative;
  z-index: 99;
}

.lookup-menu {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0;
  margin-top: var(--w-size-10);
  padding: 0;
  border: var(--w-border-size-10) solid var(--w-color-neutral-20);
  box-shadow: var(--w-shadow-primary);
  background-color: var(--w-color-neutral-10);
}

.lookup-item {
  list-style-type: none;
  cursor: pointer;
  padding: 0 var(--w-size-30);
}

.active {
  background-color: var(--w-color-info-30);
  color: var(--w-color-neutral-10);
}

.wrapper-wrapper {
  display: flex;
}
</style>
