import { ref, watch } from "vue";

import { useExecute } from "./use-execute";

// o composable é utilizado para monitorar e responder aos estados de carregamento de módulos que possuem as features de query.

export function useQueryState({ afterFirstQuery, modules, store }) {
  const getAreModulesQuerying = createAreModulesQuerying(store, modules);

  const isBusyWithQuerying = ref(getAreModulesQuerying());

  watch(
    getAreModulesQuerying,
    newValue => (isBusyWithQuerying.value = newValue),
    { immediate: true },
  );

  const getQueriedOnce = createQueriedOnce(store, modules);
  const { execute, isBusy: isBusyWithAfterFirstQuery } = useExecute({ retry: true });
  const isBusyWithFirstQuerying = ref(!getQueriedOnce() || isBusyWithAfterFirstQuery.value);
  watch(
    getQueriedOnce,
    async (queriedOnce) => {
      if (!queriedOnce) {
        isBusyWithFirstQuerying.value = true;
        return;
      }

      if (!afterFirstQuery) {
        isBusyWithFirstQuerying.value = false;
        return;
      }

      await execute(afterFirstQuery);
      isBusyWithFirstQuerying.value = false;
    },
    {
      immediate: true,
    },
  );

  return { isBusyWithFirstQuerying, isBusyWithQuerying };
}

function createAreModulesQuerying(store, modules) {
  return () => {
    const exist = m => store.hasModule(m);
    const isQuerying = m => store.getters[`${m}/isQuerying`];
    return modules.some(m => !exist(m) || isQuerying(m));
  };
}

function createQueriedOnce(store, modules) {
  return () => {
    const exist = m => store.hasModule(m);
    const queriedOnce = m => store.getters[`${m}/hasQueriedOnce`];
    return modules.every(m => exist(m) && queriedOnce(m));
  };
}
