<template>
  <span :class="badge && 'badge-wrapper'">
    <component
      :is="iconRetriever(name)"
      :id="field && camelCase('icon', field)"
      v-tooltip.hover.bottom
      :title="tooltip"
      :style="{ fill: fillColor }"
      v-bind="$attrs"
      v-on="$listeners"
    />
    <span
      v-if="badge"
      class="icon-badge"
    >
      {{ badge }}
    </span>
  </span>
</template>

<script>
import { camelCase } from "@cert/web";

import { iconRetriever } from "./app-icon-retriever";

export default {
  methods: {
    camelCase,
    iconRetriever,
  },
  name: "app-icon",
  props: {
    badge: {
      default: null,
      type: [String, Number],
    },
    field: {
      default: null,
      type: String,
    },
    // o valor default sendo '' evita um comportamento indesejado onde a tooltip
    fillColor: {
      default: null,
      type: String,
    },
    name: {
      required: true,
      type: String,
    },
    // não apaga reativamente em componentes que tiveram previamente um valor de tooltip.
    tooltip: {
      default: "",
      type: String,
    },
  },
};
</script>

<style scoped>
.badge-wrapper {
  height: min-content;
  position: relative;
  display: flex;
  align-items: center;
}

.icon-badge {
  --inner-size: var(--w-size-20);
  --size: calc(var(--inner-size) * 1.5);
  --offset: calc(var(--size) / -3.5);
  position: absolute;
  font-size: var(--inner-size);
  height: var(--size);
  width: var(--size);
  top: var(--offset);
  right: var(--offset);
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: var(--w-color-warning-30);
  color: var(--w-color-neutral-10);
  font-weight: var(--w-font-weight-50);
}
</style>
