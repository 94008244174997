var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex" },
    [
      _c("BImg", {
        attrs: {
          src: _vm.src,
          height: _vm.height,
          width: _vm.width,
          alt: "logo",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }