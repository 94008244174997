var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ControlInput",
    _vm._g(
      _vm._b(
        {
          attrs: { rules: _vm.composedRules, type: "email", label: _vm.label },
        },
        "ControlInput",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }