import { getConfiguration } from "@/data";
import { AuthPage } from "@/pages/auth-page";
import MaintenancePage from "@/pages/maintenance/maintenance-page.vue";
import NotFoundPage from "@/pages/not-found/not-found-page.vue";

import {
  AdministrativoFluxoPageAdd,
  AdministrativoFluxoPageEdit,
  AdministrativoFluxoPageRespostas,
  AdministrativoFluxoPageView,
  AdministrativoFluxosPage,
  ArchivedPageList,
  ArchivedPageRespostas,
  ArchivedPageView,
  ChangelogPage,
  ClientePageAdd,
  ClientePageEdit,
  ClientesPage,
  ExternoAcknowledgmentPage,
  ExternoDesktopPage,
  ExternoFluxosPage,
  ExternoRespostaPage,
  ExternoRespostasPage,
  ExternoRevisaoPage,
  ExternoSerproIdPage,
  GestorPageAdd,
  GestorPageEdit,
  GestoresPage,
  GrupoMembrosListPage,
  GrupoPageAdd,
  GrupoPageEdit,
  GrupoPageView,
  GruposPage,
  InteressadoPageAdd,
  InteressadoPageEdit,
  InteressadosPage,
  RequisitantePageAdd,
  RequisitantePageEdit,
  RequisitantesPage,
} from "./routes-inner-pages";

const crumbsHome = [{ text: "Página inicial", to: "/" }];
const crumbsClientes = [...crumbsHome, { text: "Clientes", to: "/clientes" }];
const crumbsGestores = [...crumbsHome, { text: "Gestores", to: "/gestores" }];
const crumbsInteressados = [
  ...crumbsHome,
  { text: "Interessados", to: "/interessados" },
];
const crumbsGrupos = [...crumbsHome, { text: "Grupos", to: "/grupos" }];
const crumbsRequisitantes = [
  ...crumbsHome,
  { text: "Requisitantes", to: "/requisitantes" },
];

const textFluxos = { text: "Fluxos de Assinatura" };
const crumbsFluxosAdministrativo = [
  ...crumbsHome,
  { ...textFluxos, to: "/fluxos-administrativo" },
];
const crumbsFluxosInteressado = [
  ...crumbsHome,
  { ...textFluxos, to: "/fluxos-externo" },
];

const crumbsFluxosArchived = [
  ...crumbsHome,
  { text: "Fluxos Arquivados", to: "/fluxos-archived/list" },
];

export const routes = [
  {
    component: AuthPage,
    meta: {
      access: ["NONE"],
      layout: "LayoutHero",
    },
    path: "/auth",
    props: route => ({
      linkId: route.query.linkId,
      linkToken: route.query.linkToken,
      perfil: route.query.perfil,
      session: route.query.session,
    }),
  },
  { path: "/", redirect: "/auth" },

  {
    component: MaintenancePage,
    meta: {
      access: ["PUBLIC"],
      layout: "LayoutHero",
    },
    path: "/maintenance",
  },

  {
    component: ChangelogPage,
    meta: {
      access: ["ADMINISTRADOR", "GESTOR", "REQUISITANTE", "INTERESSADO"],
      crumbs: [...crumbsHome, { text: "Novidades" }],
      layout: "LayoutNav",
    },
    path: "/changelog",
  },

  {
    component: NotFoundPage,
    meta: {
      access: ["PUBLIC"],
      layout: "LayoutHero",
    },
    path: "/404",
  },

  {
    component: ClientesPage,
    meta: {
      access: ["ADMINISTRADOR"],
      crumbs: crumbsClientes,
      layout: "LayoutNav",
    },
    path: "/clientes",
  },

  {
    component: ClientePageEdit,
    meta: {
      access: ["ADMINISTRADOR"],
      crumbs: [...crumbsClientes, { text: "Edição de Cliente" }],
      layout: "LayoutNav",
    },
    path: "/clientes/edit/:itemId",
    props: true,
  },

  {
    component: ClientePageAdd,
    meta: {
      access: ["ADMINISTRADOR"],
      crumbs: [...crumbsClientes, { text: "Inclusão de Cliente" }],
      layout: "LayoutNav",
    },
    path: "/clientes/add",
  },

  {
    component: GestoresPage,
    meta: {
      access: ["ADMINISTRADOR", "GESTOR"],
      crumbs: crumbsGestores,
      layout: "LayoutNav",
    },
    path: "/gestores",
  },

  {
    component: GestorPageAdd,
    meta: {
      access: ["ADMINISTRADOR", "GESTOR"],
      crumbs: [...crumbsGestores, { text: "Inclusão de Gestor" }],
      layout: "LayoutNav",
    },
    path: "/gestores/add",
  },

  {
    component: GestorPageEdit,
    meta: {
      access: ["ADMINISTRADOR", "GESTOR"],
      crumbs: [...crumbsGestores, { text: "Edição de Gestor" }],
      layout: "LayoutNav",
    },
    path: "/gestores/edit/:itemId",
    props: true,
  },

  {
    component: InteressadosPage,
    meta: {
      access: ["ADMINISTRADOR", "GESTOR", "REQUISITANTE"],
      crumbs: crumbsInteressados,
      layout: "LayoutNav",
    },
    path: "/interessados",
  },

  {
    component: InteressadoPageAdd,
    meta: {
      access: ["ADMINISTRADOR", "GESTOR", "REQUISITANTE"],
      crumbs: [...crumbsInteressados, { text: "Inclusão de Interessado" }],
      layout: "LayoutNav",
    },
    path: "/interessados/add",
  },

  {
    component: InteressadoPageEdit,
    meta: {
      access: ["ADMINISTRADOR", "GESTOR", "REQUISITANTE"],
      crumbs: [...crumbsInteressados, { text: "Edição de Interessado" }],
      layout: "LayoutNav",
    },
    path: "/interessados/edit/:itemId",
    props: true,
  },

  {
    component: GruposPage,
    meta: {
      access: ["ADMINISTRADOR", "GESTOR"],
      crumbs: crumbsGrupos,
      header: "Grupos",
      layout: "LayoutNav",
    },
    path: "/grupos",
  },

  {
    component: GrupoPageAdd,
    meta: {
      access: ["ADMINISTRADOR", "GESTOR"],
      crumbs: [...crumbsGrupos, { text: "Inclusão de Grupo" }],
      layout: "LayoutNav",
    },
    path: "/grupos/add",
  },

  {
    component: GrupoPageEdit,
    meta: {
      access: ["ADMINISTRADOR", "GESTOR"],
      crumbs: [...crumbsGrupos, { text: "Edição de Grupo" }],
      layout: "LayoutNav",
    },
    path: "/grupos/edit/:itemId",
    props: true,
  },

  {
    component: GrupoPageView,
    meta: {
      access: ["ADMINISTRADOR", "GESTOR"],
      crumbs: [...crumbsGrupos, { text: "Visualização de Grupo" }],
      layout: "LayoutNav",
    },
    path: "/grupos/view/:itemId",
    props: true,
  },

  {
    component: GrupoMembrosListPage,
    meta: {
      access: ["ADMINISTRADOR", "GESTOR"],
      crumbs: [...crumbsGrupos, { text: "Membros de Grupo" }],
      layout: "LayoutNav",
    },
    path: "/grupos/membros/:itemId",
    props: true,
  },

  {
    component: RequisitantesPage,
    meta: {
      access: ["ADMINISTRADOR", "GESTOR"],
      crumbs: crumbsRequisitantes,
      layout: "LayoutNav",
    },
    path: "/requisitantes",
  },

  {
    component: RequisitantePageAdd,
    meta: {
      access: ["ADMINISTRADOR", "GESTOR"],
      crumbs: [...crumbsRequisitantes, { text: "Inclusão de Requisitante" }],
      layout: "LayoutNav",
    },
    path: "/requisitantes/add",
  },

  {
    component: RequisitantePageEdit,
    meta: {
      access: ["ADMINISTRADOR", "GESTOR"],
      crumbs: [...crumbsRequisitantes, { text: "Edição de Requisitante" }],
      layout: "LayoutNav",
    },
    path: "/requisitantes/edit/:itemId",
    props: true,
  },

  {
    component: AdministrativoFluxosPage,
    meta: {
      access: ["REQUISITANTE"],
      crumbs: crumbsFluxosAdministrativo,
      layout: "LayoutNav",
    },
    path: "/fluxos-administrativo",
  },

  {
    component: AdministrativoFluxoPageAdd,
    meta: {
      access: ["REQUISITANTE"],
      crumbs: [...crumbsFluxosAdministrativo, { text: "Inclusão de Fluxo" }],
      layout: "LayoutNav",
    },
    path: "/fluxos-administrativo/add",
  },

  {
    component: AdministrativoFluxoPageEdit,
    meta: {
      access: ["REQUISITANTE"],
      crumbs: [...crumbsFluxosAdministrativo, { text: "Edição de Fluxo" }],
      layout: "LayoutNav",
    },
    path: "/fluxos-administrativo/edit/:itemId",
    // fluxos edit permite definição do tab inicial para suportar o tab dados básicos em edições diretas mas também a aba de interessados após inclusão com posterior redirecionamento automático
    props: route => ({
      initialTab: route.query.initialTab,
      itemId: route.params.itemId,
    }),
  },

  {
    component: AdministrativoFluxoPageView,
    meta: {
      access: ["REQUISITANTE"],
      crumbs: [
        ...crumbsFluxosAdministrativo,
        { text: "Visualização de Fluxo" },
      ],
      layout: "LayoutNav",
    },
    path: "/fluxos-administrativo/view/:itemId",
    props: true,
  },

  {
    component: AdministrativoFluxoPageRespostas,
    meta: {
      access: ["REQUISITANTE"],
      crumbs: [
        ...crumbsFluxosAdministrativo,
        { text: "Situação de Respostas" },
      ],
      layout: "LayoutNav",
    },
    path: "/fluxos-administrativo/respostas/:itemId",
    props: true,
  },

  {
    component: ArchivedPageList,
    meta: {
      access: ["REQUISITANTE"],
      crumbs: crumbsFluxosArchived,
      layout: "LayoutNav",
    },
    path: "/fluxos-archived/list",
  },

  {
    component: ArchivedPageRespostas,
    meta: {
      access: ["REQUISITANTE"],
      crumbs: [...crumbsFluxosArchived, { text: "Histórico de Respostas" }],
      layout: "LayoutNav",
    },
    path: "/fluxos-archived/respostas/:itemId",
    props: true,
  },

  {
    component: ArchivedPageView,
    meta: {
      access: ["REQUISITANTE"],
      crumbs: [...crumbsFluxosArchived, { text: "Visualização de Fluxo" }],
      layout: "LayoutNav",
    },
    path: "/fluxos-archived/view/:itemId",
    props: true,
  },

  {
    component: ExternoFluxosPage,
    meta: {
      access: ["INTERESSADO"],
      crumbs: crumbsFluxosInteressado,
      layout: "LayoutNav",
    },
    path: "/fluxos-externo",
  },

  {
    component: ExternoRespostasPage,
    meta: {
      access: ["INTERESSADO"],
      crumbs: [...crumbsFluxosInteressado, { text: "Histórico de Respostas" }],
      layout: "LayoutNav",
    },
    path: "/fluxos-externo/respostas/:itemId",
    props: true,
  },

  {
    component: ExternoRespostaPage,
    meta: {
      access: ["INTERESSADO"],
      crumbs: [...crumbsFluxosInteressado, { text: "Minha Resposta" }],
      layout: "LayoutNav",
    },
    path: "/fluxos-externo/resposta/:itemId",
    props: true,
  },

  // a quantidade de caracteres no link enviado pelo backend aos usuários é restringida pelos limites impostos a mensagens sms. o redirect abaixo permite que o backend adote uma url curta no sms e o frontend cuida de transforma ela aqui em algo conforme os padrões adotados nas demais urls.
  {
    path: "/r/:itemId",
    props: true,
    redirect: from => ({
      path: "/fluxos-externo/resposta/:itemId",
      query: {
        linkId: from.params.itemId,
        linkToken: from.query.t,
        perfil: "INTERESSADO",
        session: "clean",
      },
    }),
  },

  {
    component: ExternoAcknowledgmentPage,
    meta: {
      access: ["INTERESSADO"],
      crumbs: [
        ...crumbsFluxosInteressado,
        { text: "Verificação de Documento" },
      ],
      layout: "LayoutNav",
    },
    path: "/fluxos-externo/acknowledgment/:fluxoId/:documentoId",
    props: true,
  },

  {
    component: ExternoDesktopPage,
    meta: {
      access: ["INTERESSADO"],
      crumbs: [
        ...crumbsFluxosInteressado,
        { text: "Assinador Desktop Serpro" },
      ],
      layout: "LayoutNav",
    },
    path: "/fluxos-externo/desktop/:fluxoId",
    props: true,
  },

  {
    component: ExternoSerproIdPage,
    meta: {
      access: ["INTERESSADO"],
      crumbs: [...crumbsFluxosInteressado, { text: "SerproId" }],
      layout: "LayoutNav",
    },
    path: "/fluxos-externo/serpro-id/:fluxoId",
    props: route => ({
      fluxoId: route.params.fluxoId,
      responseSerproId: route.query.responseSerproId,
    }),
  },

  {
    // rota usada pelo serpro-id para responder pedidos de autorização ao neosigner
    path: getConfiguration("WEB_SERPRO_ID_REDIRECT"),
    redirect: (to) => {
      const fluxoId = to.query.state;
      // a resposta do serpro-id contém ou o código de sucesso ou uma mensagem genérica de erro sinalizando a provável recusa de autorização do usuário
      const responseSerproId = to.query.code || "error";
      return {
        path: `/fluxos-externo/serpro-id/${fluxoId}`,
        query: { responseSerproId },
      };
    },
  },

  {
    component: ExternoRevisaoPage,
    meta: {
      access: ["INTERESSADO"],
      crumbs: [...crumbsFluxosInteressado, { text: "Revisão" }],
      layout: "LayoutNav",
    },
    path: "/fluxos-externo/revisao/:fluxoId",
    props: true,
  },

  { path: "*", redirect: "/404" },
];
