<template>
  <div v-if="text">
    <p
      class="header-text m-0 mb-2"
      :class="[element, truncate && 'text-truncate']"
    >
      {{ text }}
    </p>
    <HeaderDetail />
  </div>
</template>

<script>
import HeaderDetail from "./header-detail";

export default {
  components: { HeaderDetail },
  name: "header-text",
  props: {
    element: {
      default: "h4",
      type: String,
    },
    text: {
      default: null,
      type: String,
    },
    truncate: {
      default: false,
      type: Boolean,
    },
  },
};
</script>

<style scoped>
.header-text {
  color: var(--w-color-primary-on-light);
}
</style>
