<template>
  <ToolbarSection>
    <ButtonIcon
      :field="camelCase('minus', field)"
      :disabled="disabled || zoom <= 5"
      icon="zoom-out"
      @click="$emit('update:zoom', Math.round(zoom - 5))"
    />
    <ButtonIcon
      :field="camelCase('current', field)"
      disabled
      :label="zoomPretty"
      class="zoom-label"
    />
    <ButtonIcon
      :field="camelCase('plus', field)"
      :disabled="disabled || zoom > 800"
      icon="zoom-in"
      @click="$emit('update:zoom', Math.round(zoom + 5))"
    />
  </ToolbarSection>
</template>

<script>
import { camelCase } from "@cert/web";

import { ButtonIcon, ToolbarSection } from "../buttons";

export default {
  components: { ButtonIcon, ToolbarSection },
  computed: {
    zoomPretty() {
      return `${this.zoom}%`;
    },
  },
  methods: {
    camelCase,
  },
  name: "pdf-document-toolbar-zoom",
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    field: {
      default: "zoom",
      type: String,
    },
    zoom: {
      default: 100,
      type: Number,
    },
  },
};
</script>

<style scoped>
.zoom-label {
  display: none;
}

@media (min-width: 576px) {
  .zoom-label {
    display: revert;
  }
}
</style>
