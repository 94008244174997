import {
  browserSupportsWebAuthn,
  startAuthentication as startAuthenticationWebAuthn,
  startRegistration as startRegistrationWebAuthn,
} from "@simplewebauthn/browser";

export const stateKey = "webAuthn";

export const initialState = {
  authenticationOptions: null,
  authenticatorResponse: null,
  isRegistrationPending: true,
  registrationOptions: null,
  skippedAuthentication: false,
};

export const getters = {
  isWebAuthnAuthenticatorAvailable: (state, { isWebAuthnManuallySkippedAuthentication, isWebAuthnSupported, webAuthnAuthenticationOptions }) => {
    if (!isWebAuthnSupported || !webAuthnAuthenticationOptions) return false;
    if (isWebAuthnManuallySkippedAuthentication) return false;

    // se houver algum registro em "allowCredentials" significa que o usuário já cadastrou algum dispositivo de autenticação junto ao backend em sessão anterior.
    const { allowCredentials } = webAuthnAuthenticationOptions;
    return allowCredentials.length > 0;
  },
  isWebAuthnManuallySkippedAuthentication: state => state[stateKey].skippedAuthentication,
  isWebAuthnRegistrationPending: state => state[stateKey].isRegistrationPending,
  isWebAuthnSupported: () => browserSupportsWebAuthn(),
  webAuthnAuthenticationOptions: state => state[stateKey].authenticationOptions,
  webAuthnAuthenticatorResponse: state => state[stateKey].authenticatorResponse,
  webAuthnRegistrationOptions: state =>
    state[stateKey].registrationOptions,
};

export const mutations = {
  setWebAuthnAuthenticationOptions(state, options = {}) {
    state[stateKey].authenticationOptions = options;
  },

  setWebAuthnAuthenticatorResponse(state, setAuthenticatorResponse) {
    state[stateKey].authenticatorResponse = setAuthenticatorResponse;
  },

  setWebAuthnRegistrationDone(state) {
    state[stateKey].isRegistrationPending = false;
  },

  setWebAuthnRegistrationOptions(state, options = {}) {
    state[stateKey].registrationOptions = options;
  },

  setWebAuthnSkippedAuthentication(state) {
    state[stateKey].skippedAuthentication = true;
  },
};

export const actions = {
  async activateWebAuthnConditionalUi({ commit, dispatch, getters }) {
    if (!getters.isWebAuthnSupported) return;

    // é usado para buscar opções genéricas de uso de web authn para preenchimento automático de autocomplete de contas já presentes no navegador
    const payload = {
      endpoint: "/authentication-options",
      method: "get",
    };
    const authenticationOptions = await dispatch("requestAction", payload);
    commit("setWebAuthnAuthenticationOptions", authenticationOptions);

    await dispatch("startAuthentication", { autocomplete: true });
    if (getters.webAuthnAuthenticatorResponse) {
      await dispatch("verifyAuthentication");
    }
  },

  async authenticateWithWebAuthn({ dispatch, getters }) {
    await dispatch("startAuthentication");
    if (getters.webAuthnAuthenticatorResponse) {
      await dispatch("verifyAuthentication");
    }
  },

  async registerAuthenticator({ commit, dispatch, getters: { idConta, webAuthnRegistrationOptions } }) {
    let authenticatorResponse;
    try {
      authenticatorResponse = await startRegistrationWebAuthn(webAuthnRegistrationOptions);
    }
    catch (e) {
      const error = new Error("Não foi possível registrar o dispositivo. Tente novamente ou siga em frente para entrar no sistema");
      error.property = "form";
      throw error;
    }
    commit("setWebAuthnAuthenticatorResponse", authenticatorResponse);

    const payload = {
      endpoint: "/verify-registration",
      method: "post",
      record: {
        authenticatorResponse,
        idConta: idConta,
      },
    };
    // valida contra o backend a resposta fornecida pelo autenticador web authn do navegador ou sistema operacional. o objetivo é concluir o registro de um novo dispositivo web authn vinculado a uma conta junto ao backend.
    await dispatch("requestAction", payload);
    commit("setWebAuthnRegistrationDone");
  },

  async startAuthentication({ commit, getters: { isWebAuthnSupported, webAuthnAuthenticationOptions } }, { autocomplete = false } = {}) {
    // action auxiliar responsável pela comunicação com o autenticador gerenciado pelo navegador. ela é usada por outras ações que cobrem a autenticação como um todo.

    if (!isWebAuthnSupported) return;

    let authenticatorResponse;
    try {
      authenticatorResponse = await startAuthenticationWebAuthn(webAuthnAuthenticationOptions, autocomplete);
    }
    catch (e) {
      // o erro aconteceu ao tentar preencher a UI com a lista de authenticators salvos no navegador. não temos muitos recursos pra entender o que levou ao erro, por ser, por exemplo, que não haja chaves salvas ainda. daí suprimimos a exceção.
      if (autocomplete) return;

      const error = new Error("Não foi possível autenticar usando o dispositivo. Tente novamente ou use um canal de comunicação para envio do código de segurança");
      error.property = "form";
      throw error;
    }

    commit("setWebAuthnAuthenticatorResponse", authenticatorResponse);
  },

  // verifyAuthentication é uma ação intermediária que trata da comunicação com o backend. é compartilhada por outras ações que cobrem a autenticação completa via web-authn
  async verifyAuthentication({ commit, dispatch, getters: { webAuthnAuthenticatorResponse } }) {
    const payload = {
      endpoint: "/verify-authentication",
      method: "post",
      record: { authenticatorResponse: webAuthnAuthenticatorResponse },
    };

    // valida contra o backend a resposta fornecida pelo autenticador web authn do navegador ou sistema operacional. o objetivo é concluir a autenticação com um dispositivo web authn vinculado anteriormente a essa conta junto ao backend.
    const {
      cliente: { id: idCliente, nome: nomeCliente, sigla },
      conta: { codigo, email, id: idConta, nome: nomeConta, perfis },
      expiracao: expirationAsDateString,
      token,
    } = await dispatch("requestAction", payload);

    if (perfis.length <= 0) {
      throw new Error("Não é possível realizar a autenticação pois usuário não possui perfil vinculado");
    }

    // alguns atributos são salvo no state mesmo que possivelmente tenham sido tratados em rotas anteriores. isso acontece porque os fluxos de autenticação seguem ordem de requisições diferentes e nesse momento é possível que certos campos do state ainda estejam indefinidos.
    const conta = { id: idConta, idCliente };
    const cliente = { id: idCliente, nome: nomeCliente, sigla };
    commit("setContas", [conta]);
    commit("setClientes", [cliente]);
    commit("setCodigo", codigo);
    commit("setEmail", email);
    commit("setNome", nomeConta);
    commit("setCliente", idCliente);
    commit("setConta", idConta);
    commit("setToken", token);
    commit("setExpiration", expirationAsDateString);
    commit("setPerfis", perfis);

    // se o usuário fez login com web authn considera-se que não há mais pendência de registro de dispositivo
    commit("setWebAuthnRegistrationDone");
  },

};
