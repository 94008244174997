import { encodeBase64 } from "./encode";

export async function digest(message, { algorithm = "SHA-256" } = {}) {
  // converte para o formato esperado pelo digest
  const encoder = new TextEncoder();
  const data = encoder.encode(message);
  // gera o digest como um ArrayBuffer
  const digested = await window.crypto.subtle.digest(algorithm, data);
  // converte para uma string em base 64 -- https://pt.wikipedia.org/wiki/Base64
  return encodeBase64(digested);
}
