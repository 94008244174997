import { clone } from "@/lib/meta";
import Vue from "vue";

/*
  concentra a criação de store modules de items de negócios com operações
  síncronas básicas para manutenção de uma lista indexada de items.
*/

export function createItemsFeatures(config) {
  const { filterItem } = config;
  return {
    getters: {
      filterItemsByFieldValue: (state, getters) => (field, value) =>
        getters.getAllItems.filter(item => item[field] === value),
      filterItemsByFieldWithSomeValue: (state, getters) => (field, values) =>
        getters.getAllItems.filter(item => values.includes(item[field])),
      getAllItems: (state) => {
        if (!state.items || typeof state.items !== "object") return [];
        if (typeof filterItem !== "function") return Object.values(state.items);
        return Object.values(state.items).filter(filterItem);
      },
      getItemByFieldValue: (state, getters) => (field, value) =>
        getters.getAllItems.find(item => item[field] === value),
      getItemById: state => id => state.items[id],
    },
    mutations: {
      removeItem(state, id) {
        Vue.delete(state.items, id);
      },
      resetItems: (state, items = {}) => {
        state.items = items;
      },
      setItem(state, item) {
        Vue.set(state.items, item.id, item);
      },
      updateItems: (state, itemsManifests = []) => {
        // a atualização em batch copiando o estado anterior e substituindo após processar todo o manifesto busca evitar um excesso de atualizações em tela competindo por recurso enquanto cada substituição ou exclusão for feita em sequencia sobre o state. nesse formato a ideia é que o calculo de reatividade será feito uma única vez
        const newItems = clone(state.items);
        itemsManifests.forEach((itemManifest) => {
          const { operation, payload } = itemManifest;
          if (operation === "del") delete newItems[payload.id];
          if (operation === "set") newItems[payload.id] = payload;
        });
        state.items = newItems;
      },
    },
    state: {
      items: {},
    },
  };
}
