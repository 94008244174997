<template>
  <BFormGroup
    label-class="p-0"
    :description="helperText"
  >
    <template
      v-if="label"
      #label
    >
      <LabelBase
        :text="label"
        :for="controlId"
      />
    </template>
    <slot />
    <BFormInvalidFeedback :state="state">
      {{ error }}
    </BFormInvalidFeedback>
  </BFormGroup>
</template>

<script>
import { BFormGroup, BFormInvalidFeedback } from "../bv";
import LabelBase from "./label-base";

export default {
  components: { BFormGroup, BFormInvalidFeedback, LabelBase },
  name: "control-group",
  props: {
    controlId: {
      required: true,
      type: String,
    },
    error: {
      default: null,
      type: String,
    },
    helperText: {
      default: null,
      type: String,
    },
    label: {
      default: null,
      type: String,
    },
    state: {
      default: null,
      type: Boolean,
    },
  },
};
</script>
