import { logger } from "@cert/web";
import { readonly, ref } from "vue";

export function useAsyncSearch(searchApi) {
  const isSearching = ref(false);
  const searchFailed = ref(false);
  const searchResult = ref(null);

  const search = async (searchToken) => {
    if (!searchToken) return;

    if (isSearching.value) {
      logger.info(
        "não é possível realizar uma nova pesquisa enquanto outra ainda está em execução",
        { searchApi, searchToken },
      );
      return;
    }

    isSearching.value = true;
    searchFailed.value = false;
    searchResult.value = null;

    try {
      const data = await searchApi(searchToken);
      if (!data || Object.keys(data).length === 0) {
        throw Error("force catch clause");
      }

      searchResult.value = {
        data,
        status: "success",
      };
    }
    catch (error) {
      searchFailed.value = true;
      searchResult.value = { status: "fail" };
    }
    finally {
      isSearching.value = false;
    }

    return searchResult;
  };

  const resetSearchState = () => {
    isSearching.value = false;
    searchFailed.value = false;
    searchResult.value = null;
  };

  return {
    isSearching: readonly(isSearching),
    resetSearchState,
    search,
    searchFailed: readonly(searchFailed),
    searchResult: readonly(searchResult),
  };
}
