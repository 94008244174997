import { convertItemToRecord, convertRecordToItem } from "./convert";

export function createCommandsFeatures(config) {
  const { editEndpoint, newEndpoint } = config;

  return {
    actions: {
      setItemCommand: createSetItemCommandAction(config),
      ...(newEndpoint && {
        addItemCommand: createAddItemCommandAction(config),
      }),
      ...(editEndpoint && {
        editItemCommand: createEditItemCommandAction(config),
      }),
    },
  };
}

export function createSetItemCommandAction(config) {
  const { parseRecordToItem } = config;

  return async ({ commit, dispatch }, payload) => {
    const record = await dispatch("requestAction", payload);
    const item = convertRecordToItem(record, parseRecordToItem);
    commit("setItem", item);
    return item;
  };
}

export function createAddItemCommandAction(config) {
  const { newEndpoint, parseItemToRecord } = config;

  return ({ dispatch }, newItem) => {
    const payload = {
      endpoint: newEndpoint,
      method: "post",
      record: convertItemToRecord(newItem, parseItemToRecord),
    };
    return dispatch("setItemCommand", payload);
  };
}

export function createEditItemCommandAction(config) {
  const { editEndpoint, parseItemToRecord } = config;

  return async ({ dispatch }, editedItem) => {
    const payload = {
      endpoint: `${editEndpoint}\\${editedItem.id}`,
      method: "put",
      record: convertItemToRecord(editedItem, parseItemToRecord),
    };
    return dispatch("setItemCommand", payload);
  };
}
