<template>
  <div>
    <ListPowerSearch
      v-model="searchedItems"
      :config="searchConfig"
      :all-items="items"
      class="mt-2"
    />
    <RowBase class="mt-2">
      <ColBase>
        <ListBase
          :field="field"
          :items="searchedItems"
          :fields="enhancedFields"
          :is-loading="isLoading"
          :primary-key="primaryKey"
          v-bind="$attrs"
          v-on="$listeners"
        >
          <template #cell(enum)="data">
            <AppIcon
              v-if="chooseEnumColorForItem(data.item)"
              name="circle"
              :variant="chooseEnumColorForItem(data.item)"
            />
          </template>
          <template #cell(actions)="data">
            <div class="d-flex flex-nowrap">
              <slot
                name="actions"
                :index="data.index"
                :item="data.item"
              />
            </div>
          </template>
          <!--
            o código a seguir puxa os slots dos componentes ancestrais e os aplica na
            bootstrap-vue table, é uma forma de evitar ter de declarar os mesmo slots
            em toda hierarquia de componentes que usar ListPower. mais detalhes na
            resposta abaixo:
            https://stackoverflow.com/questions/50891858/vue-how-to-pass-down-slots-inside-wrapper-component?rq=1
          -->
          <template
            v-for="(_, slot) of $scopedSlots"
            #[slot]="scope"
          >
            <slot
              :name="slot"
              v-bind="scope"
            />
          </template>
        </ListBase>
      </ColBase>
    </RowBase>
  </div>
</template>

<script>
import { AppIcon } from "../icons";
import { ColBase, RowBase } from "../layout";
import ListBase from "./list-base";
import ListPowerSearch from "./list-power-search";

export default {
  components: {
    AppIcon,
    ColBase,
    ListBase,
    ListPowerSearch,
    RowBase,
  },
  computed: {
    enhancedFields() {
      const fields = this.disabledActions
        ? this.fields
        : this.fields.concat({
          centerTitle: true,
          key: "actions",
          label: "Ações",
          minSize: true,
        });

      if (this.searchConfig?.enum) {
        fields.unshift({ key: "enum", label: "", minSize: true });
      }

      return fields;
    },
  },
  data() {
    return {
      searchedItems: [],
    };
  },
  methods: {
    chooseEnumColorForItem(item) {
      const enumCfg = this.searchConfig.enum;
      const enumValue = enumCfg.find(s => s.isEnum(item));
      return enumValue && enumValue.color;
    },
    handleDel(item) {
      this.$emit("del", item);
    },
    handleEdit(item) {
      this.$emit("edit", item);
    },
    handleView(item) {
      this.$emit("view", item);
    },
  },
  name: "list-power",
  props: {
    disabledActions: {
      default: false,
      type: Boolean,
    },
    field: {
      required: true,
      type: String,
    },
    fields: {
      required: true,
      type: Array,
    },
    isLoading: {
      default: false,
      type: Boolean,
    },
    items: {
      default: () => [],
      type: Array,
    },
    primaryKey: {
      default: "id",
      type: String,
    },
    searchConfig: {
      default: null,
      type: Object,
    },

  },
};
</script>
