export function createPollingFeatures({ pollingSeconds }) {
  return {
    actions: {
      onTurnOff: stopPolling,
      onTurnOn: startPolling,
    },
    getters: {
      getFrequency: () => pollingSeconds * 1000,
      getTimeoutId: state => state.timeoutId,
    },
    mutations: {
      setTimeoutId: (state, id) => (state.timeoutId = id),
    },
    state: {
      timeoutId: null,
    },
  };
}

export async function startPolling({ commit, dispatch, getters }) {
  commit("resetItems");

  const tick = async () => {
    await dispatch("queryItems");

    if (getters.getFrequency > 0) {
      // artificialAsync introduz um distanciamento aleatório de 10 segundos no próximo polling para evitar que as várias lojas de dados iniciem seus requests simultaneamente
      const artificialAsync = Math.round(Math.random() * 10 * 1000);
      const nextCall = getters.getFrequency + artificialAsync;
      const id = setTimeout(tick, nextCall);
      commit("setTimeoutId", id);
    }
  };
  tick();
}

export function stopPolling({ getters }) {
  clearTimeout(getters.getTimeoutId);
}
