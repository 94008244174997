import { request } from "./request";

export async function uploadFile({ file, meta, route }) {
  try {
    const config = {
      endpoint: route.endpoint,
      method: "upload",
      service: route.service,
    };
    const payload = {
      doc: file,
      ...meta,
    };
    const response = await request(config, payload);
    return {
      status: "success",
      value: response,
    };
  }
  catch (error) {
    return {
      status: "fail",
      value: error,
    };
  }
}

export async function deleteFile({ id, route }) {
  try {
    const config = {
      endpoint: `${route.endpoint}/${id}`,
      method: "delete",
      service: route.service,
    };
    await request(config, { id });
    return { status: "success" };
  }
  catch (error) {
    return { status: "fail", value: error };
  }
}

export async function downloadFile({ name = "", path }) {
  /*
    um link com href não garante o download. em alguns casos promove a abertura
    do documento no próprio navegador e o uso do atributo 'download' não funciona
    quando o href tem origem diferente do site. a solução abaixo é baseada em:
    https://stackoverflow.com/questions/49668363/html5-download-attribute-not-working-when-download-external-pdf-file-on-chrome
  */

  const blob = await request({ endpoint: path, method: "get.blob" });

  const anchor = document.createElement("a");
  anchor.style.display = "none";
  anchor.href = window.URL.createObjectURL(blob);
  anchor.setAttribute("download", name);
  document.body.appendChild(anchor);

  anchor.click();

  window.URL.revokeObjectURL(anchor.href);
  document.body.removeChild(anchor);
}
