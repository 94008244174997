<template>
  <div>
    <MenuText>Perfil do Usuário</MenuText>
    <MenuItem
      v-for="perfil in perfis"
      :key="perfil"
      :disabled="perfilIsAnyOf(perfil)"
      @click="handleTrocaPerfil(perfil)"
    >
      <div
        class="content"
        :class="{ current: perfilIsAnyOf(perfil) }"
      >
        <span class="dot" />
        <SpacerBase horizontal="10" />
        <span class="text">{{ perfil }}</span>
      </div>
    </MenuItem>
  </div>
</template>

<script>
import { MenuItem, MenuText, SpacerBase } from "@/lib";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { MenuItem, MenuText, SpacerBase },
  computed: {
    ...mapGetters("auth", ["perfil", "perfis", "perfilIsAnyOf"]),
  },
  methods: {
    ...mapActions("auth", ["signIn"]),
    handleTrocaPerfil(perfil) {
      this.signIn({ perfil });
    },
  },
  name: "user-menu-profile",
};
</script>

<style scoped>
.content {
  display: flex;
  align-items: center;
}

.current .text {
  color: var(--w-color-primary-30);
}

.dot {
  height: var(--w-size-20);
  width: var(--w-size-20);
  background-color: var(--w-color-neutral-10);
  border-radius: 50%;
  display: inline-block;
}

.current .dot {
  background-color: var(--w-color-primary-30);
}
</style>
