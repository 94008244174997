var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "code-input-box" },
      _vm._l(_vm.inputs, function (input) {
        return _c("input", {
          key: input.id,
          staticClass: "char-input",
          attrs: {
            id: input.id,
            "input-order": input.order,
            type: "text",
            placeholder: "*",
            maxlength: "1",
            autocomplete: "off",
            inputmode: "numeric",
          },
        })
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }