import { request } from "@/api";
import { createConnectedStoreModule } from "@/lib";

export const linksStore = createConnectedStoreModule({
  actions: {
    async sendLinks({ dispatch }, record) {
      const payload = {
        endpoint: "/reenviar-links",
        method: "patch",
        record,
      };
      await dispatch("requestAction", payload);
    },
  },
  apiService: "autenticador",
  request,
});
