var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CenterBase",
    [
      _c(
        "ColorShifter",
        { attrs: { desktop: "var(--w-color-neutral-10)" } },
        [
          _c("DisplayText", { attrs: { size: 4 } }, [
            _vm._v("\n      O NeoSigner está em manutenção\n    "),
          ]),
          _vm._v(" "),
          _c("SpacerBase", { attrs: { vertical: "20", block: "" } }),
          _vm._v(" "),
          _c("DisplayText", { attrs: { size: 4 } }, [
            _vm._v("\n      Retornaremos em breve\n    "),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("SpacerBase", { attrs: { vertical: "20", block: "" } }),
      _vm._v(" "),
      _c("ButtonSecondary", {
        staticClass: "mt-3 w-auto",
        attrs: {
          href: "https://form.omni.serpro.gov.br/upperScreenForm/3450",
          target: "_blank",
          label: "Entre em contato com o suporte",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }