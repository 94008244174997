/*
  esses módulos são carregados com a técnica de lazy-load, o que
  permite que o webpack realize code-splitting do código. mais em:
  https://router.vuejs.org/guide/advanced/lazy-loading.html
  https://webpack.js.org/guides/code-splitting/
*/

export const ClientesPage = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "@/pages/clientes-crud/clientes-list-page.vue"
  );

export const ClientePageEdit = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "@/pages/clientes-crud/cliente-page-edit.vue"
  );

export const ClientePageAdd = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "@/pages/clientes-crud/cliente-page-add.vue"
  );

export const GruposPage = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "@/pages/grupos-crud/grupos-list-page.vue"
  );

export const GrupoPageAdd = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "@/pages/grupos-crud/grupo-page-add.vue"
  );

export const GrupoPageEdit = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "@/pages/grupos-crud/grupo-page-edit.vue"
  );

export const GrupoPageView = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "@/pages/grupos-crud/grupo-page-view.vue"
  );

export const GrupoMembrosListPage = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "@/pages/grupo-membros-list/grupo-membros-list-page.vue"
  );

export const GestoresPage = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "@/pages/gestores-crud/gestores-list-page.vue"
  );

export const GestorPageAdd = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "@/pages/gestores-crud/gestor-page-add.vue"
  );

export const GestorPageEdit = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "@/pages/gestores-crud/gestor-page-edit.vue"
  );

export const InteressadosPage = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "@/pages/interessados-crud/interessados-list-page.vue"
  );

export const InteressadoPageAdd = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "@/pages/interessados-crud/interessado-page-add.vue"
  );

export const InteressadoPageEdit = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "@/pages/interessados-crud/interessado-page-edit.vue"
  );

export const RequisitantesPage = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "@/pages/requisitantes-crud/requisitantes-list-page.vue"
  );

export const RequisitantePageAdd = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "@/pages/requisitantes-crud/requisitante-page-add.vue"
  );

export const RequisitantePageEdit = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "@/pages/requisitantes-crud/requisitante-page-edit.vue"
  );

export const AdministrativoFluxosPage = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "@/pages/fluxos-administrativo-crud/fluxos-list-page.vue"
  );

export const AdministrativoFluxoPageView = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "@/pages/fluxos-administrativo-crud/fluxo-page-view.vue"
  );

export const AdministrativoFluxoPageAdd = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "@/pages/fluxos-administrativo-crud/fluxo-page-add.vue"
  );

export const AdministrativoFluxoPageEdit = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "@/pages/fluxos-administrativo-crud/fluxo-page-edit.vue"
  );

export const AdministrativoFluxoPageRespostas = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "@/pages/fluxos-administrativo-crud/fluxo-page-respostas.vue"
  );

export const ArchivedPageList = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "@/pages/fluxos-archived/fluxos-archived-list-page.vue"
  );

export const ArchivedPageRespostas = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "@/pages/fluxos-archived/page-respostas.vue"
  );

export const ArchivedPageView = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "@/pages/fluxos-archived/page-view.vue"
  );

export const ExternoFluxosPage = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "@/pages/fluxos-externo-crud/fluxos-externo-list-page.vue"
  );

export const ExternoRespostasPage = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "@/pages/fluxos-externo-crud/respostas-page.vue"
  );

export const ExternoRespostaPage = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "@/pages/fluxos-externo-crud/resposta-page.vue"
  );

export const ExternoAcknowledgmentPage = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "@/pages/fluxos-externo-crud/acknowledgment-page.vue"
  );

export const ExternoRevisaoPage = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "@/pages/revisao-page/revisao-page.vue"
  );

export const ExternoDesktopPage = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "@/pages/fluxos-externo-crud/desktop-page.vue"
  );

export const ExternoSerproIdPage = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "@/pages/fluxos-externo-serpro-id/serpro-id-page.vue"
  );

export const ChangelogPage = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "@/pages/changelog/changelog-page.vue"
  );
